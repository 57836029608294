import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';

const AuthorizedLocations = () => {
  const [locations, setLocations] = useState([]);
  const [form, setForm] = useState({ id: '', name: '', latitude: '', longitude: '', radius: 1 });

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/authorized-locations`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      setLocations(response.data);
    } catch (error) {
      console.error('Erro ao buscar locais autorizados:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/authorized-locations`,
        form,
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } }
      );
      alert(response.data.message);
      fetchLocations();
      setForm({ id: '', name: '', latitude: '', longitude: '', radius: 1 });
    } catch (error) {
      console.error('Erro ao salvar local autorizado:', error);
    }
  };

  const handleEdit = (location) => {
    setForm(location);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div>
      <h1>Gerenciar Locais Autorizados</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome"
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          required
        />
        <input
          type="number"
          placeholder="Latitude"
          value={form.latitude}
          onChange={(e) => setForm({ ...form, latitude: e.target.value })}
          required
        />
        <input
          type="number"
          placeholder="Longitude"
          value={form.longitude}
          onChange={(e) => setForm({ ...form, longitude: e.target.value })}
          required
        />
        <input
          type="number"
          placeholder="Raio (km)"
          value={form.radius}
          onChange={(e) => setForm({ ...form, radius: e.target.value })}
        />
        <button type="submit">Salvar</button>
      </form>
      <ul>
        {locations.map((loc) => (
          <li key={loc.id}>
            {loc.name} ({loc.latitude}, {loc.longitude}) - {loc.radius} km
            <button onClick={() => handleEdit(loc)}>Editar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AuthorizedLocations;