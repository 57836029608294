import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEdit, FaCheck, FaPaperPlane, FaCheckCircle } from 'react-icons/fa';
import withPermission from '../hoc/withPermission';
import './ClientList.css';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ClientList({ isVisible }) {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState({});
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const openEditModal = (client) => {
    setSelectedClient(client);
    setEditModalOpen(true);
  };

  const openViewModal = (client) => {
      setSelectedClient(client);
      setViewModalOpen(true);
  };

  const closeEditModal = () => {
      setEditModalOpen(false);
  };

  const closeViewModal = () => {
      setViewModalOpen(false);
  };

  const success_noti = (message) => {
    toast.success(message || t('termsSentSuccessfully'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  const error_noti = (message) => {
    toast.error(message || t('errorSendingTerms'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  const sendTerms = async (client) => {
    console.log('Iniciando o envio dos termos para o cliente:', client);
  
    try {
      const token = sessionStorage.getItem('token'); // Recupera o token JWT
      if (!token) {
        console.error('Token ausente. Redirecionando...');
        error_noti(t('unauthorizedAccess')); // Mensagem de erro amigável
        return;
      }
  
      const endpoint = `${API_BASE_URL}/api/clients/${client.id}/send-terms`;
  
      console.log('Enviando solicitação para o endpoint:', endpoint);
      console.log('Dados do cliente sendo enviados:', {
        contact_preference: client.contact_preference,
        email: client.email,
        phone: client.phone,
      });
  
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          contact_preference: client.contact_preference,
          email: client.email,
          phone: client.phone,
        }),
      });
  
      const responseData = await response.json();
      console.log('Resposta JSON da API:', responseData);
  
      if (response.ok) {
        success_noti(t('termsSentSuccessfully'));
  
        // Abrir o link do WhatsApp automaticamente
        if (client.contact_preference === 'whatsapp' && responseData.whatsappLink) {
          window.open(responseData.whatsappLink, '_blank'); // Abre em nova aba
        }
      } else {
        console.error('Erro na resposta da API:', response.status, response.statusText);
        error_noti(responseData.error || t('errorSendingTerms'));
      }
    } catch (error) {
      console.error('Erro ao enviar os termos:', error);
      error_noti(error.message || t('errorSendingTerms'));
    }
  };

  const handleAcceptTerms = async (clientId, currentTermsPolicy) => {
    if (!clientId) return; // Verifica se o ID do cliente foi fornecido
  
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token ausente. Redirecionando...');
        return;
      }
  
      const newTermsPolicy = currentTermsPolicy === 'yes' ? 'no' : 'yes';
  
      const response = await axios.post(
        `${API_BASE_URL}/api/clients/${clientId}/accept-terms`,
        { terms_policy: newTermsPolicy }, // Enviando terms_policy no corpo
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.status === 200) {
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.id === clientId ? { ...client, terms_policy: newTermsPolicy } : client
          )
        );
  
        setFilteredClients((prevFilteredClients) =>
          prevFilteredClients.map((client) =>
            client.id === clientId ? { ...client, terms_policy: newTermsPolicy } : client
          )
        );
  
        toast.success(t('termsAcceptedSuccessfully'), { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Erro ao aceitar termos:', error);
      toast.error(t('errorAcceptingTerms'), { position: 'bottom-right' });
    }
  };

  const handleFilter = (event) => {
    const type = event.target.value;
    setSelectedFilter(type);
  
    if (type === 'all') {
      setFilteredClients(clients);
    } else if (type === 'business') {
      const filtered = clients.filter(
        (client) => client.costumer_type === 'business_1' || client.costumer_type === 'business_2'
      );
      setFilteredClients(filtered);
    } else {
      const filtered = clients.filter((client) => client.costumer_type === type);
      setFilteredClients(filtered);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token ausente. Redirecionando...');
          return;
        }
        const response = await axios.get(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClients(response.data);
        setFilteredClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
  
    fetchClients();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filtered = clients.filter((client) => {
        const matchesSearch = Object.values(client).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
  
        if (selectedFilter === 'all') {
          return matchesSearch;
        } else if (selectedFilter === 'business') {
          return (
            matchesSearch &&
            (client.costumer_type === 'business_1' || client.costumer_type === 'business_2')
          );
        } else if (selectedFilter === 'private') {
          return matchesSearch && client.costumer_type === 'private';
        }
  
        return false;
      });
  
      setFilteredClients(filtered);
    } else {
      // Reaplica o filtro principal se o termo de busca for muito curto
      handleFilter({ target: { value: selectedFilter } });
    }
  }, [searchTerm, clients, selectedFilter]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setSelectedClient((prev) => {
      if (!prev) return {}; // Retorne um objeto vazio para evitar erros
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const saveChanges = async () => {
    try {
      console.log('Iniciando a atualização do cliente.');
  
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token ausente. Redirecionando...');
        return;
      }
  
      console.log('Token encontrado. Prosseguindo com a requisição.');
  
      const response = await axios.put(
        `${API_BASE_URL}/api/clients/${selectedClient.id}`,
        selectedClient,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Resposta da API recebida:', response);
  
      if (response.status === 200) {
        console.log('Cliente atualizado com sucesso:', response.data);
  
        const updatedClients = clients.map((client) =>
          client.id === selectedClient.id ? { ...client, ...response.data } : client
        );
  
        console.log('Lista de clientes após atualização:', updatedClients);
  
        setClients(updatedClients);
        setFilteredClients(updatedClients);
  
        console.log('Estado atualizado com sucesso.');
        closeEditModal();
  
        toast.success('Cliente atualizado com sucesso.', { position: 'bottom-right' });
      } else {
        console.error('Erro na atualização do cliente:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
      toast.error('Erro ao atualizar cliente.', { position: 'bottom-right' });
    }
  };

  const sortedClients = [...filteredClients].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastClient = currentPage * resultsPerPage;
  const indexOfFirstClient = indexOfLastClient - resultsPerPage;
  const currentClients = sortedClients.slice(indexOfFirstClient, indexOfLastClient);

  const totalPages = Math.ceil(filteredClients.length / resultsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const renderSortIcons = (field) => (
    <span className="sort-icons">
      <span
        className={`arrow ${sortField === field && sortOrder === 'asc' ? 'active' : ''}`}
      >
        ↑
      </span>
      <span
        className={`arrow ${sortField === field && sortOrder === 'desc' ? 'active' : ''}`}
      >
        ↓
      </span>
    </span>
  );

  return (
    <div className="page-wrapper">
      <ToastContainer />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('clients')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('listClient')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
             <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control me-3"
                placeholder={t('searchClients')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div class="ms-auto me-3">
                <div class="btn-group">
                    <select className="form-select w-auto ms-3" onChange={handleFilter} value={selectedFilter}>
                        <option value="all">{t('allClients')}</option>
                        <option value="private">{t('privato')}</option>
                        <option value="business">{t('business')}</option>
                    </select>
                </div>
            </div>
            </div>
            <div class="ms-auto me-3">
                <div class="btn-group">
                    <select
                    className="form-select w-auto"
                    value={resultsPerPage}
                    onChange={(e) => setResultsPerPage(Number(e.target.value))}
                    >
                    <option value={10}>10 {t('results')}</option>
                    <option value={20}>20 {t('results')}</option>
                    <option value={30}>30 {t('results')}</option>
                    </select>
                </div>
            </div>
            <span class="me-3">
            {t('showingClients')} {currentClients.length} {t('of')} {filteredClients.length} {t('clients')}
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped custom-table">
                <thead className="table-light">
                    <tr>
                        {['costumer_type', 'name', 'phone'].map((field) => (
                        <th
                            key={field}
                            onClick={() => handleSort(field)}
                            className="sortable"
                        >
                            {t(field)}
                            {renderSortIcons(field)}
                        </th>
                        ))}
                        <th>{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                  {currentClients.length > 0 ? (
                    currentClients.map((client) => (
                        <tr key={client.id}>
                            <td>{t(client.costumer_type ?? 'N/A')}</td>
                            <td>{client.name ?? 'N/A'}</td>
                            <td>{client.phone ?? 'N/A'}</td>
                            <td>
                            <button
                              className="btn btn-sm btn-primary me-2"
                              title={t('view')}
                              onClick={() => openViewModal(client)}
                            >
                              <FaEye />
                            </button>
                            <button
                              className="btn btn-sm btn-warning me-2"
                              title={t('edit')}
                              onClick={() => openEditModal(client)}
                            >
                              <FaEdit />
                            </button>
                            {client.terms_policy === 'yes' ? (
                              <button
                                className="btn btn-sm btn-outline-success me-2"
                                title={t('acceptedTerms')}
                              >
                                <FaCheck />
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-outline-danger me-2"
                                title={t('acceptTerms')}
                                onClick={() => handleAcceptTerms(client.id, client.terms_policy)}
                              >
                                <FaCheckCircle />
                              </button>
                            )}
                            <button
                              className="btn btn-sm btn-primary"
                              title={t('sendTerms')}
                              onClick={() => sendTerms(client)}
                            >
                              <FaPaperPlane />
                            </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="11" className="text-center">
                            {t('no_clients_found')}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
          </div>
          {/* Modal de Visualização */}
          {viewModalOpen && selectedClient && (
              <div className="modal-overlay">
                  <div className="modal-content modal-dialog">
                      <h4>{t('clientDetails')}</h4>
                      <p><strong>{t('costumerType')}:</strong> {t(selectedClient.costumer_type)}</p>
                      <p><strong>{t('name')}:</strong> {selectedClient.name}</p>
                      <p><strong>{t('surname')}:</strong> {selectedClient.surname}</p>
                      <p><strong>{t('email')}:</strong> {selectedClient.email}</p>
                      <p><strong>{t('phone')}:</strong> {selectedClient.phone}</p>
                      {['business_1', 'business_2'].includes(selectedClient.costumer_type) && (
                          <>
                              <p><strong>{t('pec')}:</strong> {selectedClient.pec}</p>
                              <p><strong>{t('p_iva')}:</strong> {selectedClient.p_iva}</p>
                              <p><strong>{t('cod_sdi')}:</strong> {selectedClient.cod_sdi}</p>
                          </>
                      )}
                      <p><strong>{t('cf')}:</strong> {selectedClient.cf}</p>
                      <p><strong>{t('contactPreference')}:</strong> {t(selectedClient.contact_preference)}</p>
                      <button className="btn btn-secondary" onClick={closeViewModal}>
                          {t('close')}
                      </button>
                  </div>
              </div>
          )}

          {/* Modal de Edição */}
          {editModalOpen && selectedClient && (
              <div className="modal-overlay">
                  <div className="modal-content modal-dialog-scrollable">
                      <h4>{t('editClientDetails')}</h4>
                      <form>
                          {/* Tipo de Cliente */}
                          <div className="mb-3">
                              <label className="form-label">{t('costumerType')}</label>
                              <select
                                  className="form-select"
                                  name="costumer_type"
                                  value={selectedClient.costumer_type || ''}
                                  onChange={handleInputChange}
                              >
                                  <option value="private">{t('privato')}</option>
                                  <option value="business_1">{t('business1')}</option>
                                  <option value="business_2">{t('business2')}</option>
                              </select>
                          </div>

                          {/* Campos gerais */}
                          <div className="mb-3">
                              <label className="form-label">{t('name')}</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={selectedClient.name || ''}
                                  onChange={handleInputChange}
                              />
                          </div>
                          <div className="mb-3">
                              <label className="form-label">{t('surname')}</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  name="surname"
                                  value={selectedClient.surname || ''}
                                  onChange={handleInputChange}
                              />
                          </div>
                          <div className="mb-3">
                              <label className="form-label">{t('email')}</label>
                              <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  value={selectedClient.email || ''}
                                  onChange={handleInputChange}
                              />
                          </div>
                          <div className="mb-3">
                              <label className="form-label">{t('phone')}</label>
                              <input
                                  type="tel"
                                  className="form-control"
                                  name="phone"
                                  value={selectedClient.phone || ''}
                                  onChange={handleInputChange}
                              />
                          </div>

                          {/* Campos adicionais para empresas */}
                          {['business_1', 'business_2'].includes(selectedClient.costumer_type) && (
                              <>
                                  <div className="mb-3">
                                      <label className="form-label">{t('pec')}</label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="pec"
                                          value={selectedClient.pec || ''}
                                          onChange={handleInputChange}
                                      />
                                  </div>
                                  <div className="mb-3">
                                      <label className="form-label">{t('p_iva')}</label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="p_iva"
                                          value={selectedClient.p_iva || ''}
                                          onChange={handleInputChange}
                                      />
                                  </div>
                                  <div className="mb-3">
                                      <label className="form-label">{t('cod_sdi')}</label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="cod_sdi"
                                          value={selectedClient.cod_sdi || ''}
                                          onChange={handleInputChange}
                                      />
                                  </div>
                              </>
                          )}

                          {/* CF */}
                          <div className="mb-3">
                              <label className="form-label">{t('cf')}</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  name="cf"
                                  value={selectedClient.cf || ''}
                                  onChange={handleInputChange}
                              />
                          </div>

                          {/* Preferência de Contato */}
                          <div className="mb-3">
                            <div className="form-check">
                              <input
                                type="radio"
                                id="contactEmail"
                                name="contactPreference"
                                value="email"
                                checked={selectedClient.contact_preference === 'email'}
                                onChange={(e) => setSelectedClient({ ...selectedClient, contact_preference: e.target.value })}
                              />
                              <label htmlFor="contactEmail">{t('email')}</label>
                            </div>
                            <div className="form-check">
                              <input
                                type="radio"
                                id="contactWhatsapp"
                                name="contactPreference"
                                value="whatsapp"
                                checked={selectedClient.contact_preference === 'whatsapp'}
                                onChange={(e) => setSelectedClient({ ...selectedClient, contact_preference: e.target.value })}
                              />
                              <label htmlFor="contactWhatsapp">{t('whatsapp')}</label>
                            </div>
                          </div>

                          <button
                              type="button"
                              className="btn btn-success"
                              onClick={saveChanges}
                          >
                              {t('saveChanges')}
                          </button>
                          <button
                              type="button"
                              className="btn btn-secondary ms-2"
                              onClick={closeEditModal}
                          >
                              {t('cancel')}
                          </button>
                      </form>
                  </div>
              </div>
          )}
          <div className="pagination d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === 1}
              onClick={prevPage}
            >
              {t('previous')}
            </button>
            <div>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  className={`btn btn-sm pagination-btn ${currentPage === page ? 'active' : ''}`}
                  onClick={() => paginate(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === totalPages}
              onClick={nextPage}
            >
              {t('next')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPermission(ClientList, ['admin', 'receptionist']);