import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './WorkBudget.css';
import API_BASE_URL from '../config';

function WorkBudget() {
  const [budgets, setBudgets] = useState([]);
  const [filteredBudgets, setFilteredBudgets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBudgets = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        setError(t('access_denied'));
        setLoading(false);
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/api/budgets/accepted`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Dados recebidos da API:', response.data);
      setBudgets(response.data);
    } catch (error) {
      console.error('Erro ao buscar orçamentos:', error);
      setError(t('Erro ao buscar orçamentos.'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  // Handle search and filtering
  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filtered = budgets.filter((budget) =>
        Object.values(budget).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredBudgets(filtered);
    } else {
      setFilteredBudgets(budgets);
    }
  }, [searchTerm, budgets]);

  // Handle sorting
  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  // Sorting logic
  const sortedBudgets = [...filteredBudgets].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Pagination logic
  const indexOfLastBudget = currentPage * resultsPerPage;
  const indexOfFirstBudget = indexOfLastBudget - resultsPerPage;
  const currentBudgets = sortedBudgets.slice(indexOfFirstBudget, indexOfLastBudget);

  const totalPages = Math.ceil(filteredBudgets.length / resultsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Render sort icons
  const renderSortIcons = (field) => (
    <span className="sort-icons">
      <span
        className={`arrow ${sortField === field && sortOrder === 'asc' ? 'active' : ''}`}
      >
        ↑
      </span>
      <span
        className={`arrow ${sortField === field && sortOrder === 'desc' ? 'active' : ''}`}
      >
        ↓
      </span>
    </span>
  );

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('workBudgets')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('acceptedBudgets')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              type="text"
              className="form-control me-3"
              placeholder={t('searchBudgets')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="form-select w-auto me-3"
              value={resultsPerPage}
              onChange={(e) => setResultsPerPage(Number(e.target.value))}
            >
              <option value={10}>10 {t('results')}</option>
              <option value={20}>20 {t('results')}</option>
              <option value={30}>30 {t('results')}</option>
            </select>
            <span>
              {t('showing')} {currentBudgets.length} {t('of')} {filteredBudgets.length} {t('budgets')}
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped custom-table">
              <thead className="table-light">
                <tr>
                  {['id', 'client_name', 'Car', 'created_at'].map((field) => (
                    <th
                      key={field}
                      onClick={() => handleSort(field)}
                      className="sortable"
                    >
                      {t(field)}
                      {renderSortIcons(field)}
                    </th>
                  ))}
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
              {currentBudgets.length > 0 ? (
                currentBudgets.map((budget) => (
                  <tr key={budget.budget_id}>
                    <td>{budget.budget_id}</td>
                    <td>{budget.client_name}</td>
                    <td>{budget.car_brand} {budget.car_model}</td>
                    <td>{new Date(budget.created_at).toLocaleDateString()}</td>
                    <td>
                      <button></button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    {t('Nenhum orçamento encontrado.')}
                  </td>
                </tr>
              )}
            </tbody>
            </table>
          </div>
          <div className="pagination d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === 1}
              onClick={prevPage}
            >
              {t('previous')}
            </button>
            <div>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  className={`btn btn-sm pagination-btn ${currentPage === page ? 'active' : ''}`}
                  onClick={() => paginate(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === totalPages}
              onClick={nextPage}
            >
              {t('next')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkBudget;