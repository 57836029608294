// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/bootstrap.min.css';
import './assets/css/app.css';
import './assets/css/icons.css';
import './i18n'; // Importa a configuração do i18next

// Cria uma raiz para o React 18 e renderiza o App
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Medição de desempenho opcional
reportWebVitals();