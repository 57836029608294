import { useEffect } from 'react';

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      // Se o ref existir e o clique não estiver dentro do elemento
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Ouvinte de clique no documento
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useOutsideClick;