import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import itLocale from '@fullcalendar/core/locales/it';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import './Calendar.css';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import moment from 'moment';
import 'moment-timezone';
import useOutsideClick from '../hooks/useOutsideClick.js';

registerLocale('it', it);

function Calendar() {
  const { t, i18n } = useTranslation();
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [locale, setLocale] = useState('en');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAppointments, setShowAppointments] = useState(true);
  const [showMechanicWork, setShowMechanicWork] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [mechanicBookings, setMechanicBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const role = sessionStorage.getItem("role");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [infoBox, setInfoBox] = useState(null);
  const infoBoxRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const calendarEl = calendarRef.current?.el; // Onde o FullCalendar monta o DOM
  const screenWidth = calendarEl?.offsetWidth || window.innerWidth;
  const [showDueDates, setShowDueDates] = useState(false);
  const [dueDateEvents, setDueDateEvents] = useState([]);
  const initialView = role === 'mechanic' ? 'timeGridDay' : 'timeGridWeek';

  useOutsideClick(infoBoxRef, () => {
    // Só fecha se o popup estiver aberto
    if (isPopupVisible) {
      setIsPopupVisible(false);
    }
  });

  function CustomTimeHeader({ label }) {
    return <div className="react-datepicker__time-header">Ora</div>;
  }
  // Abrir modal de visualização
  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
  };

  // Fechar modal de visualização
  const handleCloseViewModal = () => {
    setSelectedAppointment(null);
  };

  // Abrir modal de edição
  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  // Fechar modal de edição
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  // Excluir agendamento
  const handleDeleteAppointment = async (appointmentId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/appointments/${appointmentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
  
      if (response.ok) {
        setAppointments((prev) => prev.filter((appt) => appt.id !== appointmentId));
        setEvents((prevEvents) => prevEvents.filter((event) => event.id !== appointmentId));
        toast.success('Agendamento excluído com sucesso.');
        // Fecha o popup
        setIsPopupVisible(false);
      } else {
        toast.error('Erro ao excluir agendamento.');
      }
    } catch (error) {
      console.error('Erro ao excluir agendamento:', error);
      toast.error('Erro ao excluir agendamento.');
    }
  };

  // Salvar alterações no agendamento
  const handleSaveChanges = async (updatedEvent) => {
    try {
      const payload = {
        name: updatedEvent.name,
        phone: updatedEvent.phone,
        car_brand_model: updatedEvent.car_brand_model,
        car_plate: updatedEvent.car_plate,
        description: updatedEvent.description,
        start_time: moment(updatedEvent.start).tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(updatedEvent.end).tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss'),
      };
  
      console.log('Enviando alterações para o backend:', payload);
  
      const response = await fetch(`${API_BASE_URL}/api/appointments/${updatedEvent.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        toast.success('Agendamento atualizado com sucesso.');
  
        // Atualiza o estado local do evento
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === updatedEvent.id ? { ...event, ...updatedEvent } : event
          )
        );
  
        setAppointments((prevAppointments) =>
          prevAppointments.map((event) =>
            event.id === updatedEvent.id ? { ...event, ...updatedEvent } : event
          )
        );
  
        // Atualiza o popup se o evento editado for o atual
        if (infoBox?.id === updatedEvent.id) {
          setInfoBox((prev) => ({
            ...prev,
            name: updatedEvent.name,
            phone: updatedEvent.phone,
            description: updatedEvent.description,
            start: updatedEvent.start,
            end: updatedEvent.end,
          }));
        }
  
        // Fecha o modal
        setIsEditModalOpen(false);
      } else {
        const errorData = await response.json();
        console.error('Erro ao atualizar o agendamento:', errorData);
        toast.error('Erro ao atualizar o agendamento.');
      }
    } catch (error) {
      console.error('Erro ao atualizar agendamento:', error);
      toast.error('Erro ao atualizar agendamento.');
    }
  };

  useEffect(() => {
    if (infoBox?.id) {
      const updatedEvent = events.find((event) => event.id === infoBox.id);
      if (updatedEvent) {
        const updatedInfoBox = {
          ...infoBox,
          name: updatedEvent.title.split(' - ')[0] || ' ',
          phone: updatedEvent.title.match(/\((.*?)\)/)?.[1] || ' ',
          start: updatedEvent.start,
          end: updatedEvent.end,
          description: updatedEvent.description || ' ',
        };
  
        // Atualiza apenas se houver diferença para evitar loop
        if (JSON.stringify(infoBox) !== JSON.stringify(updatedInfoBox)) {
          setInfoBox(updatedInfoBox);
        }
      }
    }
  }, [events]);

  const [newEvent, setNewEvent] = useState({
    name: '',
    phone: '',
    car_brand_model: '',
    car_plate: '',
    description: '',
    start_time: null,
    duration: '',
    color: '#007bff',
  });

  const languageOptions = {
    en: enLocale,
    pt: ptLocale,
    it: itLocale,
  };

  useEffect(() => {
    const preferredLanguage = sessionStorage.getItem('preferred_language') || 'en';
    setLocale(preferredLanguage);
    i18n.changeLanguage(preferredLanguage);
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/appointments`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar agendamentos.');
        }
  
        const data = await response.json();
  
        // Formatar os dados no formato esperado pelo FullCalendar
        const formattedAppointments = data.map((event) => ({
          id: event.id,
          title: event.name, // Nome e telefone
          start: moment.tz(event.start_time, 'Europe/Rome').format(), // Formato ISO
          end: moment.tz(event.end_time, 'Europe/Rome').format(), // Formato ISO
          color: event.color || '#007bff', // Cor padrão
          description: event.description || '',
          phone: event.phone,                     // <- telefone
          car_brand_model: event.car_brand_model, // <- marca/modelo
          car_plate: event.car_plate,
        }));
  
        setAppointments(formattedAppointments); // Atualiza o estado
      } catch (error) {
        console.error('Erro ao buscar agendamentos:', error);
      }
    };
  
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/mechanics/bookings`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar agendamentos.');
        }
  
        const bookingsData = await response.json();
  
        console.log("Dados recebidos do backend:", bookingsData);
  
        // Ajusta os horários para UTC+1
        const formattedBookings = bookingsData.map((booking) => ({
          id: `booking-${booking.id}`,
          title: `${booking.mechanic_name} - ${booking.car_brand || ''} ${booking.car_model || ''}`, // Nome do mecânico + marca/modelo do carro
          start: moment(booking.start).format(),
          end: moment(booking.end).format(),
          className: booking.className, // Classe de estilo baseada no mecânico
          mechanic_name: booking.mechanic_name,
          car_brand: booking.car_brand,
          car_model: booking.car_model,
          car_plate: booking.car_plate,
          ticketNumber: booking.ticketNumber, // Número do ticket
          ticketOpenedOn: booking.ticketOpenedOn
            ? moment(booking.ticketOpenedOn).format('YYYY-MM-DD HH:mm:ss')
            : null,  // caso venha undefined ou null
          description: booking.description, // Descrição do agendamento
          type: 'booking',
        }));
  
        setMechanicBookings(formattedBookings);
      } catch (error) {
        console.error('Erro ao buscar agendamentos:', error);
      }
    };
  
    fetchEvents();
    fetchData();
  }, []);

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => {
    setModalIsOpen(false);
    setNewEvent({ title: '', description: '', date: '' });
  };

  const handleSaveEvent = async () => {
    if (!newEvent.name || !newEvent.start_time || !newEvent.duration) {
      toast.error(t('Please fill all required fields.'));
      return;
    }
  
    // Calcula o end_time com base no start_time e na duração
    const startTimeRome = moment.tz(newEvent.start_time, 'Europe/Rome');
    const endTimeRome = startTimeRome.clone().add(parseInt(newEvent.duration, 10), 'minutes');
  
    try {
      const payload = {
        name: newEvent.name,
        phone: newEvent.phone,
        car_brand_model: newEvent.car_brand_model,
        car_plate: newEvent.car_plate,
        description: newEvent.description,
        start_time: startTimeRome.format('YYYY-MM-DD HH:mm:ss'),
        end_time: endTimeRome.format('YYYY-MM-DD HH:mm:ss'),
        duration: parseInt(newEvent.duration, 10),
      }

      const response = await fetch(`${API_BASE_URL}/api/appointments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        toast.success(t('Event created successfully.'));
        
        fetchAppointments();
        handleCloseModal();
      } else {
        toast.error(t('Error creating event.'));
      }
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      toast.error(t('Error creating event.'));
    }
  };

  const handleEventClick = (info) => {
    const clickedEvent = events.find((e) => String(e.id) === String(info.event.id));

    if (!clickedEvent) {
      console.error('Evento não encontrado:', info.event.id);
      toast.error('Evento não encontrado.');
      return;
    }

    // Utiliza o campo 'type' para determinar o tipo de evento
    const eventType = clickedEvent.type || 'appointment'; // Padrão

    // Configura os dados para o infobox
    const infoboxData = {
      id: clickedEvent.id,
      type: eventType,
      description: clickedEvent.description || '',
    };

    if (eventType === 'due_date') {
      infoboxData.client_name = clickedEvent.client_name || '-';
      infoboxData.client_phone = clickedEvent.client_phone || '-';
      infoboxData.car_brand = clickedEvent.car_brand || '-';
      infoboxData.car_model = clickedEvent.car_model || '-';
      infoboxData.due_date = moment(clickedEvent.due_date).format('DD-MM-YYYY HH:mm:ss');
      infoboxData.ticketNumber = clickedEvent.ticketNumber || '-';
    } else if (eventType === 'booking') {
      infoboxData.mechanic_name = clickedEvent.mechanic_name || '-';
      infoboxData.car_brand_model = `${clickedEvent.car_brand || '-'} ${clickedEvent.car_model || '-'}`;
      infoboxData.car_plate = clickedEvent.car_plate || '-';
      infoboxData.ticketNumber = clickedEvent.ticketNumber || '-';
      infoboxData.ticketOpenedOn = clickedEvent.ticketOpenedOn || '-';
    } else if (eventType === 'appointment') {
      infoboxData.name = clickedEvent.title || '-';
      infoboxData.phone = clickedEvent.phone || '-';
      infoboxData.car_brand_model = clickedEvent.car_brand_model || '-';
      infoboxData.car_plate = clickedEvent.car_plate || '-';
      infoboxData.start = clickedEvent.start ? moment(clickedEvent.start).format('YYYY-MM-DD HH:mm') : '-';
      infoboxData.end = clickedEvent.end ? moment(clickedEvent.end).format('YYYY-MM-DD HH:mm') : '-';
    }

    setInfoBox(infoboxData);

    // Calcula a posição do popup
    const screenWidth = window.innerWidth; // Largura da tela
    const xClick = info.jsEvent.pageX;
    const yClick = info.jsEvent.pageY;

    let adjustedLeft;
    if (xClick > screenWidth / 2) {
      // Clique na metade direita => exibir popup à esquerda
      adjustedLeft = xClick - 300; // Ajuste a largura do popup conforme necessário
    } else {
      // Clique na metade esquerda => exibir popup à direita
      adjustedLeft = xClick + 10; // 10px de margem
    }

    setPopupPosition({
      top: yClick,
      left: adjustedLeft,
    });
    setIsPopupVisible(true);
  };

  const fetchAppointments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/appointments`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar agendamentos.');
      }

      const data = await response.json();

      // Formatar os dados no formato esperado pelo FullCalendar
      const formattedAppointments = data.map((event) => ({
        id: event.id,
        title: event.name, // Nome e telefone
        start: moment(event.start_time).format(),
        end: moment(event.end_time).format(),
        color: event.color || '#007bff', // Cor padrão
        description: event.description || '',
        phone: event.phone,                     // <- telefone
        car_brand_model: event.car_brand_model, // <- marca/modelo
        car_plate: event.car_plate,
        type: 'appointment',
      }));

      setAppointments(formattedAppointments); // Atualiza o estado
    } catch (error) {
      console.error('Erro ao buscar agendamentos:', error);
    }
  };

  const fetchDueDates = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/service_logs/due_dates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(t('Failed to fetch due dates.'));
      }

      const data = await response.json();

      // Formate os dados para o FullCalendar
      const formattedDueDates = data.map((log) => ({
        id: log.id, // ID único
        title: `${t('Deliverie for')} ${log.client_name} - ${log.car_brand} ${log.car_model}`,
        start: moment(log.due_date).format(),
        end: moment(log.due_date).add(1, 'hour').format(),
        color: '#ff5722',
        description: log.description || '',
        client_name: log.client_name,
        client_phone: log.client_phone,
        due_date: log.due_date,
        car_brand: log.car_brand,
        car_model: log.car_model,
        ticketNumber: log.id,
        type: 'due_date',
      }));

      setDueDateEvents(formattedDueDates);
    } catch (error) {
      console.error('Error fetching due dates:', error);
      toast.error(error.message || t('Error fetching due dates.'));
    }
  };

  useEffect(() => {
    if (showDueDates) {
      fetchDueDates();
    } else {
      setDueDateEvents([]);
    }
  }, [showDueDates]);

  useEffect(() => {
    const combineEvents = () => {
      const combinedEvents = [
        ...(showAppointments ? appointments : []),
        ...(showMechanicWork ? mechanicBookings : []),
        ...(showDueDates ? dueDateEvents : []), // Adiciona as due dates
      ];
      setEvents(combinedEvents);
    };

    combineEvents();
  }, [appointments, mechanicBookings, dueDateEvents, showAppointments, showMechanicWork, showDueDates]);

  // Chama o fetchAppointments ao carregar o componente
  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <div className="page-wrapper">
      <ToastContainer />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('calendar')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('calendar')}
                </li>
              </ol>
            </nav>
          </div>
          {["admin", "receptionist"].includes(role) && (
          <button className="btn btn-primary ms-auto" onClick={handleOpenModal}>
            {t('newSchedule')}
          </button>
          )}
        </div>
        {["admin", "receptionist"].includes(role) && (
          <div className="filters d-flex align-items-center mb-3">
            <div className="form-check me-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="showAppointments"
                checked={showAppointments}
                onChange={() => setShowAppointments(!showAppointments)}
              />
              <label className="form-check-label" htmlFor="showAppointments">
                {t('Show Appointments')}
              </label>
            </div>
            <div className="form-check me-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="showMechanicWork"
                checked={showMechanicWork}
                onChange={() => setShowMechanicWork(!showMechanicWork)}
              />
              <label className="form-check-label" htmlFor="showMechanicWork">
                {t('Show Mechanic Work')}
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showDueDates"
                checked={showDueDates}
                onChange={() => setShowDueDates(!showDueDates)}
              />
              <label className="form-check-label" htmlFor="showDueDates">
                {t('Show Due Dates')}
              </label>
            </div>
          </div>
        )}
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView={initialView}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek,timeGridDay,dayGridMonth,listWeek',
          }}
          timeZone="Europe/Rome"
          locale={languageOptions[locale]}
          slotMinTime="08:00:00"
          slotMaxTime="24:00:00"
          scrollTime="08:00:00"
          allDaySlot={false}
          nowIndicator
          selectable
          eventOverlap={false}
          slotEventOverlap={false}
          dayMaxEvents={true}
          dayMaxEventRows={6}
          expandRows={true}
          height="auto"
          contentHeight="auto"
          events={events}
          navLinks
          eventClick={handleEventClick}
          // Impede arrastar/redimensionar
          editable={false}
          eventStartEditable={false}
          eventDurationEditable={false}

          // Adicione a prop eventDidMount
          eventDidMount={(info) => {
            // listener para clique direito
            info.el.addEventListener('contextmenu', (e) => {
              e.preventDefault(); // evita abrir o menu padrão do navegador

              // Chama a MESMA função de clique do evento, passando:
              // - event: info.event (objeto do calendário)
              // - jsEvent: e (evento de mouse)
              handleEventClick({
                event: info.event,
                jsEvent: e,
              });
            });
          }}
        />

        {isPopupVisible && infoBox && (
          <div
            className="info-box"
            ref={infoBoxRef}
            style={{
              top: popupPosition?.top || 0,
              left: popupPosition?.left || 0,
            }}
          >
            <button className="close-btn" onClick={() => setIsPopupVisible(false)}>
              &times;
            </button>
            <h4>
              {infoBox.type === 'booking'
                ? t('Booking Details')
                : infoBox.type === 'due_date'
                  ? t('Due Date Details')
                  : t('Appointment Details')}
            </h4>

            {/* Informações específicas para bookings */}
            {infoBox.type === 'booking' && (
              <>
                <p><strong>{t('Mechanic Name')}:</strong> {infoBox.mechanic_name}</p>
                <p><strong>{t('Car')}:</strong> {infoBox.car_brand_model}</p>
                <p><strong>{t('Car Plate')}:</strong> {infoBox.car_plate}</p>
                <p><strong>{t('Ticket Number')}:</strong> {infoBox.ticketNumber}</p>
                <p><strong>{t('Ticket Opened On')}:</strong> {infoBox.ticketOpenedOn}</p>
                <div className="btn-container">
                  <button
                    className="btn btn-primary"
                    onClick={() => window.location.href = `/tickets/${infoBox.ticketNumber}`}
                  >
                    {t('Open Ticket')}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => setIsPopupVisible(false)}
                  >
                    {t('Close')}
                  </button>
                </div>
              </>
            )}

            {/* Informações específicas para due_dates */}
            {infoBox.type === 'due_date' && (
              <>
                <p><strong>{t('Client Name')}:</strong> {infoBox.client_name}</p>
                <p><strong>{t('Client Phone')}:</strong> {infoBox.client_phone}</p>
                <p><strong>{t('Car')}:</strong> {infoBox.car_brand} {infoBox.car_model}</p>
                <p><strong>{t('Delivery Time')}:</strong> {infoBox.due_date}</p>
                <p><strong>{t('Ticket Number')}:</strong> {infoBox.ticketNumber}</p>
                <div className="btn-container">
                  <button
                    className="btn btn-primary"
                    onClick={() => window.location.href = `/tickets/${infoBox.ticketNumber}`}
                  >
                    {t('Open Ticket')}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => setIsPopupVisible(false)}
                  >
                    {t('Close')}
                  </button>
                </div>
              </>
            )}

            {/* Informações específicas para appointments */}
            {infoBox.type === 'appointment' && (
              <>
                <p><strong>{t('Name')}:</strong> {infoBox.name}</p>
                <p><strong>{t('Phone')}:</strong> {infoBox.phone}</p>
                <p><strong>{t('Car (Brand and Model)')}:</strong> {infoBox.car_brand_model}</p>
                <p><strong>{t('Car Plate')}:</strong> {infoBox.car_plate}</p>
                <p><strong>{t('Start')}:</strong> {infoBox.start}</p>
                <p><strong>{t('End')}:</strong> {infoBox.end}</p>
                {infoBox.description && (
                  <p>
                    <strong>{t('Description')}:</strong>
                    <br />
                    {infoBox.description.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                )}
                <div className="btn-container">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setSelectedAppointment({
                        id: infoBox.id,
                        name: infoBox.name,
                        phone: infoBox.phone,
                        car_brand_model: infoBox.car_brand_model,
                        car_plate: infoBox.car_plate,
                        start: infoBox.start,
                        end: infoBox.end,
                        description: infoBox.description,
                      });
                      setIsEditModalOpen(true); // Abre o modal de edição
                    }}
                  >
                    {t('Edit')}
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteAppointment(infoBox.id)}
                  >
                    {t('Delete')}
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {/* Modal para edição do Appointment */}
        {isEditModalOpen && selectedAppointment && (
          <div className="modal-overlay">
            <div className="modal-content modal-dialog modal-xl modal-dialog-scrollable">
              <h4>{t('Edit Appointment')}</h4>

              <div className="form-grid">
                {/* Nome e Telefone */}
                <div className="form-group">
                  <label htmlFor="edit-name">{t('Client Name')}</label>
                  <input
                    type="text"
                    id="edit-name"
                    value={selectedAppointment.name || ''}
                    onChange={(e) =>
                      setSelectedAppointment((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    placeholder={t('Enter client name')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="edit-phone">{t('Client Phone')}</label>
                  <input
                    type="text"
                    id="edit-phone"
                    value={selectedAppointment.phone || ''}
                    onChange={(e) =>
                      setSelectedAppointment((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                    placeholder={t('Enter client phone')}
                  />
                </div>

                {/* Car Brand/Model e Plate */}
                <div className="form-group">
                  <label htmlFor="car-brand-model">{t('Car Brand and Model')}</label>
                  <input
                    type="text"
                    id="car-brand-model"
                    value={selectedAppointment.car_brand_model || ''}
                    onChange={(e) =>
                      setSelectedAppointment((prev) => ({
                        ...prev,
                        car_brand_model: e.target.value,
                      }))
                    }
                    placeholder={t('Enter car brand and model')}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="car-plate">{t('Car Plate')}</label>
                  <input
                    type="text"
                    id="car-plate"
                    value={selectedAppointment.car_plate || ''}
                    onChange={(e) =>
                      setSelectedAppointment((prev) => ({
                        ...prev,
                        car_plate: e.target.value,
                      }))
                    }
                    placeholder={t('Enter car plate')}
                  />
                </div>
              </div>

              {/* Descrição */}
              <label htmlFor="edit-description">{t('Description')}</label>
              <textarea
                id="edit-description"
                value={selectedAppointment.description || ''}
                onChange={(e) =>
                  setSelectedAppointment((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                placeholder={t('Enter event description')}
                style={{ minHeight: '170px' }} // Adiciona a altura mínima
              />

              {/* Horário de Início */}
              <label>{t('Start Time')}</label>
              <DatePicker
                selected={
                  selectedAppointment.start
                    ? new Date(selectedAppointment.start)
                    : null
                }
                onChange={(date) =>
                  setSelectedAppointment((prev) => ({
                    ...prev,
                    start: date.toISOString(),
                  }))
                }
                showTimeSelect
                className="custom-datepicker"
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="yyyy-MM-dd HH:mm"
                placeholderText={t('Select start time')}
                locale="it"
                timeCaption="Ora"
              />

              {/* Horário de Término */}
              <label>{t('End Time')}</label>
              <DatePicker
                selected={
                  selectedAppointment.end ? new Date(selectedAppointment.end) : null
                }
                onChange={(date) =>
                  setSelectedAppointment((prev) => ({
                    ...prev,
                    end: date.toISOString(),
                  }))
                }
                showTimeSelect
                className="custom-datepicker"
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="yyyy-MM-dd HH:mm"
                placeholderText={t('Select end time')}
                locale="it"
                timeCaption="Ora"
              />

              {/* Botões */}
              <div className="btn-container">
                <button
                  className="btn btn-secondary"
                  onClick={() => setIsEditModalOpen(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => handleSaveChanges(selectedAppointment)}
                >
                  {t('Save')}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal para criar/editar agendamento */}
        {modalIsOpen && (
          <div 
            className="modal-overlay"
            onClick={handleCloseModal} 
          >
            <div 
              className="modal-content modal-dialog modal-xl modal-dialog-scrollable"
              onClick={(e) => e.stopPropagation()}
            >
            <h4>{selectedAppointment ? t('Edit Appointment') : t('New Appointment')}</h4>
              <div className="form-grid">
                {/* Nome e Telefone */}
                <div className="form-group">
                  <label htmlFor="name">{t('Client Name')}</label>
                  <input
                    type="text"
                    id="name"
                    value={newEvent.name || ''}
                    onChange={(e) => setNewEvent({ ...newEvent, name: e.target.value })}
                    placeholder={t('Enter client name')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">{t('Client Phone')}</label>
                  <input
                    type="text"
                    id="phone"
                    value={newEvent.phone || ''}
                    onChange={(e) => setNewEvent({ ...newEvent, phone: e.target.value })}
                    placeholder={t('Enter client phone')}
                  />
                </div>

                {/* Car Brand/Model e Plate */}
                <div className="form-group">
                  <label htmlFor="car">{t('Car (Brand and Model)')}</label>
                  <input
                    type="text"
                    id="car"
                    value={newEvent.car_brand_model || ''}
                    onChange={(e) => setNewEvent({ ...newEvent, car_brand_model: e.target.value })}
                    placeholder={t('Enter car brand and model')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="plate">{t('Car Plate')}</label>
                  <input
                    type="text"
                    id="plate"
                    value={newEvent.car_plate || ''}
                    onChange={(e) => setNewEvent({ ...newEvent, car_plate: e.target.value })}
                    placeholder={t('Enter car plate')}
                  />
                </div>
              </div>

              {/* Descrição */}
              <label htmlFor="description">{t('Description')}</label>
              <textarea
                id="description"
                value={newEvent.description || ''}
                onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                placeholder={t('Enter event description')}
                style={{ minHeight: '170px' }} // Adiciona a altura mínima
              />

              {/* Horário de Início */}
              <label>{t('Start Time')}</label>
              <DatePicker
                className="custom-datepicker"
                selected={newEvent.start_time ? new Date(newEvent.start_time) : null}
                onChange={(date) => setNewEvent({ ...newEvent, start_time: date })}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="yyyy-MM-dd HH:mm"
                placeholderText={t('Select start time')}
                locale="it"
                timeCaption="Ora"
              />

              {/* Duração */}
              <label htmlFor="duration">{t('Duration')}</label>
              <select
                id="duration"
                value={newEvent.duration || ''}
                onChange={(e) => setNewEvent({ ...newEvent, duration: e.target.value })}
              >
                <option value="">{t('Select duration')}</option>
                <option value="30">{t('30 minutes')}</option>
                <option value="60">{t('1 hour')}</option>
                <option value="90">{t('1.5 hours')}</option>
                <option value="120">{t('2 hours')}</option>
                <option value="150">{t('2.5 hours')}</option>
                <option value="180">{t('3 hours')}</option>
                <option value="210">{t('3.5 hours')}</option>
                <option value="240">{t('4 hours')}</option>
                <option value="270">{t('4.5 hours')}</option>
                <option value="300">{t('5 hours')}</option>
                <option value="330">{t('5.5 hours')}</option>
                <option value="360">{t('6 hours')}</option>
                <option value="390">{t('6.5 hours')}</option>
                <option value="420">{t('7 hours')}</option>
                <option value="450">{t('7.5 hours')}</option>
                <option value="480">{t('8 hours')}</option>
              </select>

              {/* Botões */}
              <div className="btn-container">
                <button className="btn btn-secondary" onClick={handleCloseModal}>
                  {t('cancel')}
                </button>
                <button className="btn btn-primary" onClick={handleSaveEvent}>
                  {selectedAppointment ? t('Update') : t('Save')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuth(Calendar, ['admin', 'mechanic', 'receptionist']);