import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import logo from '../assets/images/logo.png';
import API_BASE_URL from '../config';

function Header({ toggleSidebar }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      // Chamada ao backend com o token no cabeçalho
      const token = sessionStorage.getItem('token'); // Recupera o token armazenado
  
      if (!token) {
        console.error('Token não encontrado.');
        return;
      }
  
      await axios.post(
        `${API_BASE_URL}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
  
      // Limpa o armazenamento local
      sessionStorage.clear();
  
      // Redireciona para a página de login
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  return (
    <header className="header fixed-top shadow-sm">
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          {/* Botão para abrir/fechar o menu lateral */}
          <button className="btn btn-menu" onClick={toggleSidebar}>
            <i className="bx bx-menu"></i>
          </button>

          {/* Logotipo */}
          <a className="navbar-brand" href="/dashboard">
            <img src={logo} width="120" alt="Logotipo" />
          </a>

          {/* Navegação */}
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {/* Botão de Logout */}
            <li className="nav-item">
              <button className="btn btn-link nav-link" onClick={handleLogout}>
                {t('logout')} {/* Texto traduzido */}
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;