import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import API_BASE_URL from '../config';
import moment from 'moment-timezone';

const Work = () => {
  const [hasCheckedIn, setHasCheckedIn] = useState(false);
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [workLogs, setWorkLogs] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const { t } = useTranslation();
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [totalMonthlyHours, setTotalMonthlyHours] = useState('0h 0m');

  // Função para obter o mês e ano atuais
  const getCurrentMonthYear = () => {
    const now = new Date();
    return { month: now.getMonth() + 1, year: now.getFullYear() };
  };

  // Estado para o mês selecionado
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthYear());

  // Função para formatar os horários para UTC+1
  const formatLogTimes = (logs) => {
    return logs.map((log) => ({
      ...log,
      checkin_time: log.checkin_time
        ? moment(log.checkin_time).tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss')
        : null,
      checkout_time: log.checkout_time
        ? moment(log.checkout_time).tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss')
        : null,
    }));
  };

  // Função para processar datas disponíveis
  const processAvailableDates = (dates) => {
    const uniqueMonths = new Set();
    dates.forEach((date) => {
      if (date.checkin_time) {
        const checkInDate = new Date(date.checkin_time);
        uniqueMonths.add(`${checkInDate.getFullYear()}-${checkInDate.getMonth() + 1}`);
      }
    });
    return Array.from(uniqueMonths).map((item) => {
      const [year, month] = item.split('-').map(Number);
      return { year, month };
    });
  };

  // Função para calcular as horas totais do mês
  const calculateMonthlyHours = (logs) => {
    let totalMinutes = 0;

    logs.forEach((log) => {
      if (log.duration) {
        // Extrai horas e minutos corretamente
        const [hours, minutes] = log.duration.split(' ').map((val) => {
          const numericValue = parseInt(val.replace(/\D/g, ''), 10);
          return isNaN(numericValue) ? 0 : numericValue; // Evita valores NaN
        });

        totalMinutes += hours * 60 + minutes;
      }
    });

    // Converte minutos totais em horas e minutos restantes
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return `${totalHours}h ${remainingMinutes}m`;
  };

  // Função para buscar os dados de trabalho
  const fetchWorkData = async (month, year) => {
    try {
      const token = sessionStorage.getItem('token');
  
      const params = {};
      if (month && year) {
        params.month = month;
        params.year = year;
      }
  
      const response = await axios.get(`${API_BASE_URL}/api/work/logs`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
  
      const logs = response.data;
  
      if (!Array.isArray(logs) || logs.length === 0) {
        console.warn('Nenhum log encontrado.');
        setWorkLogs([]);
        setTotalMonthlyHours('0h 0m');
        return;
      }
  
      // Atualiza os dados dos logs e calcula as horas totais
      setWorkLogs(formatLogTimes(logs));
      setTotalMonthlyHours(calculateMonthlyHours(logs));
  
      console.log('Dados retornados:', logs);
    } catch (error) {
      console.error('Erro ao buscar dados de trabalho:', error);
      setWorkLogs([]);
      setTotalMonthlyHours('0h 0m');
    }
  };

  // Função para buscar os meses disponíveis
  const fetchAvailableMonths = async () => {
    try {
      const token = sessionStorage.getItem('token');

      const response = await axios.get(`${API_BASE_URL}/api/work/available-months`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const months = response.data;

      // Formata os meses para ordenação e exibição
      const formattedMonths = months.map(({ year, month }) => ({
        year,
        month,
      }));

      setAvailableDates(formattedMonths);
    } catch (error) {
      console.error('Erro ao buscar meses disponíveis:', error);
      setAvailableDates([]);
    }
  };

  // Função para lidar com a mudança de mês
  const handleMonthChange = (e) => {
    const value = e.target.value;
  
    if (!value) {
      // "Todo o Período" selecionado
      setSelectedMonth(null);
      fetchWorkData(); // Chama sem mês e ano para obter todos os registros
    } else {
      const [year, month] = value.split('-').map(Number);
      setSelectedMonth({ year, month });
      fetchWorkData(month, year);
    }
  };

  // useEffect para carregar o mês atual ao montar o componente
  useEffect(() => {
    const { year, month } = getCurrentMonthYear();
    setSelectedMonth({ year, month });
    fetchWorkData(month, year);
    fetchAvailableMonths(); // Buscar meses disponíveis ao montar
  }, []);

  // useEffect para atualizar o timer quando o usuário fez check-in
  useEffect(() => {
    if (hasCheckedIn) {
      const interval = setInterval(() => {
        const duration = Date.now() - new Date(hasCheckedIn).getTime();
        const hours = Math.floor(duration / 3600000);
        const minutes = Math.floor((duration % 3600000) / 60000);
        const seconds = Math.floor((duration % 60000) / 1000);
        setTimer({ hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [hasCheckedIn]);

  // useEffect para obter a localização e verificar permissões
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ latitude, longitude });
        verifyLocation(latitude, longitude);
      },
      (error) => {
        console.error('Erro ao obter a localização:', error);
        alert(t('notPermitedLocationAble'));
      }
    );

    checkCheckInStatus();
  }, []);

  // useEffect para verificar a localização sempre que a posição atualiza
  useEffect(() => {
    if (currentPosition) {
      verifyLocation(currentPosition.latitude, currentPosition.longitude);
    }
  }, [currentPosition]);

  // Função para verificar se a localização está autorizada
  const verifyLocation = async (latitude, longitude) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/work/verify-location`,
        { latitude, longitude },
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        }
      );

      console.log('Resposta do backend:', response.data);
      setLocationAllowed(response.data.allowed);
    } catch (error) {
      console.error('Erro ao verificar localização:', error.response?.data || error.message);
      setLocationAllowed(false);
    }
  };

  // Função para verificar o status de check-in do usuário
  const checkCheckInStatus = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/work/checkin-status`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      if (response.data.hasCheckedIn) {
        setHasCheckedIn(new Date(response.data.checkInTime));
      } else {
        setHasCheckedIn(false);
      }
    } catch (error) {
      console.error('Erro ao verificar status de check-in:', error);
    }
  };

  // Função para lidar com check-in e check-out
  const handleCheck = async (type) => {
    if (!locationAllowed) {
      alert(t('notAuthorizedLocation'));
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/work/check`,
        { type, location: currentPosition },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } }
      );

      if (type === 'check-in') {
        setHasCheckedIn(new Date());
      } else {
        setHasCheckedIn(false);
      }
    } catch (error) {
      console.error('Erro ao registrar check-in ou check-out:', error);
      alert(t('errorMsgRegister'));
    }
  };

  // Função para obter o nome do mês traduzido
  const getTranslatedMonth = (month) => {
    const months = [
      t('january'),
      t('february'),
      t('march'),
      t('april'),
      t('may'),
      t('june'),
      t('july'),
      t('august'),
      t('september'),
      t('october'),
      t('november'),
      t('december'),
    ];
    return months[month - 1];
  };

  const formatDateTimeWithExtraHour = (dateTime, format) => {
    return moment(dateTime)
      .tz('Europe/Rome')
      .add(1, 'hour')
      .format(format);
  };
  
  // Renderiza os logs de trabalho
  const renderWorkLogs = () => {
    if (!Array.isArray(workLogs) || workLogs.length === 0) {
      return <p>{t('noWorkLogs')}</p>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-striped custom-table modern-table">
          <thead className="table-light">
            <tr>
              <th className="text-center">{t('date')}</th>
              <th className="text-center">{t('checkin_time')}</th>
              <th className="text-center">{t('checkout_time')}</th>
              <th className="text-center">{t('duration')}</th>
            </tr>
          </thead>
          <tbody>
            {workLogs.map((log, index) => (
              <tr key={index}>
                <td>
                  {log.checkin_time
                    ? formatDateTimeWithExtraHour(log.checkin_time, 'YYYY-MM-DD')
                    : '-'}
                </td>
                <td>
                  {log.checkin_time
                    ? formatDateTimeWithExtraHour(log.checkin_time, 'HH:mm:ss')
                    : '-'}
                </td>
                <td>
                  {log.checkout_time
                    ? formatDateTimeWithExtraHour(log.checkout_time, 'HH:mm:ss')
                    : '-'}
                </td>
                <td>{log.duration || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-2 text-muted small text-center">
          <i className="fas fa-info-circle me-2"></i>{t('tooltipLunchDiscount')}
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="location-status">
              {locationAllowed ? (
                <p className="text-authorized">
                  <i className="fas fa-check-circle"></i> {t('authorizedLocation')}
                </p>
              ) : (
                <p className="text-not-authorized">
                  <i className="fas fa-times-circle"></i> {t('notAuthorizedLocation')}
                </p>
              )}
            </div>

            <div>
              {hasCheckedIn ? (
                <button
                  className="btn btn-checkout"
                  onClick={() => handleCheck('check-out')}
                  disabled={!locationAllowed}
                >
                  <i className="fas fa-sign-out-alt"></i> {t('check-out')}
                </button>
              ) : (
                <button
                  className="btn btn-checkin"
                  onClick={() => handleCheck('check-in')}
                  disabled={!locationAllowed}
                >
                  <i className="fas fa-sign-in-alt"></i> {t('check-in')}
                </button>
              )}
            </div>
          </div>
          {hasCheckedIn ? (
            <div className="timer-clock">
              <span>{String(timer.hours).padStart(2, '0')}</span>:
              <span>{String(timer.minutes).padStart(2, '0')}</span>:
              <span>{String(timer.seconds).padStart(2, '0')}</span>
            </div>
          ) : (
            <div className="timer-clock inactive">
              <span>00</span>:<span>00</span>:<span>00</span>
            </div>
          )}

          <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="section-title">{t('workLogs')}</h4>
              <div className="filter-container">
                <div className="btn-group">
                  <select
                    value={
                      selectedMonth
                        ? `${selectedMonth.year}-${selectedMonth.month.toString().padStart(2, '0')}`
                        : ''
                    }
                    onChange={handleMonthChange}
                  >
                    <option value="">{t('allPeriod')}</option>
                    {availableDates.map((date, index) => (
                      <option key={index} value={`${date.year}-${date.month.toString().padStart(2, '0')}`}>
                        {`${getTranslatedMonth(date.month)} ${date.year}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="total-hours">
              <h5>{t('totalMonthlyHours')}: {totalMonthlyHours}</h5>
            </div>

            {renderWorkLogs()}
          </div>
        </div>
      </div>
    </div>
  );
};

// Exporta o componente com autenticação, permitindo apenas admin, mechanic e receptionist
export default withAuth(Work, ['admin', 'mechanic', 'receptionist']);