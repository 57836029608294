import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable'; // Importação correta do hook
import Sidebar from './Sidebar';
import Header from './Header';
import '../assets/css/layout.css';

const Layout = ({ children }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isSidebarVisible) {
        setIsSidebarVisible(false);
      }
    },
    onSwipedRight: () => {
      if (!isSidebarVisible) {
        setIsSidebarVisible(true);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false, // Opcional: permite detectar gestos com o mouse para testes em desktop
  });

  return (
    <div {...handlers} className="layout-container">
      <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
      <div className={`main-content ${isSidebarVisible ? 'sidebar-open' : ''}`}>
        <Header toggleSidebar={toggleSidebar} />
        <div className="content-area">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;