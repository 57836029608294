import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import flag_it from '../assets/flags/1x1/it.svg';
import flag_br from '../assets/flags/1x1/br.svg';
import flag_en from '../assets/flags/1x1/gb.svg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_BASE_URL from '../config';

function Login() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Limpar mensagens de erro anteriores
  
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, {
        email,
        password, // Envia a senha sem criptografar; criptografia será feita no backend
      });
  
      if (response.status === 200) {
        const { token, role, preferred_language } = response.data;
  
        // Armazena o token e o role na sessão do navegador
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('role', role);
        sessionStorage.setItem('preferred_language', preferred_language); // Salva o idioma na sessão
  
        // Define o idioma salvo no banco de dados
        if (preferred_language) {
          i18n.changeLanguage(preferred_language);
        }
  
        navigate('/dashboard'); // Redireciona para o dashboard
      } else {
        setError(t('login_failed'));
      }
    } catch (err) {
      console.error('Erro ao fazer login:', err);
      setError(t('login_error')); // Mensagem de erro do backend ou erro genérico
    }
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="wrapper bg-login">
      <header className="login-header shadow">
        <nav className="navbar navbar-expand-lg navbar-light bg-white rounded fixed-top rounded-0 shadow-sm">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src={logo} width="140" alt="Logotipo da Marca" />
            </a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent1">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <button className="nav-link btn" onClick={() => changeLanguage('it')}>
                    <img src={flag_it} width="20" height="15" alt="Italiano" />
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link btn" onClick={() => changeLanguage('pt')}>
                    <img src={flag_br} width="20" height="15" alt="Português" />
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link btn" onClick={() => changeLanguage('en')}>
                    <img src={flag_en} width="20" height="15" alt="Inglês" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <div className="d-flex align-items-center justify-content-center my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12 mx-auto">
              <div className="card mt-5">
                <div className="card-body">
                  <div className="border p-4 rounded">
                    <center><img src={logo} height="50" /></center>
                    <br /><hr />
                    <div className="text-center">
                      <h4>{t('login')}</h4>
                    </div>
                    <br />
                    {error && <p className="text-danger text-center">{error}</p>}
                    <form onSubmit={handleLogin} className="row g-3">
                      <div className="col-12">
                        <label htmlFor="inputEmailAddress" className="form-label">{t('email')}</label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmailAddress"
                          placeholder="example@user.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="inputChoosePassword" className="form-label">{t('password')}</label>
                        <div className="input-group" id="show_hide_password">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            className="form-control"
                            id="inputChoosePassword"
                            placeholder={t('password')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <button type="button" className="input-group-text bg-transparent" onClick={togglePasswordVisibility}>
                            <i className={`bx ${showPassword ? 'bx-show' : 'bx-hide'}`}></i>
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-grid">
                          <button type="submit" className="btn btn-primary">
                            <i className="bx bx-user"></i> {t('submit')}
                          </button>
                        </div>
                      </div>
                      <div class="col-12">
												<div class="text-center">
													<p class="mb-0">{t('forgotPassword')} <a href="/">{t('resetPassword')}</a>
													</p>
												</div>
											</div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-white shadow-sm border-top p-2 text-center fixed-bottom">
        <p className="mb-0">
          Created By <a href="https://www.instagram.com/gabriel.bussolo">Gabriel Bussolo</a> made it with ❤️.
        </p>
      </footer>
    </div>
  );
}

export default Login;