import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API_BASE_URL from '../config';
import withAuth from '../hoc/withAuth';
import Timer from '../components/Timer';
import { toast, ToastContainer } from 'react-toastify';
import './Tickets.css';
import jsPDF from 'jspdf';
import logo from '../assets/images/big_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import Select from 'react-select';
import BudgetPanel from '../components/BudgetPanel'

// Configure o Modal
Modal.setAppElement('#root');

function Tickets() {
  const { t } = useTranslation();
  const { id } = useParams();
  const chatContainerRef = useRef(null);
  const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState("All");
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [isFirstChat, setIsFirstChat] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMechanicModalOpen, setIsMechanicModalOpen] = useState(false);
  const [mechanics, setMechanics] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [isDueDateModalOpen, setIsDueDateModalOpen] = useState(false);
  const [newDueDate, setNewDueDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [assignedMechanic, setAssignedMechanic] = useState(null);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [mediaToView, setMediaToView] = useState(null);
  const [imageWidth, setImageWidth] = useState('50%');

  const toggleImageWidth = () => {
    setImageWidth(prevWidth => (prevWidth === '100%' ? '50%' : '100%'));
  };

  const [isBudgetPanelOpen, setIsBudgetPanelOpen] = useState(false);
  const [budgetItems, setBudgetItems] = useState([]);

  const openBudget = () => {
    setIsBudgetPanelOpen(true);
  };

  const closeBudget = () => {
    setIsBudgetPanelOpen(false);
  };

  const fetchBudgetItems = async () => {
    try {
      // Lógica de GET /api/tickets/:ticketId/budget
    } catch (error) {
      console.error(error);
      toast.error('Erro ao buscar itens do orçamento.');
    }
  };

  const handleOpenBudget = () => {
    fetchBudgetItems();
    setIsBudgetPanelOpen(true);
  };

  const [timers, setTimers] = useState({
    mechanicTimers: [],
    totalTimer: null,
  });

  const resultsPerPage = 10;

  const openReopenModal = () => {
    setIsReopenModalOpen(true);
  };

  const closeReopenModal = () => {
    setIsReopenModalOpen(false);
  };

  useEffect(() => {
    let interval = null;
  
    if (selectedTicket) {
      fetchTimers(selectedTicket.id);
  
      interval = setInterval(() => {
        fetchTimers(selectedTicket.id);
      }, 5000);
    }
  
    return () => clearInterval(interval);
  }, [selectedTicket]);

  const handleReopenTicket = async () => {
    if (!selectedTicket) {
      toast.error(t("No ticket selected."));
      return;
    }

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/reopen`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t("Error reopening ticket."));
      }

      toast.success(t("Ticket reopened successfully."));

      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        status: 2, // Assuming 'Pending' has status 2
      }));

      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id ? { ...ticket, status: 2, statusTranslation: t("Pending"), statusClassName: "pending" } : ticket
        )
      );

      closeReopenModal();
    } catch (error) {
      console.error("Erro ao reabrir o ticket:", error);
      toast.error(error.message || t("Error reopening ticket."));
    }
  };

  const filteredTickets = useMemo(() => {
    return tickets.filter((ticket) => {
      const query = searchQuery.toLowerCase();
      return (
        (filterStatus === "All" || ticket.statusClassName === filterStatus.toLowerCase()) &&
        (
          ticket.client.name.toLowerCase().includes(query) ||
          ticket.client.phone.includes(query) ||
          ticket.car.model.toLowerCase().includes(query) ||
          ticket.car.brand.toLowerCase().includes(query) ||
          ticket.car.plate.toLowerCase().includes(query)
        )
      );
    });
  }, [tickets, searchQuery, filterStatus]);

  const sortedTickets = useMemo(() => {
    return [...filteredTickets].sort((a, b) => b.id - a.id);
  }, [filteredTickets]);

  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);
  
    // Ajustar a janela se não houver páginas suficientes à esquerda ou à direita
    if (currentPage === 1) {
      endPage = Math.min(totalPages, startPage + 2);
    } else if (currentPage === totalPages) {
      startPage = Math.max(1, endPage - 2);
    }
  
    // Caso o total de páginas seja menor que maxPagesToShow
    if (totalPages < maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    }
  
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  
    return pages;
  };

  // Em seguida, faça os cálculos que dependem de 'filteredTickets'
  const indexOfLastTicket = currentPage * resultsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - resultsPerPage;
  const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);
  const totalPages = Math.ceil(sortedTickets.length / resultsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const adjustTimePlusOneHour = (time) => {
    if (!time) return null;
    return moment(time).add(1, 'hour').format('DD-MM-YYYY HH:mm:ss');
  };

  const [userId, setUserId] = useState(null);


  useEffect(() => {
    function checkMobileView() {
      setIsMobileView(window.innerWidth < 768);
    }
    
    checkMobileView(); // Checa no carregamento
    window.addEventListener('resize', checkMobileView);
    
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  useEffect(() => {
    if (id) {
      const fetchTicket = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${API_BASE_URL}/api/tickets/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Erro ao buscar ticket.');
          }
  
          const ticket = await response.json();
          setSelectedTicket(ticket);
        } catch (error) {
          console.error('Erro ao buscar ticket:', error);
        }
      };
  
      fetchTicket();
    }
  }, [id]);

  // Função para abrir o modal
  const openDueDateModal = () => {
    setNewDueDate(selectedTicket.due_date ? new Date(selectedTicket.due_date) : null);
    setIsDueDateModalOpen(true);
  };

  // Função para fechar o modal
  const closeDueDateModal = () => {
    setIsDueDateModalOpen(false);
  };

  // Função para salvar a nova data de entrega
  const saveDueDate = async () => {
    if (!newDueDate) {
      toast.error(t('Por favor, selecione uma data.'));
      return;
    }
  
    // Formatar a data no formato YYYY-MM-DD HH:mm:ss
    const formattedDate = moment(newDueDate).format('YYYY-MM-DD HH:mm:ss');
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/due_date`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ due_date: formattedDate }),
      });
  
      const responseData = await response.json(); // Ler a resposta uma única vez
  
      if (!response.ok) {
        throw new Error(responseData.error || 'Erro ao atualizar a data de entrega.');
      }
  
      toast.success(t('Delivery date updated successfully.'));
  
      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        due_date: formattedDate,
      }));
  
      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id ? { ...ticket, due_date: formattedDate } : ticket
        )
      );
  
      closeDueDateModal();
    } catch (error) {
      console.error('Erro ao atualizar a data de entrega:', error);
      toast.error(error.message || t('Error updating the delivery date.'));
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/tickets`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error("Erro ao buscar tickets.");
        }
  
        const data = await response.json();
        const mappedTickets = data.map((ticket) => {
          const { translation, className } = mapStatus(ticket.status);
          return {
            ...ticket,
            statusTranslation: translation, // Texto traduzido
            statusClassName: className,    // Classe CSS fixa
          };
        });
  
        setTickets(mappedTickets);
      } catch (error) {
        console.error("Erro ao buscar tickets:", error);
      }
    };
  
    fetchTickets();
  }, []);

  useEffect(() => {
    let isMounted = true;
  
    const fetchMessages = async () => {
      if (!selectedTicket) return;
  
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/messages`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar mensagens.');
        }
  
        const messages = await response.json();
  
        const sortedMessages = messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  
        if (sortedMessages.length !== chatMessages.length ||
            sortedMessages.some((msg, index) => msg.id !== chatMessages[index]?.id)) {
          if (isMounted) {
            setChatMessages(sortedMessages);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar mensagens:', error);
      } finally {
        if (isMounted) {
          setTimeout(fetchMessages, 5000);
        }
      }
    };
  
    fetchMessages();
  
    return () => {
      isMounted = false;
    };
  }, [selectedTicket, chatMessages]);

  const mapStatus = (status) => {
    switch (status) {
      case 1:
        return { translation: t("Not Assigned"), className: "not-assigned" };
      case 2:
        return { translation: t("Pending"), className: "pending" };
      case 3:
        return { translation: t("Working"), className: "working" };
      case 4:
        return { translation: t("Finished"), className: "finished" };
      default:
        return { translation: t("Unknown"), className: "unknown" };
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token não encontrado.');
          return;
        }
  
        const response = await fetch(`${API_BASE_URL}/user`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar dados do usuário.');
        }
  
        const user = await response.json();
        setUserId(user.id); // Define o userId no estado
        setUserRole(user.role); // Define o role no estado
      } catch (error) {
        console.error('Erro ao buscar o ID do usuário:', error);
      }
    };
  
    fetchUserData();
  }, []);

  // Função para buscar os timers quando um ticket é selecionado
  const fetchTimers = async (ticketId) => {
    try {
      const token = sessionStorage.getItem('token');
      console.log(`Fetching timers for ticket ID: ${ticketId}`);
      const response = await fetch(`${API_BASE_URL}/api/tickets/${ticketId}/timers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Fetch response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response data:', errorData);
        throw new Error(errorData.error || 'Erro ao buscar timers.');
      }

      const data = await response.json();
      console.log('Timers fetched:', data);

      if (userRole === 'mechanic') {
        setTimers({
          mechanicTimers: data.mechanicTimer ? [data.mechanicTimer] : [],
          totalTimer: null,
        });
      } else if (userRole === 'admin' || userRole === 'receptionist') {
        setTimers({
          mechanicTimers: data.mechanicTimers || [],
          totalTimer: data.totalTime || 0,
        });
      }
    } catch (error) {
      console.error('Erro ao buscar timers:', error);
      toast.error(error.message || 'Erro ao buscar timers.');
    }
  };

  useEffect(() => {
    if (selectedTicket) {
      fetchTimers(selectedTicket.id);
    }
  }, [selectedTicket]);

  // Função para registrar ações do timer como mensagens no ticket
  const handleTimerAction = async (action) => {
    if (!selectedTicket) return;
  
    let timerId = null;
  
    if (userRole === 'mechanic' && timers.mechanicTimers.length > 0) {
      timerId = timers.mechanicTimers[0].id;
    }
  
    if (timerId) {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/timers/${timerId}/action`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ action }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Error to register action of timer.');
        }
  
        toast.success(t('action_clicked_successfully', { action }));
        fetchTimers(selectedTicket.id); // Atualiza os timers após a ação
      } catch (error) {
        console.error('Erro ao registrar ação do timer:', error);
        toast.error(error.message || t('Error to register action of timer.'));
      }
    } else {
      toast.error(t('Timer not founded.'));
    }
  };

  const handlePrint = async () => {
    const doc = new jsPDF('p', 'mm', 'a4');
    const currentDate = new Date().toLocaleDateString();
  
    if (!selectedTicket) {
      toast.error(t('No ticket selected.'));
      return;
    }
  
    // Dados do ticket e cliente
    const clientName = selectedTicket.client?.name || '-';
    const clientPhone = selectedTicket.client?.phone || '-';
    const carBrand = selectedTicket.car?.brand || '-';
    const carModel = selectedTicket.car?.model || '-';
    const carPlate = selectedTicket.car?.plate || '-';
    const carMileage = selectedTicket.car?.mileage || '-';
    const problemDescription = selectedTicket.car?.problem || t('No description provided');
    const ticketId = selectedTicket.id || '-';
    const mechanicName = selectedTicket.mechanic || t('Not Assigned');
    const openingDate = selectedTicket.dateOpened || '-';
  
    // Cabeçalho
    doc.addImage(logo, 'PNG', 10, 15, 50, 30);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    doc.text('3B AUTO DI ELIDIR BUSNELLO E C. SAS', 65, 20);
  
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    const companyInfo = [
      'via Sandro Pertini, 20',
      '46045, MARMIROLO (MN)',
      'P. Iva/ Cod. Fiscale: 02709760207',
      'SDI: WTYVJK9',
      'Tel: 389 026 2392',
      'Email: 3BAuto3B@gmail.com',
    ];
    let companyY = 25;
    companyInfo.forEach((line) => {
      doc.text(65, companyY, line);
      companyY += 5;
    });
  
    // Informações do Cliente
    const headerY = 60;
    doc.setFillColor(230, 230, 230);
    doc.rect(10, headerY, 190, 30, 'F');
    doc.setFont('helvetica', 'bold');
    doc.text('Dati Cliente', 12, headerY + 6);
  
    doc.setFont('helvetica', 'normal');
    doc.text(`Nome: ${clientName}`, 12, headerY + 12);
    doc.text(`Telefono: ${clientPhone}`, 12, headerY + 18);
    doc.text(`Data: ${currentDate}`, 140, headerY + 12);
    doc.text(`Ticket Numero: ${ticketId}`, 140, headerY + 18);
  
    // Detalhes do Veículo
    const vehicleHeaderY = headerY + 35;
    doc.setFillColor(230, 230, 230);
    doc.rect(10, vehicleHeaderY, 190, 30, 'F');
    doc.setFont('helvetica', 'bold');
    doc.text('Dettagli del Veicolo', 12, vehicleHeaderY + 6);
  
    const vehicleDetails = [
      { label: 'Marca', value: carBrand },
      { label: 'Modello', value: carModel },
      { label: 'Targa', value: carPlate },
      { label: 'Chilometraggio', value: carMileage },
    ];
  
    let leftColumnY = vehicleHeaderY + 12;
    let rightColumnY = vehicleHeaderY + 12;
    vehicleDetails.forEach((detail, index) => {
      if (index < 3) {
        doc.text(`${detail.label}: ${detail.value}`, 12, leftColumnY);
        leftColumnY += 6;
      } else {
        doc.text(`${detail.label}: ${detail.value}`, 110, rightColumnY);
        rightColumnY += 6;
      }
    });
  
    // Descrição do Problema
    const problemHeaderY = Math.max(leftColumnY, rightColumnY) + 10;
    doc.setFont('helvetica', 'bold');
    doc.text('Descrizione del Problema', 12, problemHeaderY);
  
    const splitDescription = doc.splitTextToSize(problemDescription, 190);
    doc.setFont('helvetica', 'normal');
    doc.text(splitDescription, 12, problemHeaderY + 5);
  
    const borderHeight = splitDescription.length * 6 + 10;
    doc.setLineWidth(0.5);
    doc.rect(10, problemHeaderY - 5, 190, borderHeight);
  
    // Informações Adicionais
    const mechanicHeaderY = problemHeaderY + borderHeight + 10;
    doc.setFont('helvetica', 'bold');
    doc.text('Dettagli del Ticket', 12, mechanicHeaderY);
  
    doc.setFont('helvetica', 'normal');
    doc.text(`Mecânico Assegnato: ${mechanicName}`, 12, mechanicHeaderY + 6);
    doc.text(`Data di Apertura: ${openingDate}`, 12, mechanicHeaderY + 12);
  
    // Exibe o PDF
    window.open(doc.output('bloburl'), '_blank');
  };

  const handleTicketClick = async (ticket) => {
    setSelectedTicket(ticket);
    setChatMessages([]);
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${ticket.id}/messages`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao buscar mensagens.');
      }
  
      const messages = await response.json();
      
      const sortedMessages = messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  
      setChatMessages(sortedMessages);
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    }
  };

  const handleCloseTicket = async () => {
    if (!selectedTicket) {
      toast.error(t("No ticket selected."));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/close`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to close the ticket.");
      }
  
      toast.success(t("Ticket closed successfully."));

      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        status: 4,
      }));

      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id ? { ...ticket, status: 4, statusTranslation: t("Finished"), statusClassName: "finished" } : ticket
        )
      );
    } catch (error) {
      console.error("Erro ao fechar o ticket:", error);
      toast.error(t("Error closing ticket. Please try again."));
    }
  };

  const renderMessage = (message) => {
    if (typeof message === 'string') {
      try {
        const parsedMessage = JSON.parse(message);
        switch (parsedMessage.key) {
          case 'user_reopen_ticket_message':
            return t('user_reopen_ticket_message', { user: parsedMessage.user });
          case 'user_closed_ticket_message':
            return t('user_closed_ticket_message', { user: parsedMessage.user });
          case 'mechanic_removed_message':
            return t('mechanic_removed_message', { mechanic: parsedMessage.mechanic });
          case 'mechanic_inserted_message':
            return t('mechanic_inserted_message', { mechanic: parsedMessage.mechanic });
          case 'mechanic_restarted_message':
            return t('mechanic_restarted_message', { mechanic: parsedMessage.mechanic });
          case 'mechanic_started_message':
            return t('mechanic_started_message', { mechanic: parsedMessage.mechanic });
          case 'mechanic_paused_message':
            return t('mechanic_paused_message', { mechanic: parsedMessage.mechanic });
          case 'mechanic_finished_message':
            return t('mechanic_finished_message', { 
              mechanic: parsedMessage.mechanic, 
              totalTime: parsedMessage.totalTime 
            });
          case 'ticket_closed_successfully':
            return t('ticket_closed_successfully');
          case 'ticket_status_changed':
            return t('ticket_status_changed', { status: parsedMessage.status, user: parsedMessage.user });
          default:
            return message;
        }
      } catch (error) {
        console.error('Erro ao analisar mensagem JSON:', error);
        return message; // Retorna a mensagem original se não for JSON válido
      }
    }
    return message; // Retorna a mensagem original se não for uma string
  };

  // Função para determinar o estado atual do timer
  const getCurrentTimerState = () => {
    if (!timers.mechanicTimers || timers.mechanicTimers.length === 0) {
      return 'idle'; // Nenhum timer encontrado
    }
  
    const timer = timers.mechanicTimers[0];
    if (timer.is_running) {
      return 'running'; // Timer em execução
    } else if (!timer.is_running && timer.total_time > 0) {
      return 'paused'; // Timer pausado
    }
    return 'idle'; // Estado padrão
  };

  const timerState = getCurrentTimerState();

  // Função para renderizar os botões de controle do timer
  const renderTimerControls = () => {
    if (userRole !== 'mechanic') {
      return null; // Impede a renderização dos botões para outros papéis
    }
  
    switch (timerState) {
      case 'idle': // Nenhum timer em execução
        return (
          <button
            className="btn btn-success"
            onClick={() => handleTimerAction('start')}
          >
            {t('Start')}
          </button>
        );
      case 'running': // Timer em execução
        return (
          <>
            <button
              className="btn btn-warning"
              onClick={() => handleTimerAction('pause')}
            >
              {t('Pause')}
            </button>
          </>
        );
      case 'paused': // Timer pausado
        return (
          <>
            <button
              className="btn btn-primary"
              onClick={() => handleTimerAction('resume')}
            >
              {t('Resume')}
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleTimerAction('stop')}
            >
              {t('Finish')}
            </button>
          </>
        );
      default:
        return null;
    }
  };

  // Função para formatar o tempo total em hh:mm:ss
  const formatTotalTime = (totalSeconds) => {
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    // Definir limite de tamanho (por exemplo, 50MB)
    const maxSize = 50 * 1024 * 1024; // 50MB
    if (file.size > maxSize) {
      toast.error(t("O arquivo excede o tamanho máximo permitido de 50MB."));
      return;
    }

    // Determinar o tipo de arquivo
    const fileType = file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : null;

    if (!fileType) {
      toast.error(t("File not supported."));
      return;
    }

    // Verificar a duração do vídeo, se aplicável
    if (fileType === 'video') {
      try {
        const videoURL = URL.createObjectURL(file);
        const video = document.createElement('video');

        const duration = await new Promise((resolve, reject) => {
          video.preload = 'metadata';
          video.src = videoURL;
          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            resolve(video.duration);
          };
          video.onerror = () => {
            reject(new Error("Falha ao carregar o vídeo."));
          };
        });

        if (duration > 180) { // 3 minutos
          toast.error(t("O vídeo excede a duração máxima de 3 minutos."));
          return;
        }
      } catch (error) {
        console.error("Erro ao verificar a duração do vídeo:", error);
        toast.error(t("Falha ao processar o vídeo."));
        return;
      }
    }

    // Gerar uma mensagem temporária com a pré-visualização
    const tempId = `temp_${Date.now()}`;
    const previewUrl = URL.createObjectURL(file);
    const tempMessage = {
      id: tempId,
      sender: userId,
      senderName: 'You',
      message: fileType === 'image' 
        ? `<div class="uploading-preview">
             <img src="${previewUrl}" alt="uploading" />
             <div class="spinner"></div>
           </div>` 
        : `<div class="uploading-preview">
             <video src="${previewUrl}" controls />
             <div class="spinner"></div>
           </div>`,
      created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
      uploading: true,
      fileType,
    };

    // Adicionar a mensagem temporária ao chat
    setChatMessages(prevMessages => [...prevMessages, tempMessage]);

    // Iniciar o upload do arquivo
    const formData = new FormData();
    formData.append('file', file); // Use sempre 'file' como nome do campo

    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/upload-file`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Erro ao fazer upload do ${fileType}.`);
      }

      const data = await response.json();
      const fileUrl = data.fileUrl;

      // Gerar o conteúdo da mensagem com a URL do arquivo
      const newMessageContent = fileType === 'image' 
        ? `<img src="${fileUrl}" alt="uploaded" class="chat-media" />` 
        : `<video src="${fileUrl}" controls class="chat-media"></video>`;

      // Atualizar a mensagem temporária com o conteúdo final
      setChatMessages(prevMessages => 
        prevMessages.map(msg => 
          msg.id === tempId ? { ...msg, message: newMessageContent, uploading: false } : msg
        )
      );
      
      // Enviar a mensagem para o backend para persistência
      handleSendMessage(newMessageContent);

      toast.success(t(`${fileType.charAt(0).toUpperCase() + fileType.slice(1)} enviato con successo.`));
    } catch (error) {
      console.error(`Error to upload ${fileType}:`, error);
      toast.error(t(`Error to upload ${fileType}.`));

      // Remover a mensagem temporária em caso de erro
      setChatMessages(prevMessages => prevMessages.filter(msg => msg.id !== tempId));
    }
  };

  const handleRemoveMechanic = async () => {
    if (!selectedTicket) {
      toast.error(t("No ticket selected."));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/remove-mechanic`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t('Erro ao remover mecânico.'));
      }
  
      toast.success(t('Mechanic successfully removed.'));

      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        status: 1,
        mechanic_id: null,
        mechanic: null,
      }));

      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id 
            ? { 
                ...ticket, 
                status: 1, 
                statusTranslation: t("Not Assigned"), 
                statusClassName: "not-assigned",
                mechanic_id: null,
                mechanic: null
              } 
            : ticket
        )
      );
    } catch (error) {
      console.error('Erro ao remover mecânico:', error);
      toast.error(error.message || t('Erro ao remover mecânico.'));
    }
  };


  useEffect(() => {
    const fetchMechanics = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/mechanics`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error(t("Error fetching mechanics."));
        }
  
        const data = await response.json();
        setMechanics(data);
      } catch (error) {
        console.error("Error fetching mechanics:", error);
        toast.error(error.message || t("Error fetching mechanics."));
      }
    };
  
    fetchMechanics();
  }, [t]);

  const handleAssignMechanic = async () => {
    if (!assignedMechanic) {
      toast.error(t("Please select a mechanic."));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/assign-mechanic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ mechanic_id: assignedMechanic.id }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t("Error assigning mechanic."));
      }
  
      toast.success(t("Mechanic assigned successfully."));
  
      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id
            ? { ...ticket, mechanic: assignedMechanic.name, mechanic_id: assignedMechanic.id, status: 2 }
            : ticket
        )
      );
  
      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        mechanic: assignedMechanic.name,
        mechanic_id: assignedMechanic.id,
        status: 2
      }));
  
      setIsAssignModalOpen(false);
    } catch (error) {
      console.error("Error assigning mechanic:", error);
      toast.error(error.message || t("Error assigning mechanic."));
    }
  };

  const handleSendMessage = async (message) => {
    if (!message.trim()) {
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ message }),
      });
  
      if (!response.ok) {
        throw new Error('Erro ao enviar mensagem.');
      }
  
      const data = await response.json();
  
      const newMessage = {
        id: data.id,
        sender: userId,
        senderName: 'You',
        message,
        created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
      };
  
      setChatMessages((prevMessages) => [...prevMessages, newMessage]);
  
      // Reseta o estado de primeira vez no chat
      if (isFirstChat) setIsFirstChat(false);
  
      // Atualiza o conteúdo do ticket selecionado na lista de tickets
      const updatedTickets = tickets.map((ticket) =>
        ticket.id === selectedTicket.id
          ? { ...ticket, content: [...(ticket.content || []), newMessage] }
          : ticket
      );
  
      setTickets(updatedTickets);
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };

  const handleMediaClick = (url, type) => {
    setMediaToView({ url, type });
    setIsMediaModalOpen(true);
  };

  return (
    <div className="page-wrapper" style={{ height: "100vh" }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="page-content" style={{ height: "100%" }}>
        <div className="tickets-wrapper d-flex" style={{ height: "100%" }}>
        {!selectedTicket ? (
          <div className="tickets-sidebar" style={{ flex: "0 0 100%", overflowY: "auto" }}>
            <div className="tickets-sidebar-header">
              <div className="input-group input-group-sm mt-3">
                <span className="input-group-text bg-transparent">
                  <i className="bx bx-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Search by client name, phone, car model, brand, or plate")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div role="group" aria-label="Filter Status">
                  <button
                    type="button"
                    className={`filter-button mb-2 all ${filterStatus === 'All' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('All')}
                  >
                    {t("All Tickets")}
                  </button>
                  {userRole !== 'mechanic' && (
                    <button
                      type="button"
                      className={`filter-button mb-2 not-assigned ${filterStatus === 'Not-Assigned' ? 'active' : ''}`}
                      onClick={() => setFilterStatus('Not-Assigned')}
                    >
                      {t("Not Assigned")}
                    </button>
                  )}
                  <button
                    type="button"
                    className={`filter-button mb-2 pending ${filterStatus === 'Pending' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('Pending')}
                  >
                    {t("Pending")}
                  </button>
                  <button
                    type="button"
                    className={`filter-button mb-2 working ${filterStatus === 'Working' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('Working')}
                  >
                    {t("Working")}
                  </button>
                  <button
                    type="button"
                    className={`filter-button mb-2 finished ${filterStatus === 'Finished' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('Finished')}
                  >
                    {t("Finished")}
                  </button>
                </div>
              </div>
            </div>

            <div className="tickets-list mt-4">
              <div className="list-group list-group-flush">
                {currentTickets.length > 0 ? (
                  currentTickets
                    .sort((a, b) => b.id - a.id) // Ordena do maior para o menor (último ao primeiro)
                    .map((ticket) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        key={ticket.id}
                        href="#"
                        className={`list-group-item ${
                          selectedTicket?.id === ticket.id ? "active" : ""
                        }`}
                        onClick={() => handleTicketClick(ticket)}
                      >
                        <div className="d-flex">
                          <div className="ticket-user-badge">
                            #{ticket.id}
                          </div>
                          <div className="flex-grow-1 ms-2">
                            <h6 className="mb-0 ticket-title">{ticket.title}</h6>
                            <p className="mb-0 ticket-msg">
                              {ticket.message.slice(0, 30)}...
                            </p>
                            <p className={`ticket-status ${ticket.statusClassName}`}>
                              {ticket.statusTranslation}
                            </p>
                          </div>
                          <div className="ticket-time">{adjustTimePlusOneHour(ticket.time)}</div>
                        </div>
                      </a>
                    ))
                ) : (
                  <div className="alert alert-info text-center">{t("No tickets found.")}</div>
                )}
              </div>

              {/* Controles de Paginação */}
              {totalPages > 1 && (
                <div className="pagination-controls mt-3 d-flex justify-content-center align-items-center">
                  {/* Botão "Previous" */}
                  <button
                    className="btn btn-sm btn-outline-primary me-2"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    {t('Previous')}
                  </button>
                  
                  {/* Botões de Página com Janela Deslizante */}
                  <div className="page-numbers d-flex">
                    {getPageNumbers().map((page) => (
                      <button
                        key={page}
                        className={`btn btn-sm me-1 ${currentPage === page ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => paginate(page)}
                      >
                        {page}
                      </button>
                    ))}
                  </div>
                  
                  {/* Botão "Next" */}
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    {t('Next')}
                  </button>
                </div>
              )}
            </div>
          </div>
          ) : (
            <>
          {/* Main Ticket Details Section */}
          <div className="tickets-main" style={{ flex: "1", display: "flex", flexDirection: "column"}}>
            <div className="tickets-header d-flex flex-column flex-md-row align-items-center justify-content-between">
              {/* Botão Voltar */}
              <button className="btn btn-secondary mb-2 mb-md-0" onClick={() => setSelectedTicket(null)}>
                &larr; {t("Back to List")}
              </button>

              {/* Espaço vazio para alinhamento */}
              <div style={{ width: "100px" }}></div>

              {/* Botões de Ações */}
              <div className="d-flex flex-column flex-md-row gap-2">

                {(userRole === "admin" || userRole === "receptionist") && (
                  <>
                    {selectedTicket.mechanic && (
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={handleRemoveMechanic}
                      >
                        {t("Remove Mechanic")}
                      </button>
                    )}
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={openDueDateModal}
                    >
                      {t("Set Due Date")}
                    </button>
                  </>
                )}

                {(userRole === "admin" || userRole === "receptionist") && (selectedTicket.status === 1 || selectedTicket.status === 2 || selectedTicket.status === 3) && (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={handleCloseTicket}
                  >
                    {t("Close Ticket")}
                  </button>
                )}

                {(userRole === "admin" || userRole === "receptionist") && selectedTicket.status === 4 && (
                  <button
                    className="btn btn-success btn-sm"
                    onClick={openReopenModal}
                  >
                    {t("Reopen Ticket")}
                  </button>
                )}

                {!selectedTicket.mechanic ? (
                  <button className="btn btn-primary btn-sm" onClick={() => setIsAssignModalOpen(true)}>
                    {t('Assign Mechanic')}
                  </button>
                ) : (
                  <></>
                )}

                <button className="btn btn-primary btn-sm" onClick={() => setIsBudgetPanelOpen(!isBudgetPanelOpen)}>
                  {isBudgetPanelOpen ? t('Close Budget') : t('Open Budget')}
                </button>

                {/* Renderiza os controles do timer */}
                {renderTimerControls()}

              </div>
            </div>

            {/* Exibir os Timers */}
            <div className="ticket-timers">

              {/* Timer do Mecânico */}
              {userRole === "mechanic" && (
                <Timer
                  total_time={
                    userRole === 'mechanic' && timers.mechanicTimers.length > 0
                      ? timers.mechanicTimers[0].total_time
                      : timers.totalTimer || 0
                  }
                  is_running={
                    userRole === 'mechanic' && timers.mechanicTimers.length > 0
                      ? timers.mechanicTimers[0].is_running
                      : false
                  }
                  start_time={
                    userRole === 'mechanic' && timers.mechanicTimers.length > 0
                      ? timers.mechanicTimers[0].start_time
                      : null
                  }
                  userRole={userRole}
                />
              )}

              {userRole === 'admin' || userRole === 'receptionist' ? (
                <div className="total-timer">
                  <div className="timer-display">
                    {formatTotalTime(timers.totalTimer || 0)}
                  </div>
                </div>
              ) : null}
            </div>

            {selectedTicket ? (
              <>
                <div className="tickets-header d-flex align-items-center justify-content-between">
                  <div>
                    <h4 className="mb-1 font-weight-bold">
                      {selectedTicket.car.brand} {selectedTicket.car.model} - {selectedTicket.client.name}
                    </h4>
                    <p className={`mb-0 ticket-status ${mapStatus(selectedTicket.status).className}`}>
                      {mapStatus(selectedTicket.status).translation}
                    </p>
                    <p className="text-muted mb-0">
                      <strong>{t("Estimated Time")}:</strong> {selectedTicket.estimatedTime ? `${selectedTicket.estimatedTime} ${t('hours')}` : t('Not Estimated')}
                    </p>
                    <p className="text-muted mb-0">
                      <strong>{t("Assigned Mechanic")}:</strong>{" "}
                      {selectedTicket.mechanic ? selectedTicket.mechanic : t("Not Assigned")}
                    </p>
                    <p className="text-muted mb-0">
                      <strong>{t("Opening Date")}:</strong> {adjustTimePlusOneHour(selectedTicket.dateOpened) || t("Not Available")}
                    </p>
                    <p className="text-muted mb-0">
                      <strong>{t("Due Date")}:</strong> {selectedTicket.due_date ? moment(selectedTicket.due_date).format('DD-MM-YYYY HH:mm:ss') : t("No due date")}
                    </p>
                  </div>
                </div>

                <div className="tickets-content" style={{ flex: "1", overflowY: "auto" }} ref={chatContainerRef}>
                {/* Exibe os dados do cliente e do veículo no topo do chat */}
                {selectedTicket && (
                  <div className="chat-header-msg">
                    <div className="chat-client-details">
                      <h5>{t("Client Information")}</h5>
                      <p><strong>{t("Name")}:</strong> {selectedTicket.client.name}</p>
                      <p><strong>{t("Phone")}:</strong> {selectedTicket.client.phone}</p>
                      <button className="btn btn-primary" onClick={handlePrint}>
                        <FontAwesomeIcon icon={faPrint} className="me-2" />
                        {t('Print')}
                      </button>
                    </div>
                    <div className="chat-vehicle-details">
                      <h5>{t("Vehicle Information")}</h5>
                      <p><strong>{t("Brand/Model")}:</strong> {selectedTicket.car.brand} {selectedTicket.car.model}</p>
                      <p><strong>{t("Plate")}:</strong> {selectedTicket.car.plate}</p>
                      <p><strong>{t("Mileage")}:</strong> {selectedTicket.car.mileage || t("Not Available")}</p>
                    </div>
                    <hr />
                  </div>
                )}
                
                {/* Renderização das mensagens do chat */}
                {chatMessages.length > 0 ? (
                  chatMessages.map((msg, index) => {
                    const adjustedTime = adjustTimePlusOneHour(msg.created_at);
                    const isSystemMessage = msg.sender === 'system';
                    return isSystemMessage ? (
                      <div key={index} className="system-message">
                        <div className="system-message-header">
                          <span className="system-message-time">
                            {adjustedTime || t("No date available")}
                          </span>
                        </div>
                        <div className="system-message-content">
                          {renderMessage(msg.message)}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className={`chat-message-container ${
                          Number(msg.sender) === userId ? "chat-right" : "chat-left"
                        }`}
                      >
                        <div className="chat-message-header">
                          <strong>{Number(msg.sender) === userId ? t("You") : msg.senderName}</strong>
                          <span className={
                              Number(msg.sender) === userId ? "chat-time-right" : "chat-time"
                            }
                          >
                            {adjustedTime || t("No date available")}
                          </span>
                        </div>
                        <p
                        className="chat-message"
                        dangerouslySetInnerHTML={{
                          __html: renderMessage(msg.message).replace(/\n/g, "<br>"),
                        }}
                        onClick={(e) => {
                          const target = e.target;
                          if (target.tagName === 'IMG' || target.tagName === 'VIDEO') {
                            const url = target.src;
                            const type = target.tagName === 'IMG' ? 'image' : 'video';
                            handleMediaClick(url, type);
                          }
                        }}
                      ></p>
                      </div>
                    );
                  })
                ) : (
                  <div className="alert alert-info">{t("No messages for this ticket.")}</div>
                )}
              </div>

              <div className="chat-section">
                <>
                  <input
                    type="text"
                    id="chat-input"
                    className="form-control"
                    placeholder={t("Type a message...")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSendMessage(e.target.value);
                        e.target.value = "";
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      const input = document.getElementById("chat-input");
                      if (input && input.value.trim()) {
                        handleSendMessage(input.value.trim());
                        input.value = "";
                      } else {
                        toast.error(t("Message cannot be empty."));
                      }
                    }}
                  >
                    {t("Send")}
                  </button>
                  <div className="upload-button-container">
                    <label htmlFor="file-upload" className="upload-button">
                      <i className="bx bx-camera"></i>
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      className="file-input"
                      accept="image/*,video/*"
                      capture="camera"
                      onChange={(e) => handleFileUpload(e.target.files[0])}
                    />
                  </div>
                </>
              </div>
              </>
            ) : (
              <div className="alert alert-info text-center mt-4">
                {t("Select a ticket to view details.")}
              </div>
            )}
          </div>

          {isBudgetPanelOpen && (
            <BudgetPanel
              ticketId={selectedTicket?.id}
              userRole={userRole}
              items={budgetItems}
              onItemsChange={setBudgetItems}
              onClose={closeBudget}
            />
          )}
          </>
        )}

        {/* Modal para Atribuição de Mecânico */}
        <Modal
          isOpen={isAssignModalOpen}
          onRequestClose={() => setIsAssignModalOpen(false)}
          contentLabel="Atribuir Mecânico"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>{t('Assign Mechanic')}</h2>
          <div className="mb-3">
            <Select
              options={mechanics.map(mech => ({ value: mech.id, label: mech.name }))}
              value={assignedMechanic ? { value: assignedMechanic.id, label: assignedMechanic.name } : null}
              onChange={(selectedOption) => {
                const mechanic = mechanics.find(mech => mech.id === selectedOption.value);
                setAssignedMechanic(mechanic);
              }}
              placeholder={mechanics.length > 0 ? t('Select Mechanic') : t('No mechanics available')}
              isDisabled={mechanics.length === 0}
              noOptionsMessage={() => t('No mechanics available')}
            />
          </div>
          <button className="btn btn-primary" onClick={handleAssignMechanic}>{t('Assign')}</button>
          <button className="btn btn-secondary space" onClick={() => setIsAssignModalOpen(false)}>{t('Cancel')}</button>
        </Modal>

        {/* Modal de Confirmação para Reabrir Ticket */}
        <Modal
          isOpen={isReopenModalOpen}
          onRequestClose={closeReopenModal}
          contentLabel="Reopen Ticket Confirmation"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>{t("Reopen Ticket")}</h2>
          <p>{t("Are you sure you want to reopen this ticket?")}</p>
          <div className="d-flex justify-content-end gap-2">
            <button className="btn btn-secondary" onClick={closeReopenModal}>
              {t("No, Cancel")}
            </button>
            <button className="btn btn-success" onClick={handleReopenTicket}>
              {t("Yes, Reopen")}
            </button>
          </div>
        </Modal>

        {/* Modal para Definir Data de Entrega */}
        <Modal
          isOpen={isDueDateModalOpen}
          onRequestClose={closeDueDateModal}
          contentLabel={t("Set Due Date")}
          className="due-date-modal"
          overlayClassName="due-date-overlay"
        >
          <h2>{t("Set Due Date")}</h2>
          <div className="form-group">
            <label htmlFor="dueDate">{t("Select Due Date")}</label>
            <DatePicker
              selected={newDueDate}
              onChange={(date) => setNewDueDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="yyyy-MM-dd HH:mm:ss"
              className="form-control"
              minDate={new Date()}
            />
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-secondary me-2" onClick={closeDueDateModal}>
              {t("Cancel")}
            </button>
            <button className="btn btn-primary" onClick={saveDueDate}>
              {t("Save")}
            </button>
          </div>
        </Modal>

        {isMediaModalOpen && mediaToView && (
          <Modal
            isOpen={isMediaModalOpen}
            onRequestClose={() => setIsMediaModalOpen(false)}
            contentLabel="Visualização de Mídia"
            className="media-modal-content"
            overlayClassName="media-modal-overlay"
            shouldCloseOnOverlayClick={true}
          >
            <button
              className="media-modal-close"
              onClick={() => setIsMediaModalOpen(false)}
              aria-label="Fechar Modal"
            >
              &times;
            </button>
            {console.log('Media to View:', mediaToView)} {/* Adicione este log */}
            {mediaToView.type === 'image' ? (
              <>
              <center>
                <img 
                  src={mediaToView.url}
                  alt="Visualização Completa"
                  onClick={toggleImageWidth}
                  style={{ width: imageWidth, cursor: 'zoom-in', transition: 'width 0.3s ease' }}
                />
              </center>
              </>
            ) : (
              <video src={mediaToView.url} controls />
            )}
          </Modal>
        )}
                
        {/* Modal de Pedidos */}
        {orderModalIsOpen && (
          <div className="modal-overlay">
            <div className="modal-content modal-order modal-dialog">
              <h4 className="modal-title">{t("New Order")}</h4>
              <form>
                <div className="mb-3">
                  <label>{t("Part Name")}</label>
                  <input type="text" className="form-control" placeholder={t("Enter part name")} />
                </div>
                <div className="mb-3">
                  <label>{t("Quantity")}</label>
                  <input type="number" className="form-control" placeholder={t("Enter quantity")} />
                </div>
                <div className="mb-3">
                  <label>{t("Additional Notes")}</label>
                  <textarea className="form-control" placeholder={t("Enter additional notes")} />
                </div>
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      // Adicione aqui a lógica para enviar o pedido
                      setOrderModalIsOpen(false);
                    }}
                  >
                    {t("Submit")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setOrderModalIsOpen(false)}
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        </div>
      </div>
    </div>
  );
}

export default withAuth(Tickets, ['admin', 'mechanic', 'receptionist']);