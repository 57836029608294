import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('token');

      if (!token) {
        navigate('/login', {
          state: { errorMessage: 'Você precisa estar logado para acessar esta página.' },
        });
        return;
      }

      // Lógica para verificar se o token está expirado (se necessário)
      const isTokenExpired = () => {
        // Supondo que o token tenha um campo de expiração no formato JWT
        try {
          const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica o payload do token
          const exp = payload.exp * 1000; // Expiração em milissegundos
          return Date.now() > exp;
        } catch {
          return true; // Considera expirado se o token for inválido
        }
      };

      if (isTokenExpired()) {
        sessionStorage.removeItem('token');
        navigate('/login', {
          state: { errorMessage: 'Sessão expirada. Faça login novamente.' },
        });
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;