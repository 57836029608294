// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';
import translationIT from './locales/it/translation.json';

// Configuração das traduções
const resources = {
  en: { translation: translationEN },
  pt: { translation: translationPT },
  it: { translation: translationIT },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it', // Idioma padrão
    fallbackLng: 'it', // Idioma de fallback caso uma tradução não seja encontrada
    interpolation: { escapeValue: false }, // Escapar valores, por segurança
  });

export default i18n;