import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import API_BASE_URL from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEyeSlash, faEye, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

function EditBudget() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleDescription = (rowIndex) => {
    setExpandedRows((prev) => {
      // se estiver aberto, fecha
      if (prev.includes(rowIndex)) {
        return prev.filter((idx) => idx !== rowIndex);
      } else {
        // se estava fechado, abre
        return [...prev, rowIndex];
      }
    });
  };

  const [totals, setTotals] = useState({
    totalExclIva: '0.00',
    totalIva: '0.00',
    totalInclIva: '0.00',
  });

  const [budgetItems, setBudgetItems] = useState([
    {
      service: '',
      quantity: 1,
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: 0,
    },
  ]);

  const predefinedServices = [
    {
      value: 'Manodopera',
      label: 'Manodopera',
      costPrice: 35,
      profitMargin: 0,
      iva: 22,
      finalPrice: 35,
    },
    {
      value: 'Smaltimento Rifiuti e Materiale di Consumo',
      label: 'Smaltimento Rifiuti e Materiale di Consumo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Olio 10W40',
      label: 'Olio 10W40',
      costPrice: 10,
      profitMargin: 0,
      iva: 22,
      finalPrice: 10,
    },
    {
    value: 'Olio 5W30',
    label: 'Olio 5W30',
    costPrice: 13,
    profitMargin: 0,
    iva: 22,
    finalPrice: 13,
    },
    {
    value: 'Olio 5W40',
    label: 'Olio 5W40',
    costPrice: 12,
    profitMargin: 0,
    iva: 22,
    finalPrice: 12,
    },
    {
    value: 'Olio 0W30',
    label: 'Olio 0W30',
    costPrice: 16.50,
    profitMargin: 0,
    iva: 22,
    finalPrice: 16.50,
    },
    {
    value: 'Olio 0W20',
    label: 'Olio 0W20',
    costPrice: 19,
    profitMargin: 0,
    iva: 22,
    finalPrice: 19,
    },
    {
      value: 'Filtro Olio',
      label: 'Filtro Olio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Aria',
      label: 'Filtro Aria',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Abitacolo',
      label: 'Filtro Abitacolo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Carburante',
      label: 'Filtro Carburante',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'KIT Distribuzione',
      label: 'KIT Distribuzione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pompa Acqua',
      label: 'Pompa Acqua',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
  ];

  const [budgetData, setBudgetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const fetchBudget = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/budgets/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Verificar se a resposta possui items
      if (response.data && response.data.items) {
        const reorderedItems = reorderItems(response.data.items);
        setBudgetData({ ...response.data, items: reorderedItems });
        console.log('Itens reordenados:', reorderedItems); // Log para depuração
      } else {
        setBudgetData(response.data);
      }
  
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar orçamento:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudget();
  }, []);

  useEffect(() => {
    if (budgetData && budgetData.items) {
      const newTotals = calculateTotals(budgetData.items);
      setTotals(newTotals);
    }
  }, [budgetData]);

  const calculateTotals = (items) => {
    let totalExclIva = 0;
    items.forEach((item) => {
      // finalPrice é o valor total do item, sem IVA adicional pois você já incluiu profitMargin
      const fPrice = parseFloat(item.finalPrice || 0);
      totalExclIva += fPrice;
    });
    // Vamos assumir IVA = 22% fixo, ou use item.iva se for por item
    const totalIva = totalExclIva * 0.22;
    const totalInclIva = totalExclIva + totalIva;

    return {
      totalExclIva: totalExclIva.toFixed(2),
      totalIva: totalIva.toFixed(2),
      totalInclIva: totalInclIva.toFixed(2),
    };
  };
    
  const calculateFinalPrice = (costPrice, profitMargin, quantity) => {
    const cp = parseFloat(costPrice) || 0;
    const pm = parseFloat(profitMargin) || 0;
    const qty = parseFloat(quantity) || 1;

    // Ex.: finalPrice = (cp + (cp * pm/100)) * qty
    const profit = cp * (pm / 100);
    const finalPrice = (cp + profit) * qty;
    return finalPrice.toFixed(2);
  };

  const getAvailableServices = () => {
    // Serviços que não podem ser duplicados
    const restrictedServices = ['Manodopera', 'Smaltimento Rifiuti e Materiale di Consumo'];
  
    // Verifica quais serviços restritos já estão no orçamento
    const existingRestrictedServices = budgetData.items
      .filter(item => restrictedServices.includes(item.service))
      .map(item => item.service);
  
    // Filtra os serviços disponíveis removendo os que já existem
    const availableServices = predefinedServices.filter(
      service => !existingRestrictedServices.includes(service.label)
    );
  
    return availableServices;
  };

  const handleItemChange = (index, field, value) => {
    if (value === undefined) return;

    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;

      const updatedItems = [...prevBudget.items];
      const currentItem = { ...updatedItems[index] };

      if (['quantity', 'costPrice', 'profitMargin'].includes(field)) {
        // Se o valor estiver vazio, define como '0'
        if (value === '') {
          currentItem[field] = '0';
        } else {
          currentItem[field] = value;
        }

        currentItem.finalPrice = calculateFinalPrice(
          field === 'costPrice' ? (value === '' ? '0' : value) : currentItem.costPrice,
          field === 'profitMargin' ? (value === '' ? '0' : value) : currentItem.profitMargin,
          field === 'quantity' ? (value === '' ? '0' : value) : currentItem.quantity
        );
      } else if (field === 'service') {
        const predefined = predefinedServices.find((s) => s.value === value);
        if (predefined) {
          currentItem.service = predefined.label;
          currentItem.costPrice = predefined.costPrice ?? '0';
          currentItem.profitMargin = predefined.profitMargin ?? '0';
          currentItem.finalPrice = calculateFinalPrice(
            currentItem.costPrice,
            currentItem.profitMargin,
            currentItem.quantity
          );
        } else {
          currentItem.service = value;
        }
      } else {
        currentItem[field] = value;
      }

      updatedItems[index] = currentItem;
      return { ...prevBudget, items: updatedItems };
    });
  };

  const handleRemoveItem = (index) => {
    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;
      const updatedItems = [...prevBudget.items];
      updatedItems.splice(index, 1);
      return { ...prevBudget, items: updatedItems };
    });
  };

  const handleAddItem = () => {
    // Adicionar nova linha
    const newItem = {
      service: '',
      quantity: 1,
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: 0,
      description: '',
      showDescription: false,
    };
  
    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;
      const updatedItems = [...prevBudget.items, newItem];
  
      // Reordenar os itens para manter 'Smaltimento Rifiuti e Materiale di Consumo' penúltimo e 'Manodopera' último
      const smaltimentoIndex = updatedItems.findIndex(
        (item) => item.service === 'Smaltimento Rifiuti e Materiale di Consumo'
      );
      const manodoperaIndex = updatedItems.findIndex(
        (item) => item.service === 'Manodopera'
      );
  
      if (smaltimentoIndex !== -1 && smaltimentoIndex !== updatedItems.length - 2) {
        const [smaltimentoItem] = updatedItems.splice(smaltimentoIndex, 1);
        updatedItems.splice(updatedItems.length - 1, 0, smaltimentoItem);
      }
  
      if (manodoperaIndex !== -1 && manodoperaIndex !== updatedItems.length - 1) {
        const [manodoperaItem] = updatedItems.splice(manodoperaIndex, 1);
        updatedItems.push(manodoperaItem);
      }
  
      return { ...prevBudget, items: updatedItems };
    });
  };

  // Função para reordenar os itens antes de salvar
  const reorderItems = (items) => {
    const arr = [...items];
  
    let manodoperaIndex = arr.findIndex(
      (i) => i.service === 'Manodopera'
    );
    let smaltimentoIndex = arr.findIndex(
      (i) => i.service === 'Smaltimento Rifiuti e Materiale di Consumo'
    );
  
    if (manodoperaIndex !== -1 && smaltimentoIndex !== -1) {
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      if (smaltimentoIndex > manodoperaIndex) {
        smaltimentoIndex--;
      }
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
  
      arr.push(smaltimentoItem);
      arr.push(manodoperaItem);
  
      return arr;
    }
  
    if (manodoperaIndex !== -1) {
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      arr.push(manodoperaItem);
    }
  
    if (smaltimentoIndex !== -1) {
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
      arr.push(smaltimentoItem);
    }
  
    return arr;
  };

  // Função para formatar a entrada numérica
  const handleNumberInputChange = (index, field, value) => {
    if (value === undefined) return;
  
    // Substitui vírgulas por pontos
    let newValue = value.replace(/,/g, '.');
  
    // Remove todos os caracteres que não sejam dígitos ou pontos
    newValue = newValue.replace(/[^0-9.]/g, '');
  
    // Garante que haja no máximo um ponto decimal
    const parts = newValue.split('.');
    if (parts.length > 2) {
      newValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Limita a dois decimais
    if (parts[1]?.length > 2) {
      newValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
  
    // Atualiza o estado com o valor formatado
    handleItemChange(index, field, newValue);
  };

  const handleSave = async () => {
    if (!budgetData) return;
    setSaving(true);

    // Garantir a ordem correta dos itens
    const reorderedItems = reorderItems(budgetData.items);

    const updatedBudgetData = { ...budgetData, items: reorderedItems };

    try {
      const token = sessionStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/api/budgets/${id}`, updatedBudgetData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSaving(false);
      navigate('/budget/list');
    } catch (error) {
      console.error('Erro ao salvar orçamento:', error);
      setSaving(false);
      alert('Erro ao salvar orçamento.');
    }
  };

  const handleDescriptionChange = (index, value) => {
    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;
      const updatedItems = [...prevBudget.items];
      updatedItems[index] = {
        ...updatedItems[index],
        description: value,
      };
      return { ...prevBudget, items: updatedItems };
    });
  };

  if (loading) {
    return <p>{t('Loading...')}</p>;
  }

  if (!budgetData) {
    return <p>{t('Budget not found')}</p>;
  }

  return (
    <div className="container mt-4">
      <div className="d-flex align-items-center mb-3">
        <button
            className="btn btn-secondary"
            onClick={() => navigate('/budget/list')}
        >
            {t('Back to List')}
        </button>

        <h1 className="flex-grow-1 text-center">
            {t('Edit Budget')} #{budgetData?.budget_id}
        </h1>
      </div>

    <div className="row">
        <div className="col-md-6">
            <h5>{t('Client Details')}</h5>
            <p>
                <strong>{t('Name')}:</strong> {budgetData.client_name || '-'}
            </p>
            <p>
                <strong>{t('Phone')}:</strong> {budgetData.client_phone || '-'}
            </p>
        </div>

        <div className="col-md-3">
            <h5>{t('Car Details')}</h5>
            <p>
                <strong>{t('Brand')}:</strong> {budgetData.car_brand || '-'}
            </p>
            <p>
                <strong>{t('Model')}:</strong> {budgetData.car_model || '-'}
            </p>
        </div>
        <div className="col-md-3">
            <br/><br/>
            <p>
                <strong>{t('Year')}:</strong> {budgetData.car_year || '-'}
            </p>
            <p>
                <strong>{t('Plate')}:</strong> {budgetData.car_plate || '-'}
            </p>
        </div>
    </div>

    <div className="d-flex justify-content-end mt-4">
        <div className="p-3 border rounded" style={{ width: '100%' }}>
          <h4 className="mb-3">{t('Summary')}</h4>
          <p>
            <strong>{t('Total excl. IVA')}:</strong> €{totals.totalExclIva}
          </p>
          <p>
            <strong>IVA 22%:</strong> €{totals.totalIva}
          </p>
          <p>
            <strong>{t('Total incl. IVA')}:</strong> €{totals.totalInclIva}
          </p>
        </div>
      </div>

      {/* Tabela de itens para editar */}
      <h3 className="mt-4 mb-2">{t('budget_items')}</h3>

      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>{t('Service')}</th>
            <th>{t('Quantity')}</th>
            <th>{t('Cost Price')}</th>
            <th>{t('Profit Margin')} (%)</th>
            <th>{t('Final Price')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {budgetData.items.map((item, index) => (
            <React.Fragment key={index}>
                <tr key={index}>
                <td>{index + 1}</td>

                {/* Service */}
                <td>
                    <CreatableSelect
                        options={getAvailableServices()}
                        value={item.service ? { value: item.service, label: item.service } : null}
                        onChange={(option) => handleItemChange(index, 'service', option.value)}
                        placeholder={t('Select or enter service')}
                        />
                </td>

                {/* Quantity */}
                <td>
                    <input
                        type="text"
                        className="form-control"
                        value={item.quantity}
                        onChange={(e) => handleNumberInputChange(index, 'quantity', e.target.value)}
                    />
                </td>

                {/* Cost Price */}
                <td>
                    <input
                        type="text"
                        className="form-control"
                        value={item.costPrice}
                        onChange={(e) => handleNumberInputChange(index, 'costPrice', e.target.value)}
                    />
                </td>

                {/* Profit Margin */}
                <td>
                    <input
                        type="text"
                        className="form-control"
                        value={item.profitMargin}
                        onChange={(e) => handleNumberInputChange(index, 'profitMargin', e.target.value)}
                    />
                </td>

                {/* Final Price */}
                <td>
                    <input
                    type="text"
                    className="form-control"
                    value={item.finalPrice}
                    readOnly
                    />
                </td>

                <td>
                    {/* Contêiner flex para deixar os botões lado a lado */}
                    <div className="d-flex align-items-center">
                        <button
                        className="btn btn-sm btn-danger me-2"
                        onClick={() => handleRemoveItem(index)}
                        >
                        <FontAwesomeIcon icon={faTrash} />
                        </button>

                        <button
                        type="button"
                        className="btn btn-outline-primary space"
                        onClick={() => toggleDescription(index)}
                        >
                        {item.showDescription ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                            <FontAwesomeIcon icon={faEye} />
                        )}
                        </button>
                    </div>
                    </td>
                </tr>
                {expandedRows.includes(index) && (
                    <tr>
                    <td colSpan={6}>
                    <div>
                        <textarea
                            className="form-control mt-2"
                            placeholder={t('Add detailed description')}
                            value={item.description || ''}
                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                            rows="3"
                        />
                    </div>
                    </td>
                    </tr>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Linha de botões (Add à esquerda, Save à direita) */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddItem}
        >
          <FontAwesomeIcon icon={faPlusSquare} /> {t('Add')}
        </button>

        <button
          className="btn btn-success"
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? t('Saving...') : t('Save')}
        </button>
      </div>
    </div>
  );
}

export default EditBudget;