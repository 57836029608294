import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaEye } from 'react-icons/fa';
import withPermission from '../hoc/withPermission';
import './AutoList.css';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AutoList({ isVisible }) {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token ausente. Redirecionando...');
          return;
        }
        const response = await axios.get(`${API_BASE_URL}/api/cars`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCars(response.data);
        setFilteredCars(response.data);
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
        toast.error(t('errorFetchingCars'), { position: 'bottom-right' });
      }
    };

    fetchCars();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filtered = cars.filter((car) =>
        Object.values(car).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredCars(filtered);
    } else {
      setFilteredCars(cars);
    }
  }, [searchTerm, cars]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const sortedCars = [...filteredCars].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastCar = currentPage * resultsPerPage;
  const indexOfFirstCar = indexOfLastCar - resultsPerPage;
  const currentCars = sortedCars.slice(indexOfFirstCar, indexOfLastCar);

  const totalPages = Math.ceil(filteredCars.length / resultsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderSortIcons = (field) => (
    <span className="sort-icons">
      <span
        className={`arrow ${sortField === field && sortOrder === 'asc' ? 'active' : ''}`}
      >
        ↑
      </span>
      <span
        className={`arrow ${sortField === field && sortOrder === 'desc' ? 'active' : ''}`}
      >
        ↓
      </span>
    </span>
  );

  return (
    <div className="page-wrapper">
      <ToastContainer />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('vehicles')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('listVehicles')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              type="text"
              className="form-control me-3"
              placeholder={t('searchVehicles')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div class="ms-auto me-3">
              <div class="btn-group">
                <select
                  className="form-select w-auto ms-3"
                  value={resultsPerPage}
                  onChange={(e) => setResultsPerPage(Number(e.target.value))}
                >
                  <option value={10}>10 {t('results')}</option>
                  <option value={20}>20 {t('results')}</option>
                  <option value={30}>30 {t('results')}</option>
                </select>
              </div>
            </div>
            <span className="me-3">
              {t('showingCars')} {currentCars.length} {t('of')} {filteredCars.length} {t('cars')}
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped custom-table">
              <thead className="table-light">
                <tr>
                  {['owner_name', 'owner_phone', 'car_brand', 'car_model', 'car_plate'].map((field) => (
                    <th
                      key={field}
                      onClick={() => handleSort(field)}
                      className="sortable"
                    >
                      {t(field)}
                      {renderSortIcons(field)}
                    </th>
                  ))}
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentCars.length > 0 ? (
                  currentCars.map((car) => (
                    <tr key={car.id}>
                      <td>{car.owner_name ?? 'N/A'}</td>
                      <td>{car.owner_phone ?? 'N/A'}</td>
                      <td>{car.car_brand ?? 'N/A'}</td>
                      <td>{car.car_model ?? 'N/A'}</td>
                      <td>{car.car_plate ?? 'N/A'}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary me-2"
                          title={t('view')}
                        >
                          <FaEye />
                        </button>
                        <button
                          className="btn btn-sm btn-warning me-2"
                          title={t('edit')}
                        >
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      {t('no_vehicles_found')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === 1}
              onClick={prevPage}
            >
              {t('previous')}
            </button>
            <div>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  className={`btn btn-sm pagination-btn ${currentPage === page ? 'active' : ''}`}
                  onClick={() => paginate(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === totalPages}
              onClick={nextPage}
            >
              {t('next')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPermission(AutoList, ['admin', 'receptionist']);