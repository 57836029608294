import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusSquare, faWindowMinimize, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import API_BASE_URL from '../config';
import './BudgetPanel.css'


function BudgetPanel({ ticketId, userRole, onClose }) {
  const [items, setItems] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleDescription = (rowIndex) => {
    setExpandedRows((prev) => {
      // se estiver aberto, fecha
      if (prev.includes(rowIndex)) {
        return prev.filter((idx) => idx !== rowIndex);
      } else {
        // se estava fechado, abre
        return [...prev, rowIndex];
      }
    });
  };

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  function reorderItems(items) {
    // Fazemos uma cópia para não mutar diretamente a referência
    const arr = [...items];
  
    // Localizar os índices de cada serviço se existirem
    let manodoperaIndex = arr.findIndex(
      (i) => i.service === 'Manodopera'
    );
    let smaltimentoIndex = arr.findIndex(
      (i) => i.service === 'Smaltimento Rifiuti e Materiale di Consumo'
    );
  
    // Se ambos existirem:
    if (manodoperaIndex !== -1 && smaltimentoIndex !== -1) {
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      if (smaltimentoIndex > manodoperaIndex) {
        smaltimentoIndex--;
      }
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
  
      arr.push(smaltimentoItem);
      arr.push(manodoperaItem);
  
      return arr;
    }
  
    if (manodoperaIndex !== -1) {
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      arr.push(manodoperaItem);
    }
  
    if (smaltimentoIndex !== -1) {
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
      arr.push(smaltimentoItem);
    }
  
    return arr;
  }

  // Carrega os itens do back
  const fetchBudgetItems = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${ticketId}/budget`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar itens do orçamento');
      }
      const data = await response.json();
      
      if (Array.isArray(data)) {
        setItems(data); 
      } else {
        setItems(data.items || []);
      }

    } catch (error) {
      console.error(error);
      toast.error('Erro ao buscar itens do orçamento.');
    }
  };
  useEffect(() => {
    if (ticketId) {
      fetchBudgetItems();
    }
  }, [ticketId]);

  const handleItemChange = (index, field, value) => {
    setItems((prev) => {
      const updatedItems = [...prev];
      const item = { ...updatedItems[index] };
  
      if (field === 'service' && predefinedServices.find((s) => s.value === value)) {
        const predefined = predefinedServices.find((s) => s.value === value);
        item.service = predefined.label;
        item.cost_price = predefined.costPrice;
        item.profit_margin = predefined.profitMargin;
        item.final_price = calculateFinalPrice(
          predefined.costPrice,
          predefined.profitMargin,
          item.quantity
        );
      } else {
        item[field] = value;
        if (
          field === 'costPrice' ||
          field === 'profitMargin' ||
          field === 'iva' ||
          field === 'quantity'
        ) {
          item.final_price = calculateFinalPrice(
            item.cost_price || 0,
            item.profit_margin || 0,
            item.quantity || 1
          );
        }
      }
  
      item.final_price = parseFloat(item.final_price) || 0; // Garante que seja numérico
      updatedItems[index] = item;
      return reorderItems(updatedItems);
    });
  };

  const handleRemoveItem = (index) => {
    setItems((prev) => {
      const updated = prev.filter((_, i) => i !== index);
      return reorderItems(updated);
    });
  };

  const handleAddItem = () => {
    setItems((prev) => {
      const newItems = [
        ...prev,
        {
          service: '',
          quantity: 1,
          costPrice: 0,
          profitMargin: 0,
          iva: 22,
          finalPrice: 0,
        },
      ];
      return reorderItems(newItems);
    });
  };

  const predefinedServices = [
    {
      value: 'Manodopera',
      label: 'Manodopera',
      costPrice: 35,
      profitMargin: 0,
      iva: 22,
      finalPrice: 35,
    },
    {
      value: 'Smaltimento Rifiuti e Materiale di Consumo',
      label: 'Smaltimento Rifiuti e Materiale di Consumo',
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Olio 10W40',
      label: 'Olio 10W40',
      costPrice: 10,
      profitMargin: 0,
      iva: 22,
      finalPrice: 10,
    },
    {
    value: 'Olio 5W30',
    label: 'Olio 5W30',
    costPrice: 13,
    profitMargin: 0,
    iva: 22,
    finalPrice: 13,
    },
    {
    value: 'Olio 5W40',
    label: 'Olio 5W40',
    costPrice: 12,
    profitMargin: 0,
    iva: 22,
    finalPrice: 12,
    },
    {
    value: 'Olio 0W30',
    label: 'Olio 0W30',
    costPrice: 16.50,
    profitMargin: 0,
    iva: 22,
    finalPrice: 16.50,
    },
    {
    value: 'Olio 0W20',
    label: 'Olio 0W20',
    costPrice: 19,
    profitMargin: 0,
    iva: 22,
    finalPrice: 19,
    },
    {
      value: 'Filtro Olio',
      label: 'Filtro Olio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Aria',
      label: 'Filtro Aria',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Abitacolo',
      label: 'Filtro Abitacolo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Carburante',
      label: 'Filtro Carburante',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'KIT Distribuzione',
      label: 'KIT Distribuzione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pompa Acqua',
      label: 'Pompa Acqua',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
  ];

  const handleDescriptionChange = (index, value) => {
    setItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, description: value } : item
      )
    );
  };

  const calculateFinalPrice = (costPrice, profitMargin, quantity) => {
    if (profitMargin === 0) {
      // Caso onde o lucro não é conhecido
      return (costPrice * quantity).toFixed(2);
    }
  
    // Caso onde o lucro é conhecido
    const profit = costPrice * (profitMargin / 100);
    const finalPrice = (costPrice + profit) * quantity;
    return finalPrice.toFixed(2);
  };

  const handleTextInputChange = (e, index, field) => {
    let value = e.target.value;
  
    value = value.replace(',', '.');
  
    if (!isNaN(value) || value === '') {
        setItems((prev) => {
        const updatedItems = [...prev];
        const item = { ...updatedItems[index] };
  
        item[field] = value;
  
        if (['costPrice', 'profitMargin', 'quantity'].includes(field)) {
          item.final_price = calculateFinalPrice(
            parseFloat(item.cost_price) || 0,
            parseFloat(item.profit_margin) || 0,
            parseFloat(item.quantity) || 1
          );
        }
  
        updatedItems[index] = item;
        return updatedItems;
      });
    }
  };

  // Salva no backend
  const handleSave = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${ticketId}/budget`, {
        method: 'POST', // ou PUT, dependendo de como estruturou sua rota
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(items),
      });
      if (!response.ok) {
        throw new Error('Erro ao salvar itens de orçamento');
      }
      toast.success('Orçamento salvo com sucesso!');
      // Recarrega ou fecha?
      // fetchBudgetItems(); // Para recarregar
      onClose(); // Para fechar o painel após salvar
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Erro ao salvar orçamento.');
    }
  };

  return (
    <div className={`budget-panel ${isMinimized ? 'minimized' : ''}`}>
      <div className="budget-panel-header">
        <h3>
          {t('Budget of ticket')} #{ticketId}
        </h3>
        <div className="budget-panel-controls">
          {/* Botão minimizar */}
          <button onClick={handleToggleMinimize} title={isMinimized ? t('Maximize') : t('Minimize')}>
            {isMinimized ? '▢' : <FontAwesomeIcon icon={faWindowMinimize} />}
          </button>
          {/* Botão fechar */}
          <button onClick={onClose} title={t('Close')}>×</button>
        </div>
      </div>

      {/* Se NÃO estiver minimizado, exibe o conteúdo */}
      {!isMinimized && (
        <div className="budget-panel-content">
          <table className="table budget-table">
            <thead>
              <tr>
                <th>{t('Product/Service')}</th>
                <th>{t('Quantity')}</th>
                {(userRole === 'admin' || userRole === 'receptionist') && (
                  <>
                    <th>{t('Unit Price excl. IVA')}</th>
                    <th>{t('Profit Margin %')}</th>
                    <th>{t('Final Price')}</th>
                  </>
                )}
                <th>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <React.Fragment key={index}>
                    <tr key={index}>
                    <td style={{ minWidth: '200px' }}>
                        <CreatableSelect
                        options={predefinedServices}
                        value={item.service ? { value: item.service, label: item.service } : null}
                        onChange={(option) => handleItemChange(index, 'service', option.value)}
                        placeholder={t('Select or enter service')}
                        />
                    </td>
                    <td style={{ width: '80px' }}>
                        <input
                        type="text"
                        className="form-control"
                        value={item.quantity}
                        onChange={(e) => handleTextInputChange(e, index, 'quantity')}
                        />
                    </td>
                    {(userRole === 'admin' || userRole === 'receptionist') && (
                        <>
                        <td style={{ width: '100px' }}>
                            <input
                            type="text"
                            className="form-control"
                            value={item.cost_price}
                            onChange={(e) => handleTextInputChange(e, index, 'costPrice')}
                            />
                        </td>
                        <td style={{ width: '100px' }}>
                            <input
                            type="text"
                            className="form-control"
                            value={item.profit_margin}
                            onChange={(e) => handleTextInputChange(e, index, 'profitMargin')}
                            />
                        </td>
                        <td style={{ width: '100px' }}>
                            <input
                            type="text"
                            className="form-control"
                            value={item.final_price}
                            readOnly
                            />
                        </td>
                        </>
                    )}
                    <td className="d-flex">
                        <button className="btn btn-danger" onClick={() => handleRemoveItem(index)}>
                        <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button
                        type="button"
                        className="btn btn-outline-primary space"
                        onClick={() => toggleDescription(index)}
                        >
                        {item.showDescription ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                            <FontAwesomeIcon icon={faEye} />
                        )}
                        </button>
                    </td>
                    </tr>
                    {expandedRows.includes(index) && (
                        <tr>
                        <td colSpan={6}>
                        <div>
                            <textarea
                                className="form-control mt-2"
                                placeholder={t('Add detailed description')}
                                value={item.description || ''}
                                onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                rows="3"
                            />
                        </div>
                        </td>
                        </tr>
                    )}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <div className="budget-actions">
            <button className="btn btn-secondary" onClick={handleAddItem}>
              <FontAwesomeIcon icon={faPlusSquare} /> {t('Add')}
            </button>
            <button className="btn btn-primary space" onClick={handleSave}>
              {t('Submit')}
            </button>
            <button className="btn btn-light space" onClick={onClose}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BudgetPanel;