import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import { FaUser, FaBuilding } from 'react-icons/fa';
import API_BASE_URL from '../config';

function ClientRegister() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isBusiness, setIsBusiness] = useState(false); // Switch para empresa ou pessoa física
    const [businessType, setBusinessType] = useState(''); // Tipo de empresa
    const [formData, setFormData] = useState({
        costumer_type: 'private',
        surname: '',
        name: '',
        ragioneSociale: '',
        cf: '',
        partitaIva: '',
        street: '',
        house_number: '',
        neighborhood: '',
        city: '',
        cap: '',
        email: '',
        pec: '',
        phone: '',
        codSdi: '',
        contactPreference: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleTypeSelection = (type) => {
      setFormData({ ...formData, costumer_type: type });
    };  

    const handleInputChange = (e) => {
      const { name, value } = e.target;
  
      // Limitar o valor do campo 'cap' a apenas números com no máximo 5 dígitos
      if (name === 'cap') {
          const formattedValue = value.replace(/\D/g, '').slice(0, 5); // Remove não-numéricos e limita a 5 caracteres
          setFormData({ ...formData, [name]: formattedValue });
      } else if (name === 'phone') {
          const formattedValue = value
              .replace(/\D/g, '') // Remove caracteres não numéricos
              .replace(/^(\d{3})(\d{3})(\d{0,4}).*/, '($1) $2 $3') // Aplica o formato (XXX) XXX XXXX
              .trim(); // Remove espaços em branco extras
          setFormData({ ...formData, [name]: formattedValue });
      } else if (name === 'cf') {
          setFormData({ ...formData, [name]: value.toUpperCase() }); // Converter CF para maiúsculas
      } else {
          setFormData({ ...formData, [name]: value });
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    console.log('Estado atual do formulário:', formData);

    // Validação de campos obrigatórios
    if (!formData.name || !formData.phone) {
        console.error('Erro: Nome e telefone são obrigatórios.');
        setError(t('name_and_phone_required'));
        return;
    }

    let costumer_type = 'private';
    if (isBusiness) {
        if (businessType === 'personaFisica') {
            costumer_type = 'business_1';
        } else if (businessType === 'soggettoDiverso') {
            costumer_type = 'business_2';
        } else {
            console.error('Erro: Tipo de empresa inválido.');
            setError(t('business_type_required'));
            return;
        }
    }

    const sanitizedPhone = formData.phone.replace(/\D/g, '');
    const updatedFormData = { ...formData, costumer_type, phone: sanitizedPhone };

    console.log('Dados preparados para envio ao backend:', updatedFormData);

    try {
        const response = await fetch(`${API_BASE_URL}/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedFormData),
        });

        console.log('Resposta do backend:', response);

        if (response.ok) {
            setSuccess(t('client_registered_successfully'));
            setTimeout(() => navigate('/ClientList'), 2000);
        } else {
            const data = await response.json();
            console.error('Erro do backend:', data);
            setError(data.message || t('error_registering_client'));
        }
    } catch (err) {
        console.error('Erro na comunicação com o backend:', err);
        setError(t('server_connection_error'));
    }
};

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('clients')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('registerclient')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit} className="row g-3 needs-validation" noValidate>
                    {/* Botões para selecionar o tipo de cliente */}
                    <h4 className="text-center mb-4">{t('che_tipo_di_cliente_sei')}</h4>
                    <div className="d-flex justify-content-around mb-4">
                      <button
                          type="button"
                          className={`btn ${!isBusiness ? 'btn-primary' : 'btn-outline-secondary'}`}
                          onClick={() => setIsBusiness(false)}
                      >
                          <FaUser className="me-2" />
                          {t('privato')}
                      </button>
                      <button
                          type="button"
                          className={`btn ${isBusiness ? 'btn-primary' : 'btn-outline-secondary'}`}
                          onClick={() => setIsBusiness(true)}
                      >
                          <FaBuilding className="me-2" />
                          {t('attivita_commerciale')}
                      </button>
                    </div>

                  {/* Lista de tipos de empresa */}
                  {isBusiness && (
                    <div className="col-12">
                      <label htmlFor="businessType" className="form-label">{t("business_type")}</label>
                      <select
                        className="form-select"
                        id="businessType"
                        name="businessType"
                        value={businessType}
                        onChange={(e) => setBusinessType(e.target.value)}
                      >
                        <option value="">{t("select_business_type")}</option>
                        <option value="personaFisica">{t("persona_fisica")}</option>
                        <option value="soggettoDiverso">{t("soggetto_diverso")}</option>
                      </select>
                    </div>
                  )}

                  {/* Campos para "Persona Fisica Titolare di P. IVA" */}
                  {(businessType === 'personaFisica' || !isBusiness) && (
                    <>
                      <div className="col-md-6">
                        <label htmlFor="surname" className="form-label">{t("surname")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="surname"
                          name="surname"
                          value={formData.surname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">{t("name")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="cf" className="form-label">{t("cf")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cf"
                          name="cf"
                          value={formData.cf}
                          onChange={handleInputChange}
                        />
                      </div>
                      {isBusiness && businessType === 'personaFisica' && (
                        <div className="col-md-6">
                          <label htmlFor="partitaIva" className="form-label">{t("partitaIva")}</label>
                          <input
                            type="text"
                            className="form-control"
                            id="partitaIva"
                            name="partitaIva"
                            value={formData.partitaIva}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {/* Campos para "Soggetto Diverso da Persona Fisica" */}
                  {businessType === 'soggettoDiverso' && (
                    <>
                      <div className="col-md-6">
                        <label htmlFor="ragioneSociale" className="form-label">{t("denominazione")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ragioneSociale"
                          name="ragioneSociale"
                          value={formData.ragioneSociale}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="partitaIva" className="form-label">{t("partitaIva")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="partitaIva"
                          name="partitaIva"
                          value={formData.partitaIva}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pec" className="form-label">{t("pec")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="pec"
                          name="pec"
                          value={formData.pec}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="codSdi" className="form-label">{t("cod_sdi")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="codSdi"
                          name="codSdi"
                          value={formData.codSdi}
                          onChange={handleInputChange}
                        />
                      </div>
                    </>
                  )}

                  {/* Campos comuns */}
                  <div className="col-md-6">
                      <label htmlFor="email" className="form-label">{t("email")}</label>
                      <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          title={t("invalid_email_format")}
                      />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="phone" className="form-label">{t("phone")}</label>
                    <input
                      type="phone"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required={!formData.phone}
                    />
                  </div>
                  {/* Campos de endereço */}
                  <div className="col-md-4">
                    <label htmlFor="street" className="form-label">{t("street")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="street"
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="house_number" className="form-label">{t("house_number")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="house_number"
                      name="house_number"
                      value={formData.house_number}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="neighborhood" className="form-label">{t("neighborhood")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="neighborhood"
                      name="neighborhood"
                      value={formData.neighborhood}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="city" className="form-label">{t("city")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="cap" className="form-label">{t("cap")}</label>
                      <input
                          type="text"
                          className="form-control"
                          id="cap"
                          name="cap"
                          value={formData.cap}
                          onChange={handleInputChange}
                          pattern="\d{5}" // Apenas números com exatamente 5 dígitos
                          title={t("invalid_cap_format")}
                      />
                  </div>

                  {/* Preferência de contato */}
                  <div className="col-12">
                    <label htmlFor="contactPreference" className="form-label">{t("contact_preference")}</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="contactEmail"
                        name="contactPreference" // Mantém o mesmo "name" para agrupar os radios
                        value="email"
                        checked={formData.contactPreference === 'email'}
                        onChange={(e) => setFormData({ ...formData, contactPreference: e.target.value })}
                      />
                      <label className="form-check-label" htmlFor="contactEmail">
                        {t("email")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="contactWhatsapp"
                        name="contactPreference" // Mantém o mesmo "name" para agrupar os radios
                        value="whatsapp"
                        checked={formData.contactPreference === 'whatsapp'}
                        onChange={(e) => setFormData({ ...formData, contactPreference: e.target.value })}
                      />
                      <label className="form-check-label" htmlFor="contactWhatsapp">
                        {t("whatsapp")}
                      </label>
                    </div>
                  </div>

                  {error && <div className="alert alert-danger">{error}</div>}
                  {success && <div className="alert alert-success">{success}</div>}
                  <div className="col-12">
                    <p>* {t("required")}</p>
                    <p><Trans 
                        i18nKey="register_client_note"
                        components={{
                        b: <b className="highlight-red" />,
                        a: <a href="/terms" />
                        }}
                        values={{ buttonText: t("register") }}
                    /></p>
                    <button type="submit" className="btn btn-primary">{t("register")}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(ClientRegister, ['admin', 'receptionist']);