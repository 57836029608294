import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import './Dashboard.css';
import moment from 'moment-timezone';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [acceptanceStats, setAcceptanceStats] = useState(null);
  const [acceptanceComparison, setAcceptanceComparison] = useState(null);
  const [isFriday, setIsFriday] = useState(false);

  const [priorityTickets, setPriorityTickets] = useState([]);

  // Controla se o modal está aberto
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Guarda o ID do ticket em que o usuário clicou para marcar como entregue
  const [ticketIdToDeliver, setTicketIdToDeliver] = useState(null);

  const openConfirm = () => setConfirmOpen(true);
  const closeConfirm = () => setConfirmOpen(false);

  const mapStatus = (status) => {
    switch (status) {
      case 1:
        return { translation: t('Not Assigned'), className: 'not-assigned' };
      case 2:
        return { translation: t('Pending'), className: 'pending' };
      case 3:
        return { translation: t('Working'), className: 'working' };
      case 4:
        return { translation: t('Finished'), className: 'finished' };
      default:
        return { translation: t('Unknown'), className: 'unknown' };
    }
  };

  useEffect(() => {
    const today = new Date();
    setIsFriday(today.getDay() === 5);

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });
        if (response.data) {
          setUserName(response.data.name);
          setUserRole(response.data.role);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        navigate('/login');
      }
    };

    const fetchAcceptanceStats = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/acceptance-stats`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });
        setAcceptanceStats(response.data);
      } catch (error) {
        console.error('Erro ao buscar estatísticas de aceitação:', error);
      }
    };

    const fetchAcceptanceComparison = async () => {
      if (isFriday) {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/acceptance-weekly-comparison`, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
          });
          setAcceptanceComparison(response.data);
        } catch (error) {
          console.error('Erro ao buscar comparação semanal:', error);
        }
      }
    };

    const fetchPriorityTickets = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/api/tickets/priorities`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPriorityTickets(response.data);
      } catch (error) {
        console.error('Erro ao buscar tickets com prioridade:', error);
      }
    };

    fetchUserData();
    fetchAcceptanceStats();
    fetchAcceptanceComparison();
    fetchPriorityTickets();
  }, [navigate, isFriday]);

  const renderStatsWidget = () => {
    if (!acceptanceStats) return null;

    return (
      <div className="col-12 col-md-6 col-lg-4">
        <div className="card radius-10">
          <div className="card-body text-center">
            <h6 className="mb-3">{t('statistics')}</h6>
            <p>
              {t('today')}: {acceptanceStats.today}
            </p>
            <p>
              {t('this_week')}: {acceptanceStats.week}
            </p>
            <p>
              {t('this_month')}: {acceptanceStats.month}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderComparisonWidget = () => {
    if (!acceptanceComparison || !isFriday) return null;

    const { currentWeek, lastWeek } = acceptanceComparison;
    const difference = currentWeek - lastWeek;
    const isPositive = difference >= 0;

    return (
      <div className="col-12 col-md-6 col-lg-4">
        <div className="card radius-10">
          <div className="card-body text-center">
            <h6 className="mb-3">{t('weekly_comparison')}</h6>
            <p>
              {isPositive ? (
                <span style={{ color: 'green' }}>
                  ▲ {t('this_week')} {difference} {t('more_than_last_week')}
                </span>
              ) : (
                <span style={{ color: 'red' }}>
                  ▼ {t('this_week')} {Math.abs(difference)} {t('less_than_last_week')}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const markAsDelivered = async (ticketId) => {
    try {
      const token = sessionStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/api/tickets/${ticketId}/mark-delivered`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Ticket marked as delivered.');
      setPriorityTickets((prev) => prev.filter((ticket) => ticket.id !== ticketId));
    } catch (error) {
      console.error('Erro ao marcar ticket como entregue:', error);
      toast.error('Error marking ticket as delivered.');
    }
    setConfirmOpen(false);
  };

  const renderPriorityTicketsCard = () => {
    if (!priorityTickets || priorityTickets.length === 0) return null;

    return (
      <div className="col-12 mt-4">
        <div className="card radius-10">
          <div className="card-body">
            <h6 className="mb-3">{t('Tickets com prioridade')}</h6>
            <div className="row">
              {priorityTickets.map((ticket) => {
                let dueDateFormatted = t('No due date');
                if (ticket.due_date) {
                  dueDateFormatted = moment(ticket.due_date)
                    .tz('Europe/Rome')
                    .format('DD-MM-YYYY HH:mm:ss');
                }

                const { translation: statusText, className: statusClassName } = mapStatus(ticket.status);

                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5" key={ticket.id}>
                    <div className={`p-2 ticket-card ${ticket.color}`}>
                      {/* ID do ticket */}
                      <p className="mb-1">
                        <strong>#{ticket.id}</strong>
                      </p>

                      {/* Cliente */}
                      <p className="mb-1">
                        <strong>{t('Client')}:</strong> {ticket.client_name}
                      </p>

                      {/* Carro */}
                      <p className="mb-1">
                        <strong>{t('Car')}:</strong> {ticket.car_brand} {ticket.car_model}
                      </p>

                      {/* Status */}
                      <div className="mb-1">
                        <strong>{t('Status')}:</strong>{' '}
                        <p className={`ticket-status ${statusClassName}`} style={{ display: 'inline-block', margin: 0 }}>
                          {statusText}
                        </p>
                      </div>

                      {/* Data de Entrega Formatada */}
                      <p className="mb-1">
                        <strong>{t('Due date')}:</strong><br/> {dueDateFormatted}
                      </p>

                      {/* Botão para abrir o ticket */}
                      <button
                        className="btn btn-sm btn-secondary mt-2"
                        onClick={() => navigate(`/tickets/${ticket.id}`)}
                      >
                        {t('Abrir')}
                      </button>

                      {/* Condicional para exibir o botão apenas para certos papéis */}
                      {(userRole === 'admin' || userRole === 'receptionist') && (
                        <>
                          <button
                            className="btn btn-sm btn-light mt-2"
                            onClick={() => {
                              setTicketIdToDeliver(ticket.id);
                              openConfirm();
                            }}
                          >
                            {t('Delivered')}
                          </button>

                          <Modal
                            isOpen={confirmOpen}
                            onRequestClose={closeConfirm}
                            contentLabel={t('Confirm the Delivery')}
                            className="confirm-modal"
                            overlayClassName="confirm-overlay"
                          >
                            <h2>{t('Confirm the Delivery')}</h2>
                            <p>{t('Are you delivery to the client?')}</p>

                            <button className="btn btn-secondary" onClick={closeConfirm}>
                              {t('cancel')}
                            </button>

                            {/* Use ticketIdToDeliver ao confirmar */}
                            <button
                              className="btn btn-primary space"
                              onClick={() => markAsDelivered(ticketIdToDeliver)}
                            >
                              {t('confirm')}
                            </button>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('dashboard')}</div>
        </div>

        <div className="row">
          {renderStatsWidget()}
          {isFriday && renderComparisonWidget()}
        </div>

        {/* Card dos Tickets com Prioridade */}
        <div className="row">
          {renderPriorityTicketsCard()}
        </div>
      </div>
    </div>
  );
}

export default withAuth(Dashboard);