import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEdit, FaBan, FaPaperPlane, FaTrash, FaCheck, FaClock } from 'react-icons/fa';
import API_BASE_URL from '../config';
import './ListBudget.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/images/big_logo.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ListBudget() {
  const { t } = useTranslation();
  const [budgets, setBudgets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [pdfUrl, setPdfUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBanModal, setShowBanModal] = useState(false);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');

  const fetchBudgets = async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error(t('access_denied'));
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/api/budgets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBudgets(response.data);
    } catch (error) {
      console.error('Erro ao buscar orçamentos:', error);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  // Função que gera o PDF com jsPDF e exibe na mesma página
  const generatePDF = async (budget) => {
    try {
      const doc = new jsPDF('p', 'mm', 'a4');
      const currentDate = new Date().toLocaleDateString();
      let operatorName = 'N/A';
      let budgetData = null;

      // 1) Buscar os detalhes do orçamento no backend
      const token = sessionStorage.getItem('token');
      const responseBudget = await fetch(`${API_BASE_URL}/api/budgets/${budget.budget_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!responseBudget.ok) {
        throw new Error('Erro ao buscar orçamento');
      }
      budgetData = await responseBudget.json();

      // 2) Buscar dados do operador
      const responseOperator = await fetch(`${API_BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (responseOperator.ok) {
        const userData = await responseOperator.json();
        operatorName = userData.name || 'N/A';
      }

      // =========== Montagem do PDF =========== //
      doc.addImage(logo, 'PNG', 10, 15, 50, 30);
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.text('3B AUTO DI ELIDIR BUSNELLO E C. SAS', 65, 20);

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      const companyInfo = [
        'via Sandro Pertini, 20',
        '46045, MARMIROLO (MN)',
        'P. Iva/ Cod. Fiscale: 02709760207',
        'SDI: WTYVJK9',
        'Tel: 389 026 2392',
        'Email: 3BAuto3B@gmail.com',
      ];
      let companyY = 25;
      companyInfo.forEach((line) => {
        doc.text(65, companyY, line);
        companyY += 5;
      });

      // Título
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.text(`Preventivo N° ${budgetData.budget_id || 'N/A'}`, 105, 60, { align: 'center' });

      // Dados cliente e veículo
      const headerY = 70;
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Dati Cliente e Veicolo', 12, headerY + 6);

      doc.setFont('helvetica', 'normal');
      doc.text(`Nome Cliente: ${budgetData.client_name || '-'}`, 12, headerY + 12);
      doc.text(`Telefono Cliente: ${budgetData.client_phone || '-'}`, 12, headerY + 18);
      doc.text(`Operatore: ${operatorName}`, 140, headerY + 12);
      doc.text(`Data: ${currentDate}`, 140, headerY + 18);
      doc.text(`Veicolo: ${budgetData.car_brand || '-'} ${budgetData.car_model || '-'}`, 12, headerY + 24);
      doc.text(`Targa: ${budgetData.car_plate || '-'}`, 140, headerY + 24);

      // Linha de separação
      doc.setDrawColor(0, 0, 0);
      doc.line(10, headerY + 30, 200, headerY + 30);

      // Tabela de itens (usando autoTable)
      const tableStartY = headerY + 35;
      const tableData = budgetData.items.flatMap((item, index) => {
        // Cálculo corrigido do preço por unidade
        const unitPrice = item.costPrice
        ? parseFloat(item.costPrice) + parseFloat(item.costPrice) * (parseFloat(item.profitMargin) / 100)
        : 0;

        const mainRow = [
          index + 1,
          item.service || '-',
          item.quantity || '-',
          `€${unitPrice.toFixed(2)}`, // Preço por unidade com margem de lucro
          `€${parseFloat(item.finalPrice || 0).toFixed(2)}`,
        ];

        // Se tiver descrição, gera linha extra
        const descriptionRow = item.description
          ? [{ colSpan: 5, content: `- ${item.description.replace(/\n/g, '\n- ')}` }]
          : null;

        return descriptionRow ? [mainRow, descriptionRow] : [mainRow];
      });

      doc.autoTable({
        startY: tableStartY,
        head: [['#', `${t('Service or Product')}`, `${t('Quantity')}`, `${t('Unit Price excl. IVA')}`, `${t('Final Price')}`]],
        body: tableData,
        styles: { font: 'helvetica', fontSize: 10 },
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        margin: { left: 10, right: 10 },
      });

      // Totais
      const calculateTotals = (budgetItems) => {
        if (!Array.isArray(budgetItems) || budgetItems.length === 0) {
          return { totalExclIva: '0.00', totalIva: '0.00', totalInclIva: '0.00' };
        }
        const totalExclIva = budgetItems.reduce((sum, item) => {
          const finalPrice = parseFloat(item?.finalPrice) || 0;
          return sum + finalPrice;
        }, 0);
        const totalIva = totalExclIva * 0.22;
        const totalInclIva = totalExclIva + totalIva;
        return {
          totalExclIva: totalExclIva.toFixed(2),
          totalIva: totalIva.toFixed(2),
          totalInclIva: totalInclIva.toFixed(2),
        };
      };

      const summaryStartY = doc.previousAutoTable.finalY + 10;
      const totals = calculateTotals(budgetData.items);

      doc.setFont('helvetica', 'bold');
      doc.text('Riepilogo', 12, summaryStartY);
      doc.setFont('helvetica', 'normal');
      doc.text(`${t('Total excl. IVA')}: €${totals.totalExclIva}`, 12, summaryStartY + 6);
      doc.text(`IVA 22%: €${totals.totalIva}`, 12, summaryStartY + 12);
      doc.text(`${t('Total incl. IVA')}: €${totals.totalInclIva}`, 12, summaryStartY + 18);

      // Rodapé
      const footerY = 280;
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(10);
      doc.text(
        'Il presente preventivo non costituisce documento valido ai fini fiscali. ' +
        'RingraziandoVi per l\'attenzione, rimaniamo in attesa di un gentile riscontro.',
        10,
        footerY,
        { maxWidth: 190 }
      );
      doc.text('Firma per l\'accettazione: ____________________', 10, footerY + 10);

      // Converte para blobURL
      const pdfBlobUrl = doc.output('bloburl');
      setPdfUrl(pdfBlobUrl);
      setShowPdf(true);

    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
      toast.error(t('Error generating PDF'));
    }
  };

  const handleDeleteReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/delete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as DELETE!'));
        setShowDeleteModal(false);
        setSelectedBudgetId(null);
        fetchBudgets();
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleWaitingReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/waiting`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as WAITING!'));
        setShowWaitingModal(false);
        setSelectedBudgetId(null);
        fetchBudgets();
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleAcceptReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/accept`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as ACCEPT!'));
        setShowAcceptModal(false);
        setSelectedBudgetId(null);
        fetchBudgets();
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleBanReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/ban`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as REJECT!'));
        setShowBanModal(false);
        setSelectedBudgetId(null);
        fetchBudgets();
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleConfirmReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/ready`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as READY!'));
        setShowConfirmModal(false);
        setSelectedBudgetId(null);
        fetchBudgets();
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const indexOfLastBudget = currentPage * resultsPerPage;
  const indexOfFirstBudget = indexOfLastBudget - resultsPerPage;
  const currentBudgets = budgets
    .filter((budget) =>
      searchTerm
        ? budget.client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          budget.budget_id.toString().includes(searchTerm)
        : true
    )
    .slice(indexOfFirstBudget, indexOfLastBudget);

  const totalPages = Math.ceil(budgets.length / resultsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  // Mapeamento dos status
  const statusLabels = {
    0: t('In Progress'),
    1: t('Ready'),
    2: t('Rejected'),
    3: t('Waiting client'),
  };

  const renderSortIcons = (field) => (
    <span className="sort-icons">
      <span
        className={`arrow ${sortField === field && sortOrder === 'asc' ? 'active' : ''}`}
      >
        ↑
      </span>
      <span
        className={`arrow ${sortField === field && sortOrder === 'desc' ? 'active' : ''}`}
      >
        ↓
      </span>
    </span>
  );

  // Se "showPdf" for true, exibimos apenas o PDF e um botão para fechar
  if (showPdf && pdfUrl) {
    return (
      <div style={{ padding: '20px' }}>
        <button
          className="btn btn-secondary mb-3"
          onClick={() => {
            setShowPdf(false);
            setPdfUrl('');
          }}
        >
          {t('Close')}
        </button>

        <iframe
          src={pdfUrl}
          title="Budget PDF"
          width="100%"
          height="800px"
          frameBorder="0"
        />
      </div>
    );
  }

  return (
    <div className="page-wrapper">
      <ToastContainer />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('budget')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('listBudgets')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-4">
          <h1>{t('listBudgets')}</h1>

          {/* Barra de busca e configurações */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              type="text"
              className="form-control me-3"
              placeholder={t('searchBudgets')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="form-select w-auto"
              value={resultsPerPage}
              onChange={(e) => setResultsPerPage(Number(e.target.value))}
            >
              <option value={10}>10 {t('results')}</option>
              <option value={20}>20 {t('results')}</option>
              <option value={30}>30 {t('results')}</option>
            </select>
          </div>

          {/* Tabela de orçamentos */}
          <table className="table table-bordered table-striped">
            <thead className="table-light">
                <tr>
                  {[t('Budget ID'), t('Client'), t('Car'), t('created_at'), t('Status')].map((field) => (
                    <th
                      key={field}
                      onClick={() => handleSort(field)}
                      className="sortable"
                    >
                      {t(field)}
                      {renderSortIcons(field)}
                    </th>
                  ))}
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
            <tbody>
              {currentBudgets.length > 0 ? (
                currentBudgets.map((budget) => (
                  <tr key={budget.budget_id}>
                    <td>{budget.budget_id}</td>
                    <td>{budget.client_name}</td>
                    <td>{budget.car_brand} {budget.car_model}</td>
                    <td>{new Date(budget.created_at).toLocaleDateString()}</td>
                    <td>
                      {budget.status === 0 && (
                        <div className="badge rounded-pill text-info bg-light-info p-2 text-uppercase px-3">
                          <i className="bx bxs-circle align-middle me-1"></i>
                          {statusLabels[budget.status]}
                        </div>
                      )}
                      {budget.status === 1 && (
                        <div className="badge rounded-pill text-success bg-light-success p-2 text-uppercase px-3">
                          <i className="bx bxs-circle align-middle me-1"></i>
                          {statusLabels[budget.status]}
                        </div>
                      )}
                      {budget.status === 2 && (
                        <div className="badge rounded-pill text-danger bg-light-danger p-2 text-uppercase px-3">
                          <i className="bx bxs-circle me-1"></i>
                          {statusLabels[budget.status]}
                        </div>
                      )}
                      {budget.status === 3 && (
                        <div className="badge rounded-pill text-warning bg-light-warning p-2 text-uppercase px-3">
                          <i className="bx bxs-circle me-1"></i>
                          {statusLabels[budget.status]}
                        </div>
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary me-2"
                        onClick={() => generatePDF(budget)}
                      >
                        <FaEye />
                      </button>
                      <button
                        className="btn btn-sm btn-warning me-2"
                        onClick={() => navigate(`/edit-budget/${budget.budget_id}`)}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="btn btn-sm btn-success me-2"
                        onClick={() => {
                          setSelectedBudgetId(budget.budget_id);
                          setShowConfirmModal(true);
                        }}
                      >
                        <FaCheck />
                      </button>
                      <button
                        className="btn btn-sm btn-danger me-2"
                        onClick={() => {
                          setSelectedBudgetId(budget.budget_id);
                          setShowBanModal(true);
                        }}
                      >
                        <FaBan />
                      </button>
                      <button
                        className="btn btn-sm btn-info me-2"
                        onClick={() => {
                          setSelectedBudgetId(budget.budget_id);
                          setShowAcceptModal(true);
                        }}
                      >
                        <FaPaperPlane />
                      </button>
                      <button
                        className="btn btn-sm btn-warning me-2"
                        onClick={() => {
                          setSelectedBudgetId(budget.budget_id);
                          setShowWaitingModal(true);
                        }}
                      >
                        <FaClock />
                      </button>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          setSelectedBudgetId(budget.budget_id);
                          setShowDeleteModal(true);
                        }}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    {t('Nenhum orçamento encontrado.')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Paginação */}
          <div className="pagination d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === 1}
              onClick={prevPage}
            >
              {t('previous')}
            </button>
            <div>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  className={`btn btn-sm pagination-btn ${currentPage === page ? 'active' : ''}`}
                  onClick={() => paginate(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === totalPages}
              onClick={nextPage}
            >
              {t('next')}
            </button>
          </div>

          {showConfirmModal && (
            <Modal
              isOpen={showConfirmModal}
              onRequestClose={() => setShowConfirmModal(false)}
              contentLabel="Confirma mudar o estado para PRONTO?"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0,0,0,0.5)',
                },
                content: {
                  maxWidth: '400px',
                  maxHeight: '200px',
                  margin: 'auto',
                  borderRadius: '8px',
                },
              }}
            >
              <h2>{t('Change Budget Status')}</h2>
              <p>{t('Are you sure you want to mark this budget as READY?')}</p>

              <div className="d-flex justify-content-end gap-2 mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowConfirmModal(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleConfirmReady}
                >
                  {t('Confirm')}
                </button>
              </div>
            </Modal>
          )}

          {showBanModal && (
            <Modal
              isOpen={showBanModal}
              onRequestClose={() => setShowBanModal(false)}
              contentLabel="Confirma mudar o estado para REJEITADO?"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0,0,0,0.5)',
                },
                content: {
                  maxWidth: '400px',
                  maxHeight: '200px',
                  margin: 'auto',
                  borderRadius: '8px',
                },
              }}
            >
              <h2>{t('Change Budget Status')}</h2>
              <p>{t('Are you sure you want to mark this budget as REJECTED?')}</p>

              <div className="d-flex justify-content-end gap-2 mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowBanModal(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleBanReady}
                >
                  {t('Confirm')}
                </button>
              </div>
            </Modal>
          )}

          {showAcceptModal && (
            <Modal
              isOpen={showAcceptModal}
              onRequestClose={() => setShowAcceptModal(false)}
              contentLabel="Confirma mudar o estado para ACEITADO?"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0,0,0,0.5)',
                },
                content: {
                  maxWidth: '400px',
                  maxHeight: '200px',
                  margin: 'auto',
                  borderRadius: '8px',
                },
              }}
            >
              <h2>{t('Change Budget Status')}</h2>
              <p>{t('Are you sure you want to mark this budget as ACCEPT?')}</p>

              <div className="d-flex justify-content-end gap-2 mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowAcceptModal(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleAcceptReady}
                >
                  {t('Confirm')}
                </button>
              </div>
            </Modal>
          )}

          {showWaitingModal && (
            <Modal
              isOpen={showWaitingModal}
              onRequestClose={() => setShowWaitingModal(false)}
              contentLabel="Confirma mudar o estado para ACEITADO?"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0,0,0,0.5)',
                },
                content: {
                  maxWidth: '400px',
                  maxHeight: '200px',
                  margin: 'auto',
                  borderRadius: '8px',
                },
              }}
            >
              <h2>{t('Change Budget Status')}</h2>
              <p>{t('Are you sure you want to mark this budget as WAITING?')}</p>

              <div className="d-flex justify-content-end gap-2 mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowWaitingModal(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleWaitingReady}
                >
                  {t('Confirm')}
                </button>
              </div>
            </Modal>
          )}

          {showDeleteModal && (
            <Modal
              isOpen={showDeleteModal}
              onRequestClose={() => setShowDeleteModal(false)}
              contentLabel="Confirma mudar o estado para ACEITADO?"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0,0,0,0.5)',
                },
                content: {
                  maxWidth: '400px',
                  maxHeight: '200px',
                  margin: 'auto',
                  borderRadius: '8px',
                },
              }}
            >
              <h2>{t('Change Budget Status')}</h2>
              <p>{t('Are you sure you want to delete this budget?')}</p>

              <div className="d-flex justify-content-end gap-2 mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  {t('Cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleDeleteReady}
                >
                  {t('Confirm')}
                </button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}

export default ListBudget;