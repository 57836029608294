import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import Select from 'react-select';
import './CustomerCare.css';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import CreatableSelect from 'react-select/creatable';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPrint } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CustomerCare() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [existingClients, setExistingClients] = useState([]);
  const [damagePhotos, setDamagePhotos] = useState([]);
  const [ticketNumber, setTicketNumber] = useState(null);
  const [clientCars, setClientCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);

  // Lista de marcas de carros
  const initialCarBrands = [
    {
      value: 'Toyota',
      label: 'Toyota',
      models: ['Corolla', 'Camry', 'RAV4', 'Highlander', 'Prius', 'Tacoma', 'Yaris', 'Avalon', '4Runner', 'Sequoia', 'Tundra', 'Sienna', 'Land Cruiser', 'C-HR', 'Supra', 'Venza', 'Matrix', 'FJ Cruiser', 'Hilux', 'Fortuner', 'Innova', 'Etios'],
    },
    {
      value: 'Ford',
      label: 'Ford',
      models: ['Fiesta', 'Focus', 'Mustang', 'Explorer', 'Ranger', 'Edge', 'Escape', 'Expedition', 'Bronco', 'Bronco Sport', 'F-150', 'F-250', 'F-350', 'Transit', 'Transit Connect', 'EcoSport', 'Taurus', 'Fusion', 'Maverick', 'Galaxy', 'Mondeo', 'C-Max', 'S-Max', 'Kuga', 'Puma', 'Flex', 'Thunderbird', 'Cortina', 'Falcon', 'Courier', 'Probe', 'Ka', 'Everest', 'Territory'],
    },
    {
      value: 'Chevrolet',
      label: 'Chevrolet',
      models: ['Cruze', 'Malibu', 'Silverado', 'Tahoe', 'Equinox', 'Camaro', 'Impala', 'Trailblazer', 'Suburban', 'Traverse', 'Colorado', 'Blazer', 'Corvette', 'Spark', 'Bolt EV', 'Sonic', 'Avalanche', 'Captiva', 'Orlando', 'HHR', 'Monte Carlo', 'Uplander', 'Astro', 'LUV', 'Cobalt', 'Venture', 'Express'],
    },
    {
      value: 'Honda',
      label: 'Honda',
      models: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Fit', 'HR-V', 'Odyssey', 'Passport', 'Ridgeline', 'Insight', 'Element', 'Prelude', 'S2000', 'Clarity', 'Del Sol', 'CR-Z', 'Brio', 'Amaze', 'City', 'Jazz', 'Shuttle', 'Stream', 'Freed', 'Legend', 'Elysion', 'Acty', 'Beat'],
    },
    {
      value: 'Nissan',
      label: 'Nissan',
      models: ['Altima', 'Sentra', 'Rogue', 'Murano', 'Frontier', 'Leaf', 
              'Maxima', 'Pathfinder', 'Armada', 'Versa', 'Titan', 'Kicks', 
              'GT-R', 'Juke', 'Qashqai', '370Z', 'Navara', 'X-Trail', 
              'Patrol', 'Terrano', 'Magnite', 'Sunny', 'Bluebird', 
              'Stagea', 'Cube', 'March', 'Note', 'Micra'],
    },
    {
      value: 'Volkswagen',
      label: 'Volkswagen',
      models: ['Golf', 'Passat', 'Jetta', 'Tiguan', 'Atlas', 
              'Beetle', 'Polo', 'Arteon', 'Touareg', 'ID.4', 
              'ID.3', 'ID.5', 'ID.Buzz', 'Taos', 'Amarok', 
              'Caddy', 'California', 'Transporter', 'Multivan', 
              'Caravelle', 'Up!', 'Scirocco', 'Corrado', 'Fox', 
              'Sharan', 'Touran', 'Eos', 'Routan', 'CrossFox', 
              'Saveiro', 'Voyage', 'Gol', 'Virtus', 'T-Cross', 
              'Nivus', 'Teramont'],
    },
    {
      value: 'BMW',
      label: 'BMW',
      models: ['1 Series', '2 Series', '3 Series', '4 Series', '5 Series', '6 Series', '7 Series', 
              '8 Series', 'X1', 'X2', 'X3', 'X4', 'X5', 'X6', 'X7', 'Z3', 'Z4', 'i3', 'i4', 
              'i7', 'i8', 'iX', 'iX3', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M8', 'X5 M', 
              'X6 M', 'X3 M', 'X4 M', 'Z8', 'ActiveHybrid 3', 'ActiveHybrid 5', 'ActiveHybrid 7', 
              '2 Series Active Tourer', '2 Series Gran Tourer', 'Gran Coupe', 'Gran Turismo'],
    },
    {
      value: 'Mercedes-Benz',
      label: 'Mercedes-Benz',
      models: ['C-Class', 'E-Class', 'GLC', 'GLE', 'A-Class', 'S-Class', 'GLA', 'GLS', 
              'CLS', 'AMG GT', 'G-Class', 'SL', 'SLK', 'CLA', 'B-Class', 'V-Class', 
              'Sprinter', 'Maybach S-Class', 'EQC', 'EQS', 'EQA', 'EQB', 'EQV'],
    },
    {
      value: 'Audi',
      label: 'Audi',
      models: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 
              'Q2', 'Q3', 'Q4', 'Q5', 'Q7', 'Q8', 
              'TT', 'TTS', 'TT RS', 
              'R8', 'RS3', 'RS4', 'RS5', 'RS6', 'RS7', 
              'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 
              'e-tron', 'e-tron GT', 'Q4 e-tron', 'Q8 e-tron', 
              'Allroad', 'Cabriolet', 'Coupe', 'Sportback', 'Avant'],
    },
    {
      value: 'Hyundai',
      label: 'Hyundai',
      models: ['Elantra', 'Sonata', 'Santa Fe', 'Tucson', 'Kona', 
              'Palisade', 'Veloster', 'Ioniq', 'Genesis', 
              'Accent', 'Creta', 'Venue', 'Verna', 'Getz', 
              'Atos', 'Terracan', 'Starex', 'Galloper', 'Matrix', 
              'Azera', 'Entourage', 'Grandeur', 'Equus', 'Trajet', 
              'Scoupe', 'H1', 'H100', 'Nexo'],
    },
    {
      value: 'Kia',
      label: 'Kia',
      models: ['Rio', 'Sportage', 'Sorento', 'Optima', 'Stinger', 
              'Seltos', 'Carnival', 'Soul', 'Telluride', 'Forte', 
              'Picanto', 'Ceed', 'Stonic', 'Niro', 'Carens', 
              'EV6', 'K900', 'Mohave', 'Rondo', 'Cadenza', 
              'Borrego', 'Sedona', 'Cerato', 'ProCeed', 'K5', 
              'K8', 'K3', 'XCeed', 'Spectra', 'Shuma'],
    },
    {
      value: 'Fiat',
      label: 'Fiat',
      models: ['500', 'Panda', 'Tipo', 'Punto', 'Doblo', 'Uno', 
              'Palio', 'Siena', 'Linea', 'Bravo', 'Brava', 
              'Marea', 'Strada', 'Toro', 'Fiorino', 'Ducato', 
              'Freemont', 'Multipla', 'Stilo', 'Idea', 'Albea', 
              'Tempra', 'Argenta', 'Croma', 'Ritmo', 'Regata', 
              'Sedici', 'Qubo', 'Ulysse', 'Seicento', 'Cinquecento', 
              'X1/9', '124 Spider', '850', '600', '1100', 
              'Campagnola', 'Barchetta', 'Topolino'],
    },
    {
      value: 'Peugeot',
      label: 'Peugeot',
      models: ['208', '308', '3008', '5008', '2008', '206', 
              '207', '405', '406', '407', '508', '607', 
              '806', '807', '1007', '3008 Hybrid', '4008', 
              'Partner', 'Boxer', 'Expert', 'Traveller', 'Rifter'],
    },
    {
      value: 'Renault',
      label: 'Renault',
      models: ['Clio', 'Megane', 'Captur', 'Kadjar', 'Talisman', 'Scenic', 
              'Kangoo', 'Trafic', 'Master', 'Zoe', 'Twizy', 'Fluence', 
              'Espace', 'Laguna', 'Modus', 'Latitude', 'Koleos', 
              'Arkana', 'Duster', 'Sandero', 'Logan', 'Symbol', 
              'Wind', 'Vel Satis', 'Avantime', 'Alaskan', '4', 
              '5', '9', '11', '21', '25', 'Fuego', 'Safrane', 'Express'],
    },
    {
      value: 'Opel',
      label: 'Opel',
      models: ["Astra", "Corsa", "Insignia", "Mokka", "Crossland", "Grandland", 
              "Zafira", "Vivaro", "Combo", "Movano", "Adam", "Karl", 
              "Meriva", "Antara", "Vectra", "Signum", "Omega", "Tigra", 
              "Agila", "Frontera", "Ampera", "Monza", "Kadett", "Ascona", 
              "Calibra", "Rekord", "Senator", "Diplomat", "GT", "Manta"],
    },
    {
      value: 'Skoda',
      label: 'Skoda',
      models: ["Fabia", "Octavia", "Superb", "Karoq", "Kodiaq", "Scala", 
              "Kamiq", "Enyaq iV", "Rapid", "Citigo", "Roomster", 
              "Yeti", "Felicia", "Favorit", "Forman", "120", "130", 
              "110", "100", "Citigo-e iV"],
    },
    {
      value: 'Mazda',
      label: 'Mazda',
      models: ["Mazda2", "Mazda3", "Mazda6", "CX-3", "CX-30", "CX-5", "CX-50",
              "CX-60", "CX-90", "MX-5 Miata", "MX-30", "RX-7", "RX-8", 
              "BT-50", "Mazda5", "Tribute", "B-Series", "Premacy", "Protege", 
              "626", "323", "Demio", "Familia", "Millenia", "MPV", "Cosmo"],
    },
    {
      value: 'Jaguar',
      label: 'Jaguar',
      models: ["E-PACE", "F-PACE", "I-PACE", "F-TYPE", "XE", "XF", "XJ", 
              "XK", "XKR", "XK8", "XJ6", "XJ8", "S-Type", "X-Type", 
              "XJ220", "XJR", "XJS", "MK2", "F-PACE SVR", "F-TYPE R"],
    },
    {
      value: 'Land Rover',
      label: 'Land Rover',
      models: ["Defender", "Discovery", "Discovery Sport", "Range Rover", "Range Rover Sport",
              "Range Rover Evoque", "Range Rover Velar", "Freelander", "LR2", "LR3", "LR4"],
    },
    {
      value: 'Porsche',
      label: 'Porsche',
      models: ["911", "Cayenne", "Macan", "Panamera", "Taycan", "718 Boxster", 
              "718 Cayman", "Boxster", "Cayman", "Carrera", "Turbo", 
              "GT3", "GT4", "Targa", "Spyder", "959", "924", 
              "928", "944", "968", "356", "Carrera GT"],
    },
    {
      value: 'Jeep',
      label: 'Jeep',
      models: ["Wrangler", "Grand Cherokee", "Cherokee", "Compass", "Renegade", 
              "Gladiator", "Patriot", "Commander", "Liberty", "Wagoneer", 
              "Grand Wagoneer", "CJ", "Scrambler", "J-Series", "Comanche", 
              "Trailhawk", "Trackhawk", "Overland", "Rubicon", "Laredo", 
              "Sahara", "Willys", "Freedom", "Mojave"],
    },
    {
      value: 'Alfa Romeo',
      label: 'Alfa Romeo',
      models: ["Giulia", "Stelvio", "Tonale", "Giulietta", "4C", "8C", "147", "156", 
              "159", "166", "Spider", "Brera", "GTV", "MiTo", "Alfa 33", "Alfa 75", 
              "Alfa 90", "Alfetta", "SZ", "RZ", "Montreal", "Alfa 145", "Alfa 146", 
              "Alfa 164", "Alfa 6", "Alfasud", "Alfasud Sprint", "GT", "Junior", 
              "Giulia Sprint", "Duetto", "Quadrifoglio"],
    },
    {
      value: 'Seat',
      label: 'Seat',
      models: ["Ibiza", "Leon", "Arona", "Ateca", "Tarraco", "Mii", 
              "Toledo", "Alhambra", "Cordoba", "Exeo", "Altea", 
              "Inca", "Marbella", "Ronda", "Malaga", "Fura", 
              "Panda", "600", "133"],
    },
    {
      value: 'Suzuki',
      label: 'Suzuki',
      models: ["Alto", "Swift", "Baleno", "Celerio", "Ignis", "Wagon R", "Ertiga", 
              "XL6", "Vitara", "Jimny", "S-Cross", "Kizashi", "Grand Vitara", 
              "SX4", "Dzire", "Splash", "Ritz", "Esteem", "Aerio", "Samurai", 
              "Sidekick", "X-90", "Equator", "Cappuccino", "Carry"],
    },
    {
      value: 'Volvo',
      label: 'Volvo',
      models: ["XC40", "XC60", "XC90", "S60", "S90", "V60", "V90", "C40 Recharge", 
              "V60 Cross Country", "V90 Cross Country", "XC40 Recharge", 
              "C30", "C70", "V40", "V50", "V70", "S40", "S80", "740", "760", 
              "780", "850", "940", "960", "Amazon", "PV444", "PV544", "P1800"],
    },
    {
      value: 'Lexus',
      label: 'Lexus',
      models: ["ES", "GS", "IS", "LS", "NX", "RX", "GX", "LX", 
              "UX", "RC", "LC", "CT", "HS", "RX L", "RXh", 
              "NXh", "LC Convertible", "IS F", "LFA", "SC"],
    },
    {
      value: 'Citroen',
      label: 'Citroen',
      models: ["C1", "C2", "C3", "C3 Aircross", "C4", "C4 Cactus", "C4 Picasso", "C5", 
              "C5 Aircross", "C5 X", "C6", "C8", "Berlingo", "Jumpy", "Spacetourer", 
              "Dispatch", "Relay", "DS3", "DS4", "DS5", "Saxo", "Xantia", "XM", "ZX", 
              "AX", "BX", "CX", "Evasion", "Dyane", "Mehari", "Ami", "Traction Avant", 
              "2CV", "Visa", "C15", "HY", "GS", "GSA", "C-Crosser", "C-Zero", "Metropolis", 
              "Pluriel", "Nemo", "C-Elysee"],
    },
    {
      value: 'Mitsubishi',
      label: 'Mitsubishi',
      models: ["Lancer", "Outlander", "Pajero", "Eclipse Cross", "ASX", "Mirage", "Triton", 
              "Montero", "Delica", "Galant", "Eclipse", "RVR", "i-MiEV", "Challenger", 
              "Endeavor", "Sigma", "FTO", "Starion", "GTO", "3000GT", "Shogun", 
              "Diamante", "Space Star", "Colt", "Carisma", "Raider", "Minicab", 
              "Canter", "Express", "Attrage", "Xforce"],
    },
    {
      value: 'Mini',
      label: 'Mini',
      models: ["Cooper", "Cooper S", "Cooper SE", "Cooper SD", "John Cooper Works", 
              "Countryman", "Countryman Plug-In Hybrid", "Clubman", "Paceman", 
              "Convertible", "Roadster", "Coupe", "Hatchback", "Cabrio", 
              "One", "One D", "One First", "Electric", "Mini E"],
    },
    {
      value: 'Smart',
      label: 'Smart',
      models: ["Fortwo", "Fortwo Cabrio", "Forfour", "Crossblade", "Roadster", 
               "Roadster Coupe", "EQ Fortwo", "EQ Forfour", "Smart #1"],
    },
    {
      value: 'Chrysler',
      label: 'Chrysler',
      models: ["300", "300C", "Pacifica", "Pacifica Hybrid", "Voyager", "Aspen", "PT Cruiser", 
              "Sebring", "Crossfire", "Town & Country", "200", "Concorde", "LHS", "LeBaron", 
              "New Yorker", "Imperial", "Fifth Avenue", "Cordoba", "Valiant", "Saratoga", 
              "Windsor", "Newport", "Laser"],
    },
    {
      value: 'Dodge',
      label: 'Dodge',
      models: ["Charger", "Challenger", "Durango", "Journey", "Grand Caravan", "Nitro", 
              "Caliber", "Avenger", "Viper", "Magnum", "Dakota", "Ram 1500", "Ram 2500", 
              "Ram 3500", "Stealth", "Intrepid", "Stratus", "Neon", "Spirit", 
              "Daytona", "Lancer", "Omni", "Dart", "Polara", "Coronet", "Monaco", 
              "Aspen", "Diplomat", "Caravan", "B-Series", "A100", "Custom 880", 
              "Power Wagon", "Raider", "Shadow", "Colt", "Raider", "Aries", "Mirada", 
              "St. Regis", "Aspen SE", "Demon", "Super Bee"],
    },
    {
      value: 'Iveco',
      label: 'Iveco',
      models: ["Daily", "Eurocargo", "Stralis", "Trakker", "S-Way", "PowerStar", 
              "EuroTrakker", "Massif", "Magirus", "TurboDaily", "Zeta", "LMV", 
              "EuroTech", "EuroStar", "Cursor", "EuroClass", "TurboStar", "TurboTech", 
              "Vertis", "Tector", "Citelis", "Urbanway", "Crealis", "Crossway", 
              "Evadys", "Eurobus", "Turboliner", "Irisbus", "Bus", "Camper"],
    },
    {
      value: 'Lancia',
      label: 'Lancia',
      models: ["Ypsilon", "Delta", "Fulvia", "Stratos", "Thema", "Kappa", "Beta", "Montecarlo", 
              "Gamma", "Dedra", "Lybra", "Thesis", "Prisma", "Flavia", "Aurelia", "Appia", 
              "Ardea", "Augusta", "Dilambda", "Astura", "Aprilia", "Phedra", "Zeta", "Voyager", 
              "Trevi", "Scorpion"],
    },
    {
      value: 'Dacia',
      label: 'Dacia',
      models: ["Logan", "Sandero", "Duster", "Spring", "Lodgy", "Dokker", "Solenza", 
              "1300", "1100", "Nova", "Pick-Up", "1304", "Double Cab", "Denem", 
              "SuperNova", "Stepway", "Bigster", "Manifesto"],
    },
    {
      value: 'dR',
      label: 'dR',
      models: ["DR1", "DR2", "DR3", "DR3 EV", "DR4", "DR5", "DR5.0", "DR6", 
              "DR6.0", "DR7", "DR F35", "DR EVO", "DR Zero"],
    },
    {
      value: 'Lamborghini',
      label: 'Lamborghini',
      models: ["Aventador", "Huracán", "Urus", "Gallardo", "Murciélago", 
              "Diablo", "Countach", "Sian", "Reventón", "Veneno", 
              "Centenario", "Miura", "Espada", "Jarama", "Islero", 
              "LM002", "Estoque", "350 GT", "400 GT", "Silhouette", 
              "Jalpa", "Bravo", "Marzal", "Egoista", "Asterion", 
              "Terzo Millennio"],
    },
    {
      value: 'Ferrari',
      label: 'Ferrari',
      models: ["488 GTB", "488 Spider", "812 Superfast", "812 GTS", 
              "California", "California T", "Portofino", "Portofino M", 
              "Roma", "SF90 Stradale", "SF90 Spider", "F8 Tributo", 
              "F8 Spider", "296 GTB", "296 GTS", "LaFerrari", 
              "LaFerrari Aperta", "F12berlinetta", "F12tdf", "GTC4Lusso", 
              "GTC4Lusso T", "Enzo", "Dino 246", "250 GTO", 
              "458 Italia", "458 Spider", "458 Speciale", "599 GTB Fiorano", 
              "599 GTO", "612 Scaglietti", "F50", "F40", "Testarossa", 
              "Mondial", "308", "328", "348", "355", "360 Modena", 
              "360 Spider", "430 Scuderia", "Challenge Stradale", "275 GTB", 
              "512 TR", "512 BB", "Superamerica", "FXX", "SP1 Monza", 
              "SP2 Monza", "296 GTB", "Purosangue"],
    },
    {
      value: 'Subaro',
      label: 'Subaro',
      models: ["Impreza", "Legacy", "Outback", "Forester", 
              "Crosstrek", "Ascent", "WRX", "WRX STI", 
              "BRZ", "XV", "Levorg", "Baja", 
              "Tribeca", "SVX", "Justy", "Alcyone", 
              "Rex", "Sambar", "Trezia", "Stella", 
              "Lucra", "Dias Wagon", "Exiga", "Gloria"],
    },
  ];

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [carBrands, setCarBrands] = useState(initialCarBrands);
  const [carModels, setCarModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  const fetchNextTicketNumber = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/next-ticket-number`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao obter o próximo número do ticket.');
      }
  
      const data = await response.json();
      setTicketNumber(data.nextTicketNumber);
    } catch (err) {
      console.error('Erro ao buscar o próximo número do ticket:', err);
    }
  };

  useEffect(() => {
    fetchNextTicketNumber(); // Busca o próximo número do ticket ao carregar o componente
  }, []);

  const success_noti = (message) => {
    toast.success(message || t('Customer and car successfully registered.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };
  
  const error_noti = (message) => {
    toast.error(message || t('An error occurred while registering.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  // Atualizar modelos ao selecionar uma marca
  useEffect(() => {
    if (selectedCar) {
      // Se um veículo foi selecionado do banco de dados
      const selectedCarDetails = clientCars.find((car) => car.id === selectedCar);
  
      if (selectedCarDetails) {
        const brand = carBrands.find((b) => b.value === selectedCarDetails.car_brand);
        if (brand) {
          setCarModels(
            brand.models.map((model) => ({ value: model, label: model }))
          );
        }
        // Define o modelo selecionado automaticamente
        setSelectedModel({
          value: selectedCarDetails.car_model,
          label: selectedCarDetails.car_model,
        });
      }
    } else if (selectedBrand) {
      // Caso contrário, atualiza os modelos para a marca selecionada
      const brand = carBrands.find((b) => b.value === selectedBrand.value);
      setCarModels(
        brand ? brand.models.map((model) => ({ value: model, label: model })) : []
      );
      setSelectedModel(null); 
    } else {
      setCarModels([]);
      setSelectedModel(null);
    }
  }, [selectedBrand, selectedCar, clientCars, carBrands]);

  const handleBrandChange = (newValue) => {
    setSelectedBrand(newValue);
    setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
  
    if (newValue && !carBrands.some((brand) => brand.value === newValue.value)) {
      setCarBrands([...carBrands, { value: newValue.value, label: newValue.label, models: [] }]);
    }
  };
  
  const handleModelChange = (newValue) => {
    setSelectedModel(newValue);
    setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
  
    if (newValue && selectedBrand) {
      const updatedBrands = carBrands.map((brand) => {
        if (brand.value === selectedBrand.value) {
          return {
            ...brand,
            models: brand.models.includes(newValue.value)
              ? brand.models
              : [...brand.models, newValue.value],
          };
        }
        return brand;
      });
      setCarBrands(updatedBrands);
    }
  };

  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    carBrand: '',
    carModel: '',
    carYear: '',
    carPlate: '',
    carMileage: '',
    carProblem: '',
  });

  const steps = [
    { id: 1, title: t('Personal Information'), number: '1', description: t('Client details') },
    { id: 2, title: t('Car Details'), number: '2', description: t('Car information') },
    { id: 3, title: t('Problem Description'), number: '3', description: t('Describe the problem') },
    { id: 4, title: t('Summary'), number: '4', description: t('Review and submit') },
  ];

  const reportRef = useRef();

  const handlePrint = () => {
    const doc = new jsPDF('p', 'mm', 'a4'); // Formato A4
    const currentDate = new Date().toLocaleDateString();
  
    // Título
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    doc.text('Ficha de Serviço - Oficina', 105, 20, { align: 'center' });
  
    // Dados do Cliente
    doc.setFontSize(12);
    doc.setFillColor(230, 230, 230); // Fundo cinza claro
    doc.rect(10, 30, 190, 25, 'F'); // Retângulo para os dados do cliente
    doc.text('Dados do Cliente:', 12, 35);
  
    doc.setFont('helvetica', 'normal');
    doc.text(`Nome: ${formData.clientName || '-'}`, 12, 42);
    doc.text(`Telefone: ${formData.clientPhone || '-'}`, 12, 48);
    doc.text(`Data: ${currentDate}`, 12, 54);
  
    // Dados do Carro
    doc.setFont('helvetica', 'bold');
    doc.text('Dados do Carro:', 12, 65);
    doc.setFont('helvetica', 'normal');
    doc.text(`Marca: ${formData.carBrand || '-'}`, 12, 72);
    doc.text(`Modelo: ${formData.carModel || '-'}`, 12, 78);
    doc.text(`Ano: ${formData.carYear || '-'}`, 12, 84);
    doc.text(`Placa: ${formData.carPlate || '-'}`, 12, 90);
    doc.text(`Quilometragem: ${formData.carMileage || '-'}`, 12, 96);
  
    // Tabela para Descrição do Serviço/Produto
    const tableColumn = ['Descrição do Serviço/Produto', 'Quantidade', 'Total'];
    const tableRows = Array(10).fill(['', '', '']); // 10 linhas vazias
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 110, // Posição inicial da tabela
      styles: {
        lineWidth: 0.3, // Largura da borda
        lineColor: [0, 0, 0], // Cor da borda (preta)
        halign: 'center',
        valign: 'middle',
      },
      columnStyles: {
        0: { halign: 'left', cellWidth: 100 }, // Descrição alinhada à esquerda
        1: { cellWidth: 40 },
        2: { cellWidth: 50 },
      },
      tableLineColor: [0, 0, 0], // Cor das linhas da tabela
      tableLineWidth: 0.3, // Largura das linhas da tabela
    });
  
    // Abrir em nova aba para pré-visualização
    window.open(doc.output('bloburl'), '_blank');
  };

  const fetchClientDetails = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/clients/details/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar detalhes do cliente.');
      }

      const clientDetails = await response.json();

      setFormData({
        clientName: clientDetails.name || '',
        clientEmail: clientDetails.email || '',
        clientPhone: clientDetails.phone || '',
        carBrand: '',
        carModel: '',
        carYear: '',
        carPlate: '',
        carMileage: '',
        carProblem: ''
      });
    } catch (err) {
      console.error('Erro:', err);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setExistingClients(data);
      } catch (err) {
        console.error('Erro ao buscar clientes existentes:', err);
      }
    };

    fetchClients();
  }, []);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    if (!formData.clientName || !formData.clientPhone || !formData.carBrand || !formData.carModel || !formData.carYear || !formData.carPlate || !formData.carMileage) {
      error_noti(t('All fields are required.'));
      return;
    }
  
    const payload = {
      clientName: formData.clientName,
      clientPhone: formData.clientPhone,
      carBrand: selectedCar ? null : formData.carBrand,
      carModel: selectedCar ? null : formData.carModel,
      carYear: selectedCar ? null : formData.carYear,
      carPlate: formData.carPlate,
      carMileage: formData.carMileage,
      carProblem: formData.carProblem,
    };
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/customer-care`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        setTicketNumber(data.ticketNumber);
        success_noti();
        setTimeout(() => navigate(`/Tickets/${ticketNumber}/`), 2000);
      } else {
        const data = await response.json();
        error_noti(data.error || t('An error occurred while registering.'));
      }
    } catch (err) {
      error_noti(t('Server connection error.'));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await handlePrint(e);
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const photoPreviews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setDamagePhotos((prevPhotos) => [...prevPhotos, ...photoPreviews]);
  };

  const handleRemovePhoto = (index) => {
    setDamagePhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  const fetchClientCars = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/clients/${clientId}/cars`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao buscar carros do cliente.');
      }
  
      const cars = await response.json();
      setClientCars(cars);
    } catch (err) {
      console.error('Erro ao buscar carros do cliente:', err);
      setClientCars([]); // Reseta a lista de carros se houver erro
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h4 className="form-section-title">{t('che_tipo_di_cliente_sei')}</h4>
            <div className="col-12 mb-3">
              <label htmlFor="clientSelection" className="form-label">{t('select_client')}</label>
              {existingClients.length > 0 ? (
                <Select
                  options={[
                    { value: 'new', label: t('new_client') },
                    ...existingClients.map((client) => ({
                      value: client.id,
                      label: `${client.name} - ${client.phone}`,
                    })),
                  ]}
                  value={selectedClient ? { value: selectedClient, label: `${formData.clientName} - ${formData.clientPhone}` } : null}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedClient(null);
                      setFormData({
                        clientName: '',
                        clientEmail: '',
                        clientPhone: '',
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                        carMileage: '',
                        carProblem: '',
                      });
                      setClientCars([]);
                    } else {
                      setSelectedClient(selectedOption.value);
                      fetchClientDetails(selectedOption.value);
                      fetchClientCars(selectedOption.value);
                    }
                  }}
                  placeholder={t('select_or_search_client')}
                  isSearchable
                />
              ) : (
                <p className="text-muted">{t('no_clients_found')}</p> // Mensagem quando não há clientes
              )}
            </div>
            <div className="row g-4">
            <div className="col-md-6">
              <label htmlFor="clientName" className="form-label">{t('name')}</label>
              <input
                type="text"
                className="form-control"
                id="clientName"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                required
                disabled={!!selectedClient} // Desabilita se um cliente for selecionado
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="clientPhone" className="form-label">{t('phone')}</label>
              <input
                type="text"
                className="form-control"
                id="clientPhone"
                name="clientPhone"
                value={formData.clientPhone}
                onChange={handleChange}
                required
                disabled={!!selectedClient} // Desabilita se um cliente for selecionado
              />
            </div>
            </div>
          </div>
        );
        case 2:
          return (
            <div>
              <h4 className="form-section-title">{t('Car Details')}</h4>

              {/* Seleção de veículos */}
              <div className="col-12 mb-3">
                <label htmlFor="carSelection" className="form-label">{t('Select a Car')}</label>
                <Select
                  id="carSelection"
                  options={[
                    { value: 'new', label: t('New Vehicle') },
                    ...clientCars.map((car) => ({
                      value: car.id,
                      label: `${car.car_brand} ${car.car_model} (${car.car_year}) - ${car.car_plate} ${car.last_mileage ? `(${t('Mileage')}: ${car.last_mileage} km)` : ''}`,
                    })),
                  ]}
                  value={
                    selectedCar
                      ? clientCars.find((car) => car.id === selectedCar) 
                        ? { value: selectedCar, label: `${clientCars.find((car) => car.id === selectedCar).car_brand} ${clientCars.find((car) => car.id === selectedCar).car_model} (${clientCars.find((car) => car.id === selectedCar).car_year}) - ${clientCars.find((car) => car.id === selectedCar).car_plate}` }
                        : { value: 'new', label: t('New Vehicle') }
                      : { value: 'new', label: t('New Vehicle') }
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedCar(null); // Limpa a seleção de veículo
                      setFormData({
                        ...formData,
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                      });
                    } else {
                      setSelectedCar(selectedOption.value); // Define o veículo selecionado
                      const selectedCarDetails = clientCars.find((car) => car.id === selectedOption.value);
                      if (selectedCarDetails) {
                        setFormData({
                          ...formData,
                          carBrand: selectedCarDetails.car_brand,
                          carModel: selectedCarDetails.car_model,
                          carYear: selectedCarDetails.car_year,
                          carPlate: selectedCarDetails.car_plate,
                        });
                      }
                    }
                  }}
                  placeholder={t('Select or register a vehicle')}
                  isSearchable
                />
              </div>

              {/* Formulário para cadastrar/editar veículo */}
              <div className="row g-4">
                <div className="col-md-6">
                  <label className="form-label">{t('Brand')}</label>
                  <CreatableSelect
                    options={carBrands}
                    value={
                      formData.carBrand
                        ? carBrands.find((brand) => brand.value === formData.carBrand) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleBrandChange(newValue);
                      setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car brand')}
                    isClearable
                    isDisabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Model')}</label>
                  <CreatableSelect
                    options={carModels}
                    value={
                      formData.carModel
                        ? carModels.find((model) => model.value === formData.carModel) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleModelChange(newValue);
                      setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car model')}
                    isClearable
                    isDisabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Year')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carYear"
                    value={formData.carYear}
                    onChange={handleChange}
                    placeholder={t('Enter car year')}
                    required
                    disabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Plate')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="carPlate"
                    value={formData.carPlate}
                    onChange={handleChange}
                    placeholder={t('Enter car plate')}
                    required
                    disabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Kilometer')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carMileage"
                    value={formData.carMileage || ''}
                    onChange={handleChange}
                    placeholder={t('Enter car mileage')}
                    required
                  />
                </div>
              </div>
            </div>
          );
      case 3:
        return (
          <div>
            <h4 className="form-section-title">{t('Problem Description')}</h4>
            <div className="form-group">
              <label className="form-label">{t('Description')}</label>
              <textarea
                className="form-control"
                name="carProblem"
                value={formData.carProblem}
                onChange={handleChange}
                rows="5"
                placeholder={t('Describe the issue with the car')}
                required
              ></textarea>
            </div>
          </div>
        );
        case 4:
          return (
            <div>
              <div>
                <h3 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#2c3e50' }}>
                  {t('Service Report')}
                </h3>

                <div id="report-preview" ref={reportRef} className="p-3">

                  {/* Detalhes do Cliente */}
                  <div className="border rounded p-3 mb-4" style={{ backgroundColor: '#e9ecef' }}>
                    <h5 className="mb-3" style={{ fontWeight: 'bold', color: '#34495e' }}>
                      {t('Client Information')}
                    </h5>
                    <p><strong>{t('Name')}:</strong> {formData.clientName || '-'}</p>
                    <p><strong>{t('phone')}:</strong> {formData.clientPhone || '-'}</p>
                    <p><strong>{t('Date')}:</strong> {new Date().toLocaleDateString()}</p>
                    <p><strong>{t('Ticket Number')}:</strong> #{ticketNumber}</p>
                  </div>

                  {/* Detalhes do Veículo */}
                  <div className="border rounded p-3 mb-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <h5 className="mb-3" style={{ fontWeight: 'bold', color: '#34495e' }}>
                      {t('Car Details')}
                    </h5>
                    <p><strong>{t('Brand')}:</strong> {formData.carBrand || '-'}</p>
                    <p><strong>{t('Model')}:</strong> {formData.carModel || '-'}</p>
                    <p><strong>{t('Year')}:</strong> {formData.carYear || '-'}</p>
                    <p><strong>{t('Plate')}:</strong> {formData.carPlate || '-'}</p>
                    <p><strong>{t('mileage')}:</strong> {formData.carMileage || '-'}</p>
                  </div>

                  {/* Descrição do Problema */}
                  <div className="border rounded p-3 mb-4" style={{ backgroundColor: '#ffffff' }}>
                    <h5 className="mb-3" style={{ fontWeight: 'bold', color: '#34495e' }}>
                      {t('Problem Description')}
                    </h5>
                    <p>{formData.carProblem || t('No description provided')}</p>
                  </div>

                  {/* Upload de Fotos de Danos */}
                  <div className="border rounded p-3 mb-4">
                    <h5 className="mb-3" style={{ fontWeight: 'bold', color: '#34495e' }}>
                      {t('Damage Photos')}
                    </h5>
                    <div className="mb-3">
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleFileUpload}
                        className="form-control"
                      />
                    </div>
                    <div className="d-flex flex-wrap">
                      {damagePhotos.map((photo, index) => (
                        <div key={index} className="m-2 position-relative" style={{ width: '150px' }}>
                          <img
                            src={photo.preview}
                            alt={`Damage ${index + 1}`}
                            className="img-thumbnail"
                            style={{ width: '100%', height: 'auto' }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm position-absolute"
                            style={{ top: '5px', right: '5px' }}
                            onClick={() => handleRemovePhoto(index)}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Botões de Ação */}
                <div className="mt-4 d-flex justify-content-between">
                  <button className="btn btn-secondary" onClick={(e) => handlePrint(e)}>
                    <FontAwesomeIcon icon={faPrint} className="me-2" />
                    {t('Print')}
                  </button>
                  <button className="btn btn-primary" onClick={(e) => handleSave(e)}>
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    {t('Save')}
                  </button>
                </div>
              </div>
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('customerCare')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('customerCare')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* Progress Bar */}
            <div className="progress-bar-container mb-4">
              {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                  <div
                    className={`progress-step ${currentStep === step.id ? 'active' : ''} ${
                      currentStep > step.id ? 'completed' : ''
                    }`}
                    onClick={() => handleStepClick(step.id)}
                  >
                    <div className="step-icon">{step.number}</div> {/* Número da etapa */}
                    <div className="step-title">{step.title}</div>
                    <div className="step-description">{step.description}</div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`bs-stepper-line ${
                        currentStep > step.id ? 'completed' : ''
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <form>
              {renderStep()}
              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  disabled={currentStep === 1}
                >
                  {t('Previous')}
                </button>
                {currentStep === 4 ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {t('Submit')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setCurrentStep((prev) => prev + 1)}
                  >
                    {t('Next')}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default CustomerCare;