import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserRegister() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 'admin',
  });
  const [loading, setLoading] = useState(false);

  const success_noti = (message) => {
    toast.success(message || t('user_registered_successfully'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  const error_noti = (message) => {
    toast.error(message || t('error_registering_user'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(
        `${API_BASE_URL}/userRegister`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      success_noti(); // Exibe o toast de sucesso
      setFormData({ name: '', email: '', role: 'admin' });
    } catch (err) {
      error_noti(err.response?.data?.error || t('error_registering_user')); // Exibe o toast de erro
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('profile')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('newUser')}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <form
                  onSubmit={handleSubmit}
                  className="row g-3 needs-validation"
                  noValidate
                >
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      {t('name')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      {t('email')}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="role" className="form-label">
                      {t('role')}
                    </label>
                    <select
                      className="form-select"
                      id="role"
                      name="role"
                      value={formData.role}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="admin">{t('admin')}</option>
                      <option value="mechanic">{t('mechanic')}</option>
                      <option value="receptionist">{t('receptionist')}</option>
                    </select>
                  </div>
                  
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      {loading ? t('registering') : t('register_user')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default UserRegister;