import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withPermission = (WrappedComponent, allowedRoles) => {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('token');
      const role = sessionStorage.getItem('role'); // Assumindo que o role é salvo no login

      if (!token || !allowedRoles.includes(role)) {
        navigate('/dashboard', {
          state: { errorMessage: 'Você não tem permissão para acessar esta página.' },
        });
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withPermission;