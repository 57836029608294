import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import Select from 'react-select';
import './Budget.css';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEyeSlash, faEye, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Budget() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [budgetNumber, setBudgetNumber] = useState(null);
  const [existingClients, setExistingClients] = useState([]);
  const [clientCars, setClientCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [isTagliandoChecked, setIsTagliandoChecked] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleDescription = (index) => {
    setExpandedRows((prev) => {
      if (prev.includes(index)) {
        // já está aberto, então fecha
        return prev.filter((idx) => idx !== index);
      } else {
        // estava fechado, então abre
        return [...prev, index];
      }
    });
    setBudgetItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index
          ? { ...item, showDescription: !item.showDescription }
          : item
      )
    );
  };

  const addTagliandoItems = () => {
    const tagliandoItems = [
      { service: 'Olio', quantity: 1, costPrice: 10, profitMargin: 0, iva: 22, finalPrice: 10 },
      { service: 'Filtro Olio', quantity: 1, costPrice: 5, profitMargin: 0, iva: 22, finalPrice: 5 },
      { service: 'Filtro Aria', quantity: 1, costPrice: 7, profitMargin: 0, iva: 22, finalPrice: 7 },
      { service: 'Filtro Abitacolo', quantity: 1, costPrice: 6, profitMargin: 0, iva: 22, finalPrice: 6 },
      { service: 'Filtro Carburante', quantity: 1, costPrice: 8, profitMargin: 0, iva: 22, finalPrice: 8 },
      { service: 'Smaltimento rifiuti e materiale di consumo', quantity: 1, costPrice: 3, profitMargin: 0, iva: 22, finalPrice: 3 },
      { service: 'Manodopera', quantity: 1, costPrice: 35, profitMargin: 0, iva: 22, finalPrice: 35 },
    ];
  
    setBudgetItems((prev) => [...prev, ...tagliandoItems]);
  };

  // Lista de marcas de carros
  const initialCarBrands = [
    {
      value: 'Toyota',
      label: 'Toyota',
      models: ['Corolla', 'Camry', 'RAV4', 'Highlander', 'Prius', 'Tacoma', 'Yaris', 'Avalon', '4Runner', 'Sequoia', 'Tundra', 'Sienna', 'Land Cruiser', 'C-HR', 'Supra', 'Venza', 'Matrix', 'FJ Cruiser', 'Hilux', 'Fortuner', 'Innova', 'Etios'],
    },
    {
      value: 'Ford',
      label: 'Ford',
      models: ['Fiesta', 'Focus', 'Mustang', 'Explorer', 'Ranger', 'Edge', 'Escape', 'Expedition', 'Bronco', 'Bronco Sport', 'F-150', 'F-250', 'F-350', 'Transit', 'Transit Connect', 'EcoSport', 'Taurus', 'Fusion', 'Maverick', 'Galaxy', 'Mondeo', 'C-Max', 'S-Max', 'Kuga', 'Puma', 'Flex', 'Thunderbird', 'Cortina', 'Falcon', 'Courier', 'Probe', 'Ka', 'Everest', 'Territory'],
    },
    {
      value: 'Chevrolet',
      label: 'Chevrolet',
      models: ['Cruze', 'Malibu', 'Silverado', 'Tahoe', 'Equinox', 'Camaro', 'Impala', 'Trailblazer', 'Suburban', 'Traverse', 'Colorado', 'Blazer', 'Corvette', 'Spark', 'Bolt EV', 'Sonic', 'Avalanche', 'Captiva', 'Orlando', 'HHR', 'Monte Carlo', 'Uplander', 'Astro', 'LUV', 'Cobalt', 'Venture', 'Express'],
    },
    {
      value: 'Honda',
      label: 'Honda',
      models: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Fit', 'HR-V', 'Odyssey', 'Passport', 'Ridgeline', 'Insight', 'Element', 'Prelude', 'S2000', 'Clarity', 'Del Sol', 'CR-Z', 'Brio', 'Amaze', 'City', 'Jazz', 'Shuttle', 'Stream', 'Freed', 'Legend', 'Elysion', 'Acty', 'Beat'],
    },
    {
      value: 'Nissan',
      label: 'Nissan',
      models: ['Altima', 'Sentra', 'Rogue', 'Murano', 'Frontier', 'Leaf', 
              'Maxima', 'Pathfinder', 'Armada', 'Versa', 'Titan', 'Kicks', 
              'GT-R', 'Juke', 'Qashqai', '370Z', 'Navara', 'X-Trail', 
              'Patrol', 'Terrano', 'Magnite', 'Sunny', 'Bluebird', 
              'Stagea', 'Cube', 'March', 'Note', 'Micra'],
    },
    {
      value: 'Volkswagen',
      label: 'Volkswagen',
      models: ['Golf', 'Passat', 'Jetta', 'Tiguan', 'Atlas', 
              'Beetle', 'Polo', 'Arteon', 'Touareg', 'ID.4', 
              'ID.3', 'ID.5', 'ID.Buzz', 'Taos', 'Amarok', 
              'Caddy', 'California', 'Transporter', 'Multivan', 
              'Caravelle', 'Up!', 'Scirocco', 'Corrado', 'Fox', 
              'Sharan', 'Touran', 'Eos', 'Routan', 'CrossFox', 
              'Saveiro', 'Voyage', 'Gol', 'Virtus', 'T-Cross', 
              'Nivus', 'Teramont'],
    },
    {
      value: 'BMW',
      label: 'BMW',
      models: ['1 Series', '2 Series', '3 Series', '4 Series', '5 Series', '6 Series', '7 Series', 
              '8 Series', 'X1', 'X2', 'X3', 'X4', 'X5', 'X6', 'X7', 'Z3', 'Z4', 'i3', 'i4', 
              'i7', 'i8', 'iX', 'iX3', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M8', 'X5 M', 
              'X6 M', 'X3 M', 'X4 M', 'Z8', 'ActiveHybrid 3', 'ActiveHybrid 5', 'ActiveHybrid 7', 
              '2 Series Active Tourer', '2 Series Gran Tourer', 'Gran Coupe', 'Gran Turismo'],
    },
    {
      value: 'Mercedes-Benz',
      label: 'Mercedes-Benz',
      models: ['C-Class', 'E-Class', 'GLC', 'GLE', 'A-Class', 'S-Class', 'GLA', 'GLS', 
              'CLS', 'AMG GT', 'G-Class', 'SL', 'SLK', 'CLA', 'B-Class', 'V-Class', 
              'Sprinter', 'Maybach S-Class', 'EQC', 'EQS', 'EQA', 'EQB', 'EQV'],
    },
    {
      value: 'Audi',
      label: 'Audi',
      models: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 
              'Q2', 'Q3', 'Q4', 'Q5', 'Q7', 'Q8', 
              'TT', 'TTS', 'TT RS', 
              'R8', 'RS3', 'RS4', 'RS5', 'RS6', 'RS7', 
              'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 
              'e-tron', 'e-tron GT', 'Q4 e-tron', 'Q8 e-tron', 
              'Allroad', 'Cabriolet', 'Coupe', 'Sportback', 'Avant'],
    },
    {
      value: 'Hyundai',
      label: 'Hyundai',
      models: ['Elantra', 'Sonata', 'Santa Fe', 'Tucson', 'Kona', 
              'Palisade', 'Veloster', 'Ioniq', 'Genesis', 
              'Accent', 'Creta', 'Venue', 'Verna', 'Getz', 
              'Atos', 'Terracan', 'Starex', 'Galloper', 'Matrix', 
              'Azera', 'Entourage', 'Grandeur', 'Equus', 'Trajet', 
              'Scoupe', 'H1', 'H100', 'Nexo'],
    },
    {
      value: 'Kia',
      label: 'Kia',
      models: ['Rio', 'Sportage', 'Sorento', 'Optima', 'Stinger', 
              'Seltos', 'Carnival', 'Soul', 'Telluride', 'Forte', 
              'Picanto', 'Ceed', 'Stonic', 'Niro', 'Carens', 
              'EV6', 'K900', 'Mohave', 'Rondo', 'Cadenza', 
              'Borrego', 'Sedona', 'Cerato', 'ProCeed', 'K5', 
              'K8', 'K3', 'XCeed', 'Spectra', 'Shuma'],
    },
    {
      value: 'Fiat',
      label: 'Fiat',
      models: ['500', 'Panda', 'Tipo', 'Punto', 'Doblo', 'Uno', 
              'Palio', 'Siena', 'Linea', 'Bravo', 'Brava', 
              'Marea', 'Strada', 'Toro', 'Fiorino', 'Ducato', 
              'Freemont', 'Multipla', 'Stilo', 'Idea', 'Albea', 
              'Tempra', 'Argenta', 'Croma', 'Ritmo', 'Regata', 
              'Sedici', 'Qubo', 'Ulysse', 'Seicento', 'Cinquecento', 
              'X1/9', '124 Spider', '850', '600', '1100', 
              'Campagnola', 'Barchetta', 'Topolino'],
    },
    {
      value: 'Peugeot',
      label: 'Peugeot',
      models: ['208', '308', '3008', '5008', '2008', '206', 
              '207', '405', '406', '407', '508', '607', 
              '806', '807', '1007', '3008 Hybrid', '4008', 
              'Partner', 'Boxer', 'Expert', 'Traveller', 'Rifter'],
    },
    {
      value: 'Renault',
      label: 'Renault',
      models: ['Clio', 'Megane', 'Captur', 'Kadjar', 'Talisman', 'Scenic', 
              'Kangoo', 'Trafic', 'Master', 'Zoe', 'Twizy', 'Fluence', 
              'Espace', 'Laguna', 'Modus', 'Latitude', 'Koleos', 
              'Arkana', 'Duster', 'Sandero', 'Logan', 'Symbol', 
              'Wind', 'Vel Satis', 'Avantime', 'Alaskan', '4', 
              '5', '9', '11', '21', '25', 'Fuego', 'Safrane', 'Express'],
    },
    {
      value: 'Opel',
      label: 'Opel',
      models: ["Astra", "Corsa", "Insignia", "Mokka", "Crossland", "Grandland", 
              "Zafira", "Vivaro", "Combo", "Movano", "Adam", "Karl", 
              "Meriva", "Antara", "Vectra", "Signum", "Omega", "Tigra", 
              "Agila", "Frontera", "Ampera", "Monza", "Kadett", "Ascona", 
              "Calibra", "Rekord", "Senator", "Diplomat", "GT", "Manta"],
    },
    {
      value: 'Skoda',
      label: 'Skoda',
      models: ["Fabia", "Octavia", "Superb", "Karoq", "Kodiaq", "Scala", 
              "Kamiq", "Enyaq iV", "Rapid", "Citigo", "Roomster", 
              "Yeti", "Felicia", "Favorit", "Forman", "120", "130", 
              "110", "100", "Citigo-e iV"],
    },
    {
      value: 'Mazda',
      label: 'Mazda',
      models: ["Mazda2", "Mazda3", "Mazda6", "CX-3", "CX-30", "CX-5", "CX-50",
              "CX-60", "CX-90", "MX-5 Miata", "MX-30", "RX-7", "RX-8", 
              "BT-50", "Mazda5", "Tribute", "B-Series", "Premacy", "Protege", 
              "626", "323", "Demio", "Familia", "Millenia", "MPV", "Cosmo"],
    },
    {
      value: 'Jaguar',
      label: 'Jaguar',
      models: ["E-PACE", "F-PACE", "I-PACE", "F-TYPE", "XE", "XF", "XJ", 
              "XK", "XKR", "XK8", "XJ6", "XJ8", "S-Type", "X-Type", 
              "XJ220", "XJR", "XJS", "MK2", "F-PACE SVR", "F-TYPE R"],
    },
    {
      value: 'Land Rover',
      label: 'Land Rover',
      models: ["Defender", "Discovery", "Discovery Sport", "Range Rover", "Range Rover Sport",
              "Range Rover Evoque", "Range Rover Velar", "Freelander", "LR2", "LR3", "LR4"],
    },
    {
      value: 'Porsche',
      label: 'Porsche',
      models: ["911", "Cayenne", "Macan", "Panamera", "Taycan", "718 Boxster", 
              "718 Cayman", "Boxster", "Cayman", "Carrera", "Turbo", 
              "GT3", "GT4", "Targa", "Spyder", "959", "924", 
              "928", "944", "968", "356", "Carrera GT"],
    },
    {
      value: 'Jeep',
      label: 'Jeep',
      models: ["Wrangler", "Grand Cherokee", "Cherokee", "Compass", "Renegade", 
              "Gladiator", "Patriot", "Commander", "Liberty", "Wagoneer", 
              "Grand Wagoneer", "CJ", "Scrambler", "J-Series", "Comanche", 
              "Trailhawk", "Trackhawk", "Overland", "Rubicon", "Laredo", 
              "Sahara", "Willys", "Freedom", "Mojave"],
    },
    {
      value: 'Alfa Romeo',
      label: 'Alfa Romeo',
      models: ["Giulia", "Stelvio", "Tonale", "Giulietta", "4C", "8C", "147", "156", 
              "159", "166", "Spider", "Brera", "GTV", "MiTo", "Alfa 33", "Alfa 75", 
              "Alfa 90", "Alfetta", "SZ", "RZ", "Montreal", "Alfa 145", "Alfa 146", 
              "Alfa 164", "Alfa 6", "Alfasud", "Alfasud Sprint", "GT", "Junior", 
              "Giulia Sprint", "Duetto", "Quadrifoglio"],
    },
    {
      value: 'Seat',
      label: 'Seat',
      models: ["Ibiza", "Leon", "Arona", "Ateca", "Tarraco", "Mii", 
              "Toledo", "Alhambra", "Cordoba", "Exeo", "Altea", 
              "Inca", "Marbella", "Ronda", "Malaga", "Fura", 
              "Panda", "600", "133"],
    },
    {
      value: 'Suzuki',
      label: 'Suzuki',
      models: ["Alto", "Swift", "Baleno", "Celerio", "Ignis", "Wagon R", "Ertiga", 
              "XL6", "Vitara", "Jimny", "S-Cross", "Kizashi", "Grand Vitara", 
              "SX4", "Dzire", "Splash", "Ritz", "Esteem", "Aerio", "Samurai", 
              "Sidekick", "X-90", "Equator", "Cappuccino", "Carry"],
    },
    {
      value: 'Volvo',
      label: 'Volvo',
      models: ["XC40", "XC60", "XC90", "S60", "S90", "V60", "V90", "C40 Recharge", 
              "V60 Cross Country", "V90 Cross Country", "XC40 Recharge", 
              "C30", "C70", "V40", "V50", "V70", "S40", "S80", "740", "760", 
              "780", "850", "940", "960", "Amazon", "PV444", "PV544", "P1800"],
    },
    {
      value: 'Lexus',
      label: 'Lexus',
      models: ["ES", "GS", "IS", "LS", "NX", "RX", "GX", "LX", 
              "UX", "RC", "LC", "CT", "HS", "RX L", "RXh", 
              "NXh", "LC Convertible", "IS F", "LFA", "SC"],
    },
    {
      value: 'Citroen',
      label: 'Citroen',
      models: ["C1", "C2", "C3", "C3 Aircross", "C4", "C4 Cactus", "C4 Picasso", "C5", 
              "C5 Aircross", "C5 X", "C6", "C8", "Berlingo", "Jumpy", "Spacetourer", 
              "Dispatch", "Relay", "DS3", "DS4", "DS5", "Saxo", "Xantia", "XM", "ZX", 
              "AX", "BX", "CX", "Evasion", "Dyane", "Mehari", "Ami", "Traction Avant", 
              "2CV", "Visa", "C15", "HY", "GS", "GSA", "C-Crosser", "C-Zero", "Metropolis", 
              "Pluriel", "Nemo", "C-Elysee"],
    },
    {
      value: 'Mitsubishi',
      label: 'Mitsubishi',
      models: ["Lancer", "Outlander", "Pajero", "Eclipse Cross", "ASX", "Mirage", "Triton", 
              "Montero", "Delica", "Galant", "Eclipse", "RVR", "i-MiEV", "Challenger", 
              "Endeavor", "Sigma", "FTO", "Starion", "GTO", "3000GT", "Shogun", 
              "Diamante", "Space Star", "Colt", "Carisma", "Raider", "Minicab", 
              "Canter", "Express", "Attrage", "Xforce"],
    },
    {
      value: 'Mini',
      label: 'Mini',
      models: ["Cooper", "Cooper S", "Cooper SE", "Cooper SD", "John Cooper Works", 
              "Countryman", "Countryman Plug-In Hybrid", "Clubman", "Paceman", 
              "Convertible", "Roadster", "Coupe", "Hatchback", "Cabrio", 
              "One", "One D", "One First", "Electric", "Mini E"],
    },
    {
      value: 'Smart',
      label: 'Smart',
      models: ["Fortwo", "Fortwo Cabrio", "Forfour", "Crossblade", "Roadster", 
               "Roadster Coupe", "EQ Fortwo", "EQ Forfour", "Smart #1"],
    },
    {
      value: 'Chrysler',
      label: 'Chrysler',
      models: ["300", "300C", "Pacifica", "Pacifica Hybrid", "Voyager", "Aspen", "PT Cruiser", 
              "Sebring", "Crossfire", "Town & Country", "200", "Concorde", "LHS", "LeBaron", 
              "New Yorker", "Imperial", "Fifth Avenue", "Cordoba", "Valiant", "Saratoga", 
              "Windsor", "Newport", "Laser"],
    },
    {
      value: 'Dodge',
      label: 'Dodge',
      models: ["Charger", "Challenger", "Durango", "Journey", "Grand Caravan", "Nitro", 
              "Caliber", "Avenger", "Viper", "Magnum", "Dakota", "Ram 1500", "Ram 2500", 
              "Ram 3500", "Stealth", "Intrepid", "Stratus", "Neon", "Spirit", 
              "Daytona", "Lancer", "Omni", "Dart", "Polara", "Coronet", "Monaco", 
              "Aspen", "Diplomat", "Caravan", "B-Series", "A100", "Custom 880", 
              "Power Wagon", "Raider", "Shadow", "Colt", "Raider", "Aries", "Mirada", 
              "St. Regis", "Aspen SE", "Demon", "Super Bee"],
    },
    {
      value: 'Iveco',
      label: 'Iveco',
      models: ["Daily", "Eurocargo", "Stralis", "Trakker", "S-Way", "PowerStar", 
              "EuroTrakker", "Massif", "Magirus", "TurboDaily", "Zeta", "LMV", 
              "EuroTech", "EuroStar", "Cursor", "EuroClass", "TurboStar", "TurboTech", 
              "Vertis", "Tector", "Citelis", "Urbanway", "Crealis", "Crossway", 
              "Evadys", "Eurobus", "Turboliner", "Irisbus", "Bus", "Camper"],
    },
    {
      value: 'Lancia',
      label: 'Lancia',
      models: ["Ypsilon", "Delta", "Fulvia", "Stratos", "Thema", "Kappa", "Beta", "Montecarlo", 
              "Gamma", "Dedra", "Lybra", "Thesis", "Prisma", "Flavia", "Aurelia", "Appia", 
              "Ardea", "Augusta", "Dilambda", "Astura", "Aprilia", "Phedra", "Zeta", "Voyager", 
              "Trevi", "Scorpion"],
    },
    {
      value: 'Dacia',
      label: 'Dacia',
      models: ["Logan", "Sandero", "Duster", "Spring", "Lodgy", "Dokker", "Solenza", 
              "1300", "1100", "Nova", "Pick-Up", "1304", "Double Cab", "Denem", 
              "SuperNova", "Stepway", "Bigster", "Manifesto"],
    },
    {
      value: 'dR',
      label: 'dR',
      models: ["DR1", "DR2", "DR3", "DR3 EV", "DR4", "DR5", "DR5.0", "DR6", 
              "DR6.0", "DR7", "DR F35", "DR EVO", "DR Zero"],
    },
    {
      value: 'Lamborghini',
      label: 'Lamborghini',
      models: ["Aventador", "Huracán", "Urus", "Gallardo", "Murciélago", 
              "Diablo", "Countach", "Sian", "Reventón", "Veneno", 
              "Centenario", "Miura", "Espada", "Jarama", "Islero", 
              "LM002", "Estoque", "350 GT", "400 GT", "Silhouette", 
              "Jalpa", "Bravo", "Marzal", "Egoista", "Asterion", 
              "Terzo Millennio"],
    },
    {
      value: 'Ferrari',
      label: 'Ferrari',
      models: ["488 GTB", "488 Spider", "812 Superfast", "812 GTS", 
              "California", "California T", "Portofino", "Portofino M", 
              "Roma", "SF90 Stradale", "SF90 Spider", "F8 Tributo", 
              "F8 Spider", "296 GTB", "296 GTS", "LaFerrari", 
              "LaFerrari Aperta", "F12berlinetta", "F12tdf", "GTC4Lusso", 
              "GTC4Lusso T", "Enzo", "Dino 246", "250 GTO", 
              "458 Italia", "458 Spider", "458 Speciale", "599 GTB Fiorano", 
              "599 GTO", "612 Scaglietti", "F50", "F40", "Testarossa", 
              "Mondial", "308", "328", "348", "355", "360 Modena", 
              "360 Spider", "430 Scuderia", "Challenge Stradale", "275 GTB", 
              "512 TR", "512 BB", "Superamerica", "FXX", "SP1 Monza", 
              "SP2 Monza", "296 GTB", "Purosangue"],
    },
    {
      value: 'Subaro',
      label: 'Subaro',
      models: ["Impreza", "Legacy", "Outback", "Forester", 
              "Crosstrek", "Ascent", "WRX", "WRX STI", 
              "BRZ", "XV", "Levorg", "Baja", 
              "Tribeca", "SVX", "Justy", "Alcyone", 
              "Rex", "Sambar", "Trezia", "Stella", 
              "Lucra", "Dias Wagon", "Exiga", "Gloria"],
    },
  ];

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [carBrands, setCarBrands] = useState(initialCarBrands);
  const [carModels, setCarModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  const [budgetItems, setBudgetItems] = useState([
    {
      service: '',
      quantity: 1,
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: 0,
      description: '',
    },
  ]);

  const predefinedServices = [
    {
      value: 'Manodopera',
      label: 'Manodopera',
      costPrice: 35,
      profitMargin: 0,
      iva: 22,
      finalPrice: 35,
    },
    {
      value: 'Smaltimento Rifiuti e Materiale di Consumo',
      label: 'Smaltimento Rifiuti e Materiale di Consumo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Olio 10W40',
      label: 'Olio 10W40',
      costPrice: 10,
      profitMargin: 0,
      iva: 22,
      finalPrice: 10,
    },
    {
    value: 'Olio 5W30',
    label: 'Olio 5W30',
    costPrice: 13,
    profitMargin: 0,
    iva: 22,
    finalPrice: 13,
    },
    {
    value: 'Olio 5W40',
    label: 'Olio 5W40',
    costPrice: 12,
    profitMargin: 0,
    iva: 22,
    finalPrice: 12,
    },
    {
    value: 'Olio 0W30',
    label: 'Olio 0W30',
    costPrice: 16.50,
    profitMargin: 0,
    iva: 22,
    finalPrice: 16.50,
    },
    {
    value: 'Olio 0W20',
    label: 'Olio 0W20',
    costPrice: 19,
    profitMargin: 0,
    iva: 22,
    finalPrice: 19,
    },
    {
      value: 'Filtro Olio',
      label: 'Filtro Olio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Aria',
      label: 'Filtro Aria',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Abitacolo',
      label: 'Filtro Abitacolo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Carburante',
      label: 'Filtro Carburante',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'KIT Distribuzione',
      label: 'KIT Distribuzione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pompa Acqua',
      label: 'Pompa Acqua',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
  ];

  const fetchNextBudgetId = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/next-budget-id`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar o próximo número do orçamento');
      }
      const data = await response.json();
      setBudgetNumber(data.nextId || 1);
    } catch (error) {
      console.error('Erro ao buscar o próximo número do orçamento:', error);
      setBudgetNumber(1); // Valor padrão em caso de erro
    }
  };

  useEffect(() => {
    fetchNextBudgetId();
  }, []);

  function reorderItems(items) {
    // Fazemos uma cópia para não mutar diretamente a referência
    const arr = [...items];
  
    // Localizar os índices de cada serviço se existirem
    let manodoperaIndex = arr.findIndex(
      (i) => i.service === 'Manodopera'
    );
    let smaltimentoIndex = arr.findIndex(
      (i) => i.service === 'Smaltimento Rifiuti e Materiale di Consumo'
    );
  
    // Se ambos existirem:
    if (manodoperaIndex !== -1 && smaltimentoIndex !== -1) {
      // Remove primeiro o item Manodopera
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      // Se manodoperaIndex < smaltimentoIndex, ao remover Manodopera, 
      // o índice do Smaltimento pode mudar
      if (smaltimentoIndex > manodoperaIndex) {
        smaltimentoIndex--;
      }
      // Remove Smaltimento
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
  
      // Agora adiciona novamente Smaltimento e Manodopera no final do array,
      // garantindo que Smaltimento fique penúltimo e Manodopera seja o último
      arr.push(smaltimentoItem);
      arr.push(manodoperaItem);
  
      return arr;
    }
  
    // Se existir só Manodopera (sem Smaltimento)
    if (manodoperaIndex !== -1) {
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      arr.push(manodoperaItem);
    }
  
    // Se existir só Smaltimento (sem Manodopera)
    if (smaltimentoIndex !== -1) {
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
      arr.push(smaltimentoItem);
    }
  
    return arr;
  }

  const handleAddItem = () => {
    setBudgetItems((prev) => {
      const newItems = [
        ...prev,
        {
          service: '',
          quantity: 1,
          costPrice: 0,
          profitMargin: 0,
          iva: 22,
          finalPrice: 0,
        },
      ];
      return reorderItems(newItems);
    });
  };

  const handleRemoveItem = (index) => {
    setBudgetItems((prev) => {
      const updated = prev.filter((_, i) => i !== index);
      return reorderItems(updated);
    });
  };

  const handleItemChange = (index, field, value) => {
    setBudgetItems((prev) => {
      const updatedItems = [...prev];
      const item = { ...updatedItems[index] };
  
      if (field === 'service' && predefinedServices.find((s) => s.value === value)) {
        const predefined = predefinedServices.find((s) => s.value === value);
        item.service = predefined.label;
        item.costPrice = predefined.costPrice;
        item.profitMargin = predefined.profitMargin;
        item.finalPrice = calculateFinalPrice(
          predefined.costPrice,
          predefined.profitMargin,
          item.quantity
        );
      } else {
        item[field] = value;
        if (
          field === 'costPrice' ||
          field === 'profitMargin' ||
          field === 'iva' ||
          field === 'quantity'
        ) {
          item.finalPrice = calculateFinalPrice(
            item.costPrice || 0,
            item.profitMargin || 0,
            item.quantity || 1
          );
        }
      }
  
      item.finalPrice = parseFloat(item.finalPrice) || 0; // Garante que seja numérico
      updatedItems[index] = item;
      return reorderItems(updatedItems);
    });
  };

  const calculateFinalPrice = (costPrice, profitMargin, quantity) => {
    if (profitMargin === 0) {
      // Caso onde o lucro não é conhecido
      return (costPrice * quantity).toFixed(2);
    }
  
    // Caso onde o lucro é conhecido
    const profit = costPrice * (profitMargin / 100);
    const finalPrice = (costPrice + profit) * quantity;
    return finalPrice.toFixed(2);
  };

  const success_noti = (message) => {
    toast.success(message || t('Customer and car successfully registered.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };
  
  const error_noti = (message) => {
    toast.error(message || t('An error occurred while registering.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  useEffect(() => {
    if (selectedCar) {
      // Se um veículo foi selecionado do banco de dados
      const selectedCarDetails = clientCars.find((car) => car.id === selectedCar);
  
      if (selectedCarDetails) {
        const brand = carBrands.find((b) => b.value === selectedCarDetails.car_brand);
        if (brand) {
          setCarModels(
            brand.models.map((model) => ({ value: model, label: model }))
          );
        }
        // Define o modelo selecionado automaticamente
        setSelectedModel({
          value: selectedCarDetails.car_model,
          label: selectedCarDetails.car_model,
        });
      }
    } else if (selectedBrand) {
      // Caso contrário, atualiza os modelos para a marca selecionada
      const brand = carBrands.find((b) => b.value === selectedBrand.value);
      setCarModels(
        brand ? brand.models.map((model) => ({ value: model, label: model })) : []
      );
      setSelectedModel(null); 
    } else {
      setCarModels([]);
      setSelectedModel(null);
    }
  }, [selectedBrand, selectedCar, clientCars, carBrands]);

  const handleBrandChange = (newValue) => {
    setSelectedBrand(newValue);
    setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
  
    if (newValue && !carBrands.some((brand) => brand.value === newValue.value)) {
      setCarBrands([...carBrands, { value: newValue.value, label: newValue.label, models: [] }]);
    }
  };
  
  const handleModelChange = (newValue) => {
    setSelectedModel(newValue);
    setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
  
    if (newValue && selectedBrand) {
      const updatedBrands = carBrands.map((brand) => {
        if (brand.value === selectedBrand.value) {
          return {
            ...brand,
            models: brand.models.includes(newValue.value)
              ? brand.models
              : [...brand.models, newValue.value],
          };
        }
        return brand;
      });
      setCarBrands(updatedBrands);
    }
  };

  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    carBrand: '',
    carModel: '',
    carYear: '',
    carPlate: '',
    carProblem: '',
  });

  const steps = [
    { id: 1, title: t('Personal Information'), number: '1', description: t('Client details') },
    { id: 2, title: t('Car Details'), number: '2', description: t('Car information') },
    { id: 3, title: t('budget'), number: '3', description: t('Add products or services') },
    { id: 4, title: t('Summary'), number: '4', description: t('Review and submit') },
  ];

  const fetchClientDetails = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/clients/details/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar detalhes do cliente.');
      }

      const clientDetails = await response.json();

      setFormData({
        clientName: clientDetails.name || '',
        clientEmail: clientDetails.email || '',
        clientPhone: clientDetails.phone || '',
        carBrand: '',
        carModel: '',
        carYear: '',
        carPlate: '',
        carProblem: ''
      });
    } catch (err) {
      console.error('Erro:', err);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setExistingClients(data);
      } catch (err) {
        console.error('Erro ao buscar clientes existentes:', err);
      }
    };

    fetchClients();
  }, []);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
        console.log('Iniciando o processo de envio do orçamento...');
        const token = sessionStorage.getItem('token');
        if (!token) {
            console.error('Erro: Usuário não está logado.');
            error_noti('User is not logged');
            return;
        }

        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const operatorId = decodedToken.userId;

        const payload = {
            clientName: formData.clientName,
            clientPhone: formData.clientPhone,
            carBrand: formData.carBrand,
            carModel: formData.carModel,
            carYear: parseInt(formData.carYear, 10),
            carPlate: formData.carPlate,
            operatorId: operatorId,
            budgetItems: budgetItems.map((item) => ({
                service: item.service,
                description: item.description || null,
                quantity: parseFloat(item.quantity),
                costPrice: parseFloat(item.costPrice),
                profitMargin: parseFloat(item.profitMargin),
                iva: parseFloat(item.iva),
                finalPrice: parseFloat(item.finalPrice),
            })),
            totals: {
                totalExclIva: parseFloat(totals.totalExclIva),
                totalIva: parseFloat(totals.totalIva),
                totalInclIva: parseFloat(totals.totalInclIva),
            },
        };

        console.log('Payload preparado:', JSON.stringify(payload, null, 2));

        const response = await fetch(`${API_BASE_URL}/api/budgets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            navigate('/budget/list');
            success_noti(t('Budget created successfully!'));
        } else {
            const errorData = await response.json();
            console.error('Erro ao salvar orçamento. Detalhes:', errorData);
            error_noti(errorData.error || t('Error to creat budget!'));
        }
    } catch (error) {
        console.error('Erro ao conectar ao servidor:', error);
        error_noti(t('Error to conect to server.'));
    }
};
  
  const handleDescriptionChange = (index, value) => {
    setBudgetItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, description: value } : item
      )
    );
  };

  const fetchClientCars = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/clients/${clientId}/cars`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao buscar carros do cliente.');
      }
  
      const cars = await response.json();
      setClientCars(cars);
    } catch (err) {
      console.error('Erro ao buscar carros do cliente:', err);
      setClientCars([]);
    }
  };

  const handleTextInputChange = (index, field, value) => {
    // Substitui vírgulas por pontos
    let newValue = value.replace(/,/g, '.');
  
    // Remove todos os caracteres que não sejam dígitos ou pontos
    newValue = newValue.replace(/[^0-9.]/g, '');
  
    // Garante que haja no máximo um ponto decimal
    const parts = newValue.split('.');
    if (parts.length > 2) {
      // Junta todos os pontos extras e mantém apenas o primeiro
      newValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Limita a duas casas decimais após o ponto
    if (parts[1]?.length > 2) {
      newValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
  
    // Permite que o usuário deixe o campo vazio ou apenas com um ponto
    // Isso evita bloquear a digitação intermediária
    if (newValue === '.') {
      newValue = '';
    }
  
    // Atualiza o estado com o valor formatado
    setBudgetItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index],
        [field]: newValue,
      };
  
      // Recalcula o finalPrice se necessário
      if (['quantity', 'costPrice', 'profitMargin'].includes(field)) {
        const costPrice = parseFloat(updatedItems[index].costPrice) || 0;
        const profitMargin = parseFloat(updatedItems[index].profitMargin) || 0;
        const quantity = parseFloat(updatedItems[index].quantity) || 1;
  
        const profit = costPrice * (profitMargin / 100);
        const finalPrice = (costPrice + profit) * quantity;
        updatedItems[index].finalPrice = finalPrice.toFixed(2);
      }
  
      return updatedItems;
    });
  };

  const calculateTotals = () => {
    const totalExclIva = budgetItems.reduce((sum, item) => {
      const finalPrice = parseFloat(item.finalPrice) || 0;
      return sum + finalPrice;
    }, 0);
  
    const totalIva = totalExclIva * 0.22;
    const totalInclIva = totalExclIva + totalIva;
  
    return {
      totalExclIva: totalExclIva.toFixed(2),
      totalIva: totalIva.toFixed(2),
      totalInclIva: totalInclIva.toFixed(2),
    };
  };
  
  const totals = calculateTotals();

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h4 className="form-section-title">{t('Client details')}</h4>
            <div className="col-12 mb-3">
              <label htmlFor="clientSelection" className="form-label">{t('select_client')}</label>
              {existingClients.length > 0 ? (
                <Select
                  options={[
                    { value: 'new', label: t('new_client') },
                    ...existingClients.map((client) => ({
                      value: client.id,
                      label: `${client.name} - ${client.phone}`,
                    })),
                  ]}
                  value={selectedClient ? { value: selectedClient, label: `${formData.clientName} - ${formData.clientPhone}` } : null}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedClient(null);
                      setFormData({
                        clientName: '',
                        clientEmail: '',
                        clientPhone: '',
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                        carProblem: '',
                      });
                      setClientCars([]);
                    } else {
                      setSelectedClient(selectedOption.value);
                      fetchClientDetails(selectedOption.value);
                      fetchClientCars(selectedOption.value);
                    }
                  }}
                  placeholder={t('select_or_search_client')}
                  isSearchable
                />
              ) : (
                <p className="text-muted">{t('no_clients_found')}</p>
              )}
            </div>
            <div className="row g-4">
            <div className="col-md-6">
              <label htmlFor="clientName" className="form-label">{t('name')}</label>
              <input
                type="text"
                className="form-control"
                id="clientName"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                required
                disabled={!!selectedClient}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="clientPhone" className="form-label">{t('phone')}</label>
              <input
                type="text"
                className="form-control"
                id="clientPhone"
                name="clientPhone"
                value={formData.clientPhone}
                onChange={handleChange}
                required
                disabled={!!selectedClient}
              />
            </div>
            </div>
          </div>
        );
        case 2:
          return (
            <div>
              <h4 className="form-section-title">{t('Car Details')}</h4>

              {/* Seleção de veículos */}
              <div className="col-12 mb-3">
                <label htmlFor="carSelection" className="form-label">{t('Select a Car')}</label>
                <Select
                  id="carSelection"
                  options={[
                    { value: 'new', label: t('New Vehicle') },
                    ...clientCars.map((car) => ({
                      value: car.id,
                      label: `${car.car_brand} ${car.car_model} (${car.car_year}) - ${car.car_plate}`,
                    })),
                  ]}
                  value={
                    selectedCar
                      ? clientCars.find((car) => car.id === selectedCar) 
                        ? { value: selectedCar, label: `${clientCars.find((car) => car.id === selectedCar).car_brand} ${clientCars.find((car) => car.id === selectedCar).car_model} (${clientCars.find((car) => car.id === selectedCar).car_year}) - ${clientCars.find((car) => car.id === selectedCar).car_plate}` }
                        : { value: 'new', label: t('New Vehicle') }
                      : { value: 'new', label: t('New Vehicle') }
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedCar(null); // Limpa a seleção de veículo
                      setFormData({
                        ...formData,
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                      });
                    } else {
                      setSelectedCar(selectedOption.value); // Define o veículo selecionado
                      const selectedCarDetails = clientCars.find((car) => car.id === selectedOption.value);
                      if (selectedCarDetails) {
                        setFormData({
                          ...formData,
                          carBrand: selectedCarDetails.car_brand,
                          carModel: selectedCarDetails.car_model,
                          carYear: selectedCarDetails.car_year,
                          carPlate: selectedCarDetails.car_plate,
                        });
                      }
                    }
                  }}
                  placeholder={t('Select or register a vehicle')}
                  isSearchable
                />
              </div>

              {/* Formulário para cadastrar/editar veículo */}
              <div className="row g-4">
                <div className="col-md-6">
                  <label className="form-label">{t('Brand')}</label>
                  <CreatableSelect
                    options={carBrands}
                    value={
                      formData.carBrand
                        ? carBrands.find((brand) => brand.value === formData.carBrand) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleBrandChange(newValue);
                      setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car brand')}
                    isClearable
                    isDisabled={selectedCar}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Model')}</label>
                  <CreatableSelect
                    options={carModels}
                    value={
                      formData.carModel
                        ? carModels.find((model) => model.value === formData.carModel) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleModelChange(newValue);
                      setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car model')}
                    isClearable
                    isDisabled={selectedCar}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Year')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carYear"
                    value={formData.carYear}
                    onChange={handleChange}
                    placeholder={t('Enter car year')}
                    required
                    disabled={selectedCar} 
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Plate')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="carPlate"
                    value={formData.carPlate}
                    onChange={handleChange}
                    placeholder={t('Enter car plate')}
                    required
                    disabled={selectedCar}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Mileage')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="carMileage"
                    value={formData.carMileage}
                    onChange={handleChange}
                    placeholder={t('Enter car mileage')}
                  />
                </div>
              </div>
            </div>
          );
      case 3:
        return (
            <div>
            <h4 className="form-section-title">{t('Create Budget')}</h4>
            
            {/* Checkbox para Tagliando */}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="tagliandoCheckbox"
                checked={isTagliandoChecked}
                onChange={(e) => {
                  setIsTagliandoChecked(e.target.checked);
                  if (e.target.checked) {
                    addTagliandoItems();
                  } else {
                    // Remove os itens de Tagliando quando desmarcado
                    setBudgetItems((prev) =>
                      prev.filter(
                        (item) =>
                          !['Olio', 'Filtro Olio', 'Filtro Aria', 'Filtro Abitacolo', 'Filtro Carburante', 'Smaltimento rifiuti e materiale di consumo', 'Manodopera'].includes(item.service)
                      )
                    );
                  }
                }}
              />
              <label className="form-check-label" htmlFor="tagliandoCheckbox">
                Tagliando
              </label>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('Service')}</th>
                  <th>{t('Quantity')}</th>
                  <th>{t('Cost Price')}</th>
                  <th>{t('Profit Margin')} (%)</th>
                  <th>{t('Final Price')}</th>
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {budgetItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{index + 1}</td>

                      {/* Service */}
                      <td>
                        <CreatableSelect
                          options={predefinedServices}
                          value={item.service ? { value: item.service, label: item.service } : null}
                          onChange={(option) => handleItemChange(index, 'service', option.value)}
                          placeholder={t('Select or enter service')}
                        />
                      </td>

                      {/* Quantity */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.quantity}
                          onChange={(e) => handleTextInputChange(index, 'quantity', e.target.value)}
                        />
                      </td>

                      {/* Cost Price */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.costPrice}
                          onChange={(e) => handleTextInputChange(index, 'costPrice', e.target.value)}
                        />
                      </td>

                      {/* Profit Margin */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.profitMargin}
                          onChange={(e) => handleTextInputChange(index, 'profitMargin', e.target.value)}
                        />
                      </td>

                      {/* Final Price */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.finalPrice}
                          readOnly
                        />
                      </td>

                      <td>
                        {/* Botões lado a lado */}
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger me-2"
                            onClick={() => handleRemoveItem(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-primary space"
                            onClick={() => toggleDescription(index)}
                          >
                            {item.showDescription ? (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            ) : (
                              <FontAwesomeIcon icon={faEye} />
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>

                    {/* Linha extra para o campo description, se expandido */}
                    {expandedRows.includes(index) && (
                      <tr>
                        <td colSpan={6}>
                          <textarea
                            className="form-control mt-2"
                            placeholder={t('Add detailed description')}
                            value={item.description || ''}
                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                            rows="3"
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={handleAddItem}
            >
                <FontAwesomeIcon icon={faPlusSquare} /> {t('Add')}
            </button>

            {/* Totais */}
            <div className="totals-summary mt-4 p-3 bg-light rounded">
                <h5>{t('Summary')}</h5>
                <p>
                <strong>{t('Total excl. IVA')}:</strong> €{totals.totalExclIva}
                </p>
                <p>
                <strong>{t('IVA 22%')}:</strong> €{totals.totalIva}
                </p>
                <p>
                <strong>{t('Total incl. IVA')}:</strong> €{totals.totalInclIva}
                </p>
            </div>
            </div>
            );
            case 4:
                return (
                  <div className="review-budget">
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="p-3 border rounded">
                          <h5>{t('Client Details')}</h5>
                          <p>
                            <strong>{t('Name')}:</strong> {formData.clientName || '-'}
                          </p>
                          <p>
                            <strong>{t('Phone')}:</strong> {formData.clientPhone || '-'}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="p-3 border rounded">
                          <h5>{t('Car Details')}</h5>
                          <p>
                            <strong>{t('Brand')}:</strong> {formData.carBrand || '-'}
                          </p>
                          <p>
                            <strong>{t('Model')}:</strong> {formData.carModel || '-'}
                          </p>
                          <p>
                            <strong>{t('Year')}:</strong> {formData.carYear || '-'}
                          </p>
                          <p>
                            <strong>{t('Plate')}:</strong> {formData.carPlate || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
              
                    {/* Tabela de Itens do Orçamento */}
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t('Product/Service')}</th>
                          <th>{t('Quantity')}</th>
                          <th>{t('Cost Price (excl. IVA)')}</th>
                          <th>{t('Final Price')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {budgetItems.map((item, index) => {
                          // Calcula o cost price (excl. IVA) => custo + margem, sem IVA:
                          const costPricePerItem =
                            parseFloat(item.costPrice || 0) *
                            (1 + (parseFloat(item.profitMargin || 0) / 100));

                          return (
                            <React.Fragment key={index}>
                              {/* Linha principal */}
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.service || '-'}</td>
                                <td>{item.quantity || '-'}</td>
                                <td>€{costPricePerItem.toFixed(2)}</td>
                                <td>€ {Number(item.finalPrice || 0).toFixed(2)}</td>
                              </tr>

                              {/* Se houver descrição, mostra em nova linha, ocupando todas as colunas */}
                              {item.description && (
                                <tr>
                                  <td colSpan={5}>
                                    {item.description
                                      .split('\n')
                                      .map((line, i) => (
                                        <div key={i}>- {line}</div>
                                      ))}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
              
                    {/* Summary alinhado à direita */}
                    <div className="d-flex justify-content-end mt-12">
                      <div className="p-3 border rounded" style={{ minWidth: '300px' }}>
                        <h4 className="form-section-title">{t('Summary')}</h4>
                        <p>
                          <strong>{t('Total excl. IVA')}:</strong> €{totals.totalExclIva}
                        </p>
                        <p>
                          <strong>{t('IVA 22%')}:</strong> €{totals.totalIva}
                        </p>
                        <p>
                          <strong>{t('Total incl. IVA')}:</strong> €{totals.totalInclIva}
                        </p>
                      </div>
                    </div>
                  </div>
                );
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('budget')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('newBudget')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* Progress Bar */}
            <div className="progress-bar-container mb-4">
              {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                  <div
                    className={`progress-step ${currentStep === step.id ? 'active' : ''} ${
                      currentStep > step.id ? 'completed' : ''
                    }`}
                    onClick={() => handleStepClick(step.id)}
                  >
                    <div className="step-icon">{step.number}</div>
                    <div className="step-title">{step.title}</div>
                    <div className="step-description">{step.description}</div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`bs-stepper-line ${
                        currentStep > step.id ? 'completed' : ''
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <form>
              {renderStep()}
              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  disabled={currentStep === 1}
                >
                  {t('Previous')}
                </button>
                {currentStep === 4 ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {t('Submit')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setCurrentStep((prev) => prev + 1)}
                  >
                    {t('Next')}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Budget;