import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Timer.css';
import { useTranslation } from 'react-i18next';

function Timer({ total_time, is_running, start_time, userRole }) {
  const { t } = useTranslation();
  const [displayTime, setDisplayTime] = useState(total_time);

  useEffect(() => {
    let interval = null;

    if (is_running && start_time) {
      interval = setInterval(() => {
        const now = new Date();
        const start = new Date(start_time);
        const elapsed = Math.floor((now - start) / 1000);
        setDisplayTime(total_time + elapsed);
      }, 1000);
    } else {
      setDisplayTime(total_time);
    }

    return () => clearInterval(interval);
  }, [is_running, start_time, total_time]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className={`timer-container ${userRole}`}>
      <h5>
        {t(
          userRole === 'admin' || userRole === 'receptionist'
            ? t('Total Time')
            : t('Your Total Time')
        )}
      </h5>
      <div className="timer-display">{formatTime(displayTime)}</div>
    </div>
  );
}

Timer.propTypes = {
  total_time: PropTypes.number.isRequired,
  is_running: PropTypes.bool.isRequired,
  start_time: PropTypes.string,
  userRole: PropTypes.string.isRequired,
};

export default Timer;